import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';

import { ArrowBorderLeftBig } from 'assets/svg-icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { editUserData, getUserSettings } from 'store/redux-toolkit/users/users-thunks';
import CountrySelect from 'ui/Formik/CountrySelect';
import Input from 'ui/Formik/Input';
import DatePicker from 'ui/Formik/DatePicker';
import { editSchema } from 'form-validations/shemValidations';
import InputPhone from 'ui/Formik/InputPhone';
import CustomSelect from 'ui/CustomSelect';
import { ERoutes } from 'enum/types';

export interface IEditValues {
  email: string;
  first_name: string;
  last_name: string;
  country: string;
  phone: string;
}

export enum ESource {
  admin = 'admin',
}

const EditUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.users);

  const dateOfBirth = user?.userKyc?.date_of_birth || user?.data?.date_of_birth || null;

  const initialValues = {
    email: user?.email,
    first_name: user?.data?.first_name || undefined,
    last_name: user?.data?.last_name || undefined,
    country: user?.data?.country || undefined,
    phone: user?.data?.phone || undefined,
    date_of_birth: dateOfBirth ? new Date(dateOfBirth) : undefined,
    affiliate_id: undefined,
    source: user?.data?.source || undefined,
  };

  const goBack = () => navigate(-1);

  useEffect(() => {
    if (userId) {
      dispatch(getUserSettings({ id: userId }));
    }
  }, [userId]);

  return (
    <div className='main-content edit-user-form'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Edit</p>
        </div>
        <button className='back-to-btn' onClick={goBack}>
          <span className='back-to-btn__arrow'>
            <ArrowBorderLeftBig />
          </span>
          Back to User data
        </button>
      </div>
      {user && (
        <div className='block '>
          <Formik
            initialValues={initialValues}
            validationSchema={editSchema}
            onSubmit={(values, { setSubmitting }) => {
              const { date_of_birth, ...rest } = values;
              dispatch(
                editUserData({
                  user_id: user.id,
                  date_of_birth: moment(date_of_birth).format('YYYY-MM-DD'),
                  ...rest,
                })
              );

              setSubmitting(false);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form className='form form--user-edit-new'>
                  <div className='form-body'>
                    <div className='inputs-row'>
                      <div className='form-group'>
                        <Field
                          name='first_name'
                          label='First Name:'
                          placeholder='First Name'
                          component={Input}
                        />
                        <Field
                          name='last_name'
                          label='Last Name:'
                          placeholder='Last Name'
                          component={Input}
                        />
                        <Field
                          name='country'
                          label='Country:'
                          placeholder='Country'
                          component={CountrySelect}
                        />
                        <Field name='email' label='Email:' placeholder='Email' component={Input} />
                        <Field
                          name='phone'
                          label='Phone Number:'
                          placeholder='Phone Number'
                          component={InputPhone}
                          maxLength={20}
                        />
                      </div>
                      <div className='form-group'>
                        <Field
                          name='date_of_birth'
                          label='Date of birth:'
                          component={DatePicker}
                          maxDate={new Date()}
                        />
                        <Field
                          name='affiliate_id'
                          label='Affiliate ID:'
                          placeholder='Affiliate id'
                          component={Input}
                        />
                        <CustomSelect
                          obj={ESource}
                          element={values.source || ''}
                          className='source-select'
                          setElement={(val) => setFieldValue('source', val)}
                          label='Source:'
                          placeholder='Source'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-submit form-submit--end'>
                    <Link
                      to={`${ERoutes.users}/${user?.id}/profile`}
                      className='button button--type-text'
                    >
                      Cancel
                    </Link>

                    <button className='button' type='submit'>
                      Save
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default EditUser;
