import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, Navigate } from 'react-router-dom';
import { APP_NAME } from 'services/constants/env';
import usePermissions from 'hooks/usePermissions';

const AdminManagementPage = () => {
  const appTitle = APP_NAME || '';
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission('admins') ? (
        <>
          <Helmet>
            <title>{`${appTitle} | Admin management`}</title>
          </Helmet>
          <Outlet />
        </>
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default AdminManagementPage;
