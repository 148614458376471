import { FC, useReducer } from 'react';
import { useAppSelector } from 'store/hooks';
import { isDecimal, isDecimalWithMinus } from 'utils/regex';
import { CloseTableBigIcon, CheckBigIcon, EditIcon } from 'assets/svg-icon';
import Switch from 'ui/Switch';
import { ISwapRow } from './types';

interface IEditRowParams {
  min_lot: string;
  swap_short: string;
  swap_long: string;
  lot_size: string;
  spread_percent_min: string;
  spread_percent_max: string;
  name?: string;
}

const SwapRow: FC<ISwapRow> = ({
  item,
  editingId,
  onEdit,
  toggleActive,
  onCancelEdit,
  onSetEditId,
  copyRow,
  copiedRow,
  setCopiedRow,
  fromCopy,
}) => {
  const { editLoading, swapCommissions } = useAppSelector((state) => state.commissions);
  const {
    id,
    name,
    min_lot,
    lot_size,
    swap_short,
    swap_long,
    spread_percent_min,
    spread_percent_max,
    is_active = 0,
  } = item;

  const initialStateValue = {
    name,
    min_lot: String(min_lot),
    swap_short: String(swap_short),
    swap_long: String(swap_long),
    lot_size: String(lot_size),
    spread_percent_min: String(spread_percent_min),
    spread_percent_max: String(spread_percent_max),
  };

  const [state, setState] = useReducer(
    (state: IEditRowParams, newState: Partial<IEditRowParams>) => ({
      ...state,
      ...newState,
    }),
    initialStateValue
  );

  const handleToggleActive = () => {
    toggleActive?.(id as number, !is_active);
  };

  const handleCancelEdit = () => {
    setState(initialStateValue);
    onCancelEdit?.();
  };

  const isCopy = copiedRow === id;

  const handleEdit = () => {
    let params = {
      min_lot: Number(state.min_lot),
      swap_short: Number(state.swap_short),
      swap_long: Number(state.swap_long),
      lot_size: Number(state.lot_size),
      spread_percent_min: Number(state.spread_percent_min),
      spread_percent_max: Number(state.spread_percent_max),
    };

    if (isCopy) {
      const copyObject = {
        name: state.name,
      };

      params = {
        ...item,
        ...params,
        ...copyObject,
      };
    }

    if (!isCopy) {
      const editObject = {
        asset_id: id as number,
      };

      params = {
        ...params,
        ...editObject,
      };
    }

    onEdit?.(
      {
        params,
      },
      isCopy
    );
    onCancelEdit?.();
  };

  return (
    <div className='tr'>
      {editingId === id || isCopy ? (
        <>
          {isCopy && (
            <div className='td'>
              <p className='td-hidden-name'>Assets</p>
              <div className='td-input'>
                <div className='field-wrap'>
                  <input
                    type='text'
                    className='input-item input-item--td-type'
                    value={state.name}
                    onChange={(e) => {
                      const { value } = e.target;
                      setState({ name: value });
                    }}
                    placeholder='Order size min'
                  />
                </div>
              </div>
            </div>
          )}
          {!isCopy && (
            <div className='td'>
              <p className='td-hidden-name'>Assets</p>
              <p>{name}</p>
            </div>
          )}
          <div className='td'>
            <p className='td-hidden-name'>Order size min</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.min_lot}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimal.test(value)) return;
                    setState({ min_lot: value });
                  }}
                  placeholder='Order size min'
                />
              </div>
            </div>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Lot size</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.lot_size}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimal.test(value)) return;
                    setState({ lot_size: value });
                  }}
                  placeholder='Lot size'
                />
              </div>
            </div>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Spread min %</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.spread_percent_min}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimal.test(value)) return;
                    setState({ spread_percent_min: value });
                  }}
                  placeholder='Spread min %'
                />
              </div>
            </div>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Spread max %</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.spread_percent_max}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimal.test(value)) return;
                    setState({ spread_percent_max: value });
                  }}
                  placeholder='Spread max %'
                />
              </div>
            </div>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Swap short %</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.swap_short}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimalWithMinus.test(value)) return;
                    setState({ swap_short: value });
                  }}
                  placeholder='Swap short %'
                />
              </div>
            </div>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Swap long %</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.swap_long}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimalWithMinus.test(value)) return;
                    setState({ swap_long: value });
                  }}
                  placeholder='Swap long %'
                />
              </div>
            </div>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Action</p>
            <div className='table-buttons flex-e'>
              <button onClick={() => (isCopy ? setCopiedRow() : handleCancelEdit())}>
                <CloseTableBigIcon />
              </button>
              <button onClick={handleEdit}>
                <CheckBigIcon />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='td'>
            <p className='td-hidden-name'>Assets</p>
            <p>{name || '-'}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Order size min</p>
            <p>{min_lot || '-'}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Lot size</p>
            <p>{lot_size}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Spread min %</p>
            <p>{spread_percent_min}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Spread max %</p>
            <p>{spread_percent_max}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Swap short %</p>
            <p>{swap_short}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Swap long %</p>
            <p>{swap_long}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Action</p>
            <div className='table-buttons flex-e'>
              <button onClick={() => onSetEditId?.(id as number)}>
                <EditIcon />
              </button>
              <Switch checked={is_active === 1} onChange={handleToggleActive} />
              <div className='btn--flex flex-e'>
                <button
                  className='button btn-copy'
                  disabled={fromCopy === id}
                  onClick={() => copyRow(id as number)}
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SwapRow;
