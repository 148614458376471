import { FC, useState } from 'react';
import { CircleUploadIcon, SearchIcon } from 'assets/svg-icon';
import { EFilterField } from 'enum/types';
import CustomInput from 'ui/CustomInput';
import CustomSelect from 'ui/CustomSelect';
import SelectCalendar from 'ui/SelectCalendar';
import { ITransactionsRequest } from 'store/redux-toolkit/transactions/transactions-type';
import moment from 'moment';

interface IFiltersProps {
  setParams: (val: ITransactionsRequest) => void;
}

const Filters: FC<IFiltersProps> = ({ setParams }) => {
  const [filterField, setFilterField] = useState('user_id');
  const [searchValue, setSearchValue] = useState('');
  const [paymentSearchValue, setPaymentSearchValue] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const handleSearch = () => {
    const params: Record<string, any> = {
      date_from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
      date_to: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : startDate
        ? moment().format('YYYY-MM-DD')
        : undefined,
    };
    if (filterField && searchValue) {
      params.search_field = filterField;
      params.search_value = searchValue;
    }
    if (paymentSearchValue) {
      params.payment_search_value = paymentSearchValue;
    }
    setParams(params);
  };

  return (
    <div className='table-filter table-filter--transactions'>
      <button type='button' className='button button--icon' onClick={handleSearch}>
        <CircleUploadIcon />
      </button>
      <div className='search-by'>
        <CustomSelect obj={EFilterField} element={filterField} setElement={setFilterField} />
        <CustomInput
          value={searchValue}
          onChange={setSearchValue}
          iconRight={<SearchIcon />}
          placeholder='Search by user id and email'
        />
      </div>
      <SelectCalendar startDate={startDate} setStartDate={setStartDate} />
      <SelectCalendar startDate={endDate} setStartDate={setEndDate} />
      <div className='search-by'>
        <CustomInput
          value={paymentSearchValue}
          onChange={setPaymentSearchValue}
          iconRight={<SearchIcon />}
          placeholder='Search by Payment System'
        />
      </div>
    </div>
  );
};

export default Filters;
