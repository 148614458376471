import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { APP_NAME } from 'services/constants/env';
import usePermissions from 'hooks/usePermissions';
import { ITransactionsRequest } from 'store/redux-toolkit/transactions/transactions-type';
import Filters from 'ui/filters/TransactionsFilter';
import Withdrawals from 'components/Withdrawals';

export interface ITransactionTabProps {
  params: ITransactionsRequest | undefined;
  setParams: (val: ITransactionsRequest) => void;
}

const WithdrawalsPage = () => {
  const appTitle = APP_NAME || '';
  const [filterParams, setFilterParams] = useState<ITransactionsRequest>();
  const { checkPermission } = usePermissions();

  const props = {
    params: filterParams,
    setParams: setFilterParams,
  };

  return (
    <>
      {checkPermission('withdrawals') ? (
        <div className='main-content'>
          <div className='title-block-wrap'>
            <div className='title-block'>
              <p className='title'>Withdrawals</p>
            </div>
          </div>
          <Filters setParams={setFilterParams} />
          <Withdrawals {...props} />
        </div>
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default WithdrawalsPage;
