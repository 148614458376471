import ModalWrapper from '../../../../ui/ModalWrapper';
import { newPassword } from '../../../../store/redux-toolkit/users/users-thunks';
import { notificationContainer } from '../../../../ui/notificationContainer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ChangeEvent, useState } from 'react';

interface IProps {
  isOpened: boolean;
  closeModal: () => void;
}

function NewPasswordModal({ isOpened, closeModal }: IProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const [password, setPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true);

  const changePassword = () => {
    if (!user || !password || !isValidPassword) return;
    dispatch(
      newPassword({
        apiParams: {
          email: user.email,
          password: password,
        },
        onSuccess: () => {
          notificationContainer(user.email + ' password changed successfully.', 'success');
          setPassword('');
          closeModal();
        },
      })
    );
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsValidPassword(validatePassword(newPassword));
  };

  return (
    <ModalWrapper openModal={isOpened} setOpenModal={closeModal} customClass='popup--width-500'>
      <div className='popup-header'>
        <div className='popup-header__title-block'>
          <p className='popup-header__title'>New Password</p>
        </div>
      </div>
      {user && user.email && (
        <div className='popup-body'>
          <div>
            <p className='button--full-width popup-text--center'>
              Do you want to set new password for
            </p>
            <i className='popup-text--center button--full-width forgot-modal--email'>
              {user.email}
            </i>
            <input
              type='password'
              className='input-item input-item--td-type new-password'
              placeholder='New Password'
              value={password}
              onChange={handleChangePassword}
            />
            {!isValidPassword && (
              <p style={{ color: 'red' }}>
                Password must be at least 8 characters and include 1 uppercase letter, 1 lowercase
                letter, 1 special character and 1 number.
              </p>
            )}
          </div>

          <div className='popup-submit popup-submit--mob-column'>
            <button onClick={closeModal} className='button button--full-width button--type2'>
              Cancel
            </button>
            <button onClick={changePassword} className='button button--full-width'>
              Change Password
            </button>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
}

export default NewPasswordModal;

function validatePassword(password: string) {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z\d]).{8,}$/;
  return regex.test(password);
}
