import {
  getSettingBySlug,
  getSettings,
  toggleSetting,
} from 'store/redux-toolkit/settings/settings-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGetSettingsRequest,
  ISettingsResponse,
  IToggleSettingRequest,
  SettingsState,
} from './settings-types';

const initialState: SettingsState = {
  settingsList: null,
  settingList: null,
  setting: null,
  loading: false,
  error: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state, { payload }: PayloadAction<ISettingsResponse>) => {
        state.settingsList = payload;
      })
      .addCase(
        getSettingBySlug.fulfilled,
        (state, { payload }: PayloadAction<ISettingsResponse>) => {
          state.settingList = payload;
        }
      )
      .addCase(toggleSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(toggleSetting.fulfilled, (state, action) => {
        state.loading = false;
        const { id, is_active } = action.payload;
        if (state.settingsList && state.settingsList[id]) {
          state.settingsList[id].is_active = is_active;
        }
      })
      .addCase(toggleSetting.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// export const { settingInitState } = settingsSlice.actions;

export default settingsSlice.reducer;
