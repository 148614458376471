import { FC, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import PasswordField from 'ui/Formik/PasswordField';
import { MailIconGray, UserIcon, UserKeyIcon } from 'assets/svg-icon';
import { ILoginFormProps, IFormValues } from '../types';
import Input from 'ui/Formik/Input';

type TFormInnerProps = Omit<ILoginFormProps, 'onSubmit'>;

const FormAdminInner: FC<TFormInnerProps> = ({ isEdit, values: defaultValues }) => {
  const { isSubmitting, setValues } = useFormikContext<IFormValues>();

  useEffect(() => {
    if (!isEdit) {
      return;
    }
    const { first_name = '', last_name = '' } = defaultValues || {};
    setValues({
      first_name,
      last_name,
      password: '',
      password_repeat: '',
    });
  }, [isEdit, defaultValues, setValues]);

  return (
    <>
      <div className='forms-row forms-row--admin-management'>
        <div className='form form--mt-0  form--admin-management'>
          <p className='block-title'>Name</p>
          <div className='form__content'>
            <Field
              name='first_name'
              label='First name'
              iconLeft={<UserIcon />}
              component={Input}
              placeholder='First name'
            />
            <Field
              name='last_name'
              label='Last name'
              iconLeft={<UserIcon />}
              component={Input}
              placeholder='Last name'
              autoComplete='off'
            />
            {!isEdit && (
              <Field
                name='email'
                label='Email'
                iconLeft={<MailIconGray />}
                component={Input}
                placeholder='Email address'
              />
            )}
          </div>
        </div>
        <div className='form form--mt-0 form--admin-management'>
          <p className='block-title'>Password</p>
          <div className='form__content'>
            <Field
              name='password'
              label='Password'
              component={PasswordField}
              iconLeft={<UserKeyIcon />}
              placeholder='Enter password'
              autoComplete='new-password'
            />
            <Field
              name='password_repeat'
              label='Repeat Password'
              component={PasswordField}
              iconLeft={<UserKeyIcon />}
              placeholder='Enter password'
            />
          </div>
        </div>
      </div>
      <div className='form-submit form-submit--mt-type3 form-submit--start'>
        <button type='submit' disabled={isSubmitting} className='button button--width-165'>
          Save
        </button>
      </div>
    </>
  );
};

export default FormAdminInner;
