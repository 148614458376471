import ReferralSystem from 'components/Settings/ReferralSystem';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { APP_NAME } from 'services/constants/env';
import usePermissions from 'hooks/usePermissions';

const ReferralSystemPage = () => {
  const appTitle = APP_NAME || '';
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission('commissions') ? (
        <>
          <Helmet>
            <title>{`${appTitle} | Referral System`}</title>
          </Helmet>
          <ReferralSystem />
        </>
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default ReferralSystemPage;
