import { FC } from 'react';
import moment from 'moment';
import { IReferralTransactionItem } from 'store/redux-toolkit/users/users-types';

interface IProps {
  item: IReferralTransactionItem;
}

const TransferTableRow: FC<IProps> = ({ item }) => {
  const { id, user_id, referral_user_id, amount, created_at } = item;
  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <a href='' className='link'>
            {id}
          </a>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Date & Time</p>
        <p>{moment(created_at).format('DD/MM/YYYY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Asset</p>
        <p>USD</p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Amount</p>
        <p>{amount}</p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Status</p>
        <div className='td-status'>
          <span className='td-status__name'>Successful</span>
        </div>
      </div>
    </div>
  );
};

export default TransferTableRow;
