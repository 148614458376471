import React, { useCallback, useEffect, useState } from 'react';
import TradesFilter from './TradesFilter';
import { NavLink, Navigate, useParams } from 'react-router-dom';
import TradeHistory from './TradeHistory';
import OpenPositions from './OpenPositions';
import PendingOrders from './PendingOrders';
import { ERoutes } from 'enum/types';
import { ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import OpenOrderAdd from '../../ui/modals/OpenOrderAdd';
import { getAssets } from '../../store/redux-toolkit/trades/trades-thunks';
import { getMarkets } from '../../store/redux-toolkit/commission/commission-thunks';
import { useAppDispatch } from '../../store/hooks';

export enum ETableRout {
  history = 'trade-history',
  open = 'open-positions',
  pending = 'pending-order',
}

const Trades = () => {
  const { table } = useParams();
  const [filterParams, setFilterParams] = useState<ITradeRequest>();
  const dispatch = useAppDispatch();
  const [isOpenOrderAddOpened, setAddOrderModalOpened] = useState(false);

  const getTabContent = useCallback(() => {
    switch (table) {
      case ETableRout.history:
        return <TradeHistory {...filterParams} />;
      case ETableRout.open:
        return <OpenPositions {...filterParams} />;
      case ETableRout.pending:
        return <PendingOrders {...filterParams} />;
      default:
        return <Navigate to={ETableRout.history} replace />;
    }
  }, [table, filterParams]);

  const openAddOrderModal = () => {
    setAddOrderModalOpened(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpened(false);
  };

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getMarkets());
  }, []);

  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Trades</p>
        </div>
      </div>
      <TradesFilter params={filterParams} setParams={setFilterParams} />

      <div className='block'>
        <div className='client-trade-nav-items-block'>
          <div className='block-nav '>
            <NavLink className='block-nav__item' to={`${ERoutes.trades}/${ETableRout.history}`}>
              Trade history
            </NavLink>
            <NavLink className='block-nav__item' to={`${ERoutes.trades}/${ETableRout.open}`}>
              Open positions
            </NavLink>
            <NavLink className='block-nav__item' to={`${ERoutes.trades}/${ETableRout.pending}`}>
              Pending order
            </NavLink>
          </div>
          <button type='button' className='button' onClick={openAddOrderModal}>
            Add order
          </button>
        </div>
        <OpenOrderAdd isOpened={isOpenOrderAddOpened} closeModal={closeAddOrderModal} />
        {getTabContent()}
      </div>
    </div>
  );
};

export default Trades;
