import { combineReducers } from '@reduxjs/toolkit';
import auth from './redux-toolkit/auth/auth-slice';
import dashboard from './redux-toolkit/dashboard/dashboard-slice';
import users from './redux-toolkit/users/users-slice';
import commissions from './redux-toolkit/commission/commission-slice';
import admins from './redux-toolkit/admins/admins-slice';
import transactions from './redux-toolkit/transactions/transactions-slice';
import trade from './redux-toolkit/trades/trades-slice';
import settings from './redux-toolkit/settings/settings-slice';

const createRootReducer = () =>
  combineReducers({
    auth,
    dashboard,
    users,
    commissions,
    admins,
    transactions,
    trade,
    settings,
  });

export default createRootReducer;
