import Limitations from 'components/Limitations';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { APP_NAME } from 'services/constants/env';
import usePermissions from 'hooks/usePermissions';

const LimitationsPage = () => {
  const appTitle = APP_NAME || '';
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission('limitations') ? (
        <>
          <Helmet>
            <title>{`${appTitle} | Limitations`}</title>
          </Helmet>
          <Limitations />
        </>
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default LimitationsPage;
