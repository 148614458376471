import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Dashboard from 'components/Dashboard';
import usePermissions from 'hooks/usePermissions';

const DashbordPage = () => {
  const appTitle = APP_NAME || '';
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission('dashboard') && (
        <>
          <Helmet>
            <title>{`${appTitle} | Clients`}</title>
          </Helmet>
          <Dashboard />
        </>
      )}
    </>
  );
};

export default DashbordPage;
