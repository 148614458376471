import { useCallback } from 'react';
import { Navigate, NavLink, useLocation, useParams } from 'react-router-dom';
import DepositTable from './DepositTable';
import WithdrawTable from './WithdrawTable';

enum ETableRout {
  deposit = 'deposit',
  withdraw = 'withdraw',
}

const TransactionsHistoryTab = () => {
  const { table } = useParams();
  const { pathname } = useLocation();

  const getTabContent = useCallback(() => {
    switch (table) {
      case ETableRout.deposit:
        return <DepositTable />;
      case ETableRout.withdraw:
        return <WithdrawTable />;

      default:
        return <Navigate to={ETableRout.deposit} replace={true} />;
    }
  }, [table]);
  return (
    <>
      <div className='block-nav'>
        <NavLink
          to={`${pathname.replace(String(table), '')}${ETableRout.deposit}`}
          className='block-nav__item'
        >
          Deposit
        </NavLink>
        <NavLink
          to={`${pathname.replace(String(table), '')}${ETableRout.withdraw}`}
          className='block-nav__item'
        >
          Withdraw
        </NavLink>
      </div>
      {getTabContent()}
    </>
  );
};

export default TransactionsHistoryTab;
