import { endpoint } from 'services/endpoint';
import requestService from 'services/interceptor/request-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import { IGetAdminAnalyticsRequest } from './dashboard-types';

export const getAdminAnalytics = createAsyncThunk(
  'dashboard/get-admin-analytics',
  async (payload: IGetAdminAnalyticsRequest) => {
    try {
      const response = await requestService.get(endpoint.dashboard.GET_ADMIN_ANALYTICS, payload);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
