import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAdminAnalytics } from 'store/redux-toolkit/dashboard/dashboard-thunks';
import { TTimePeriod } from 'store/redux-toolkit/dashboard/dashboard-types';
import { convertToChartData } from './utils';
import Summary from './Summary';
import ChartCard from './ChartCard';
import SelectPeriodBlock from './SelectPeriodBlock';
import Ranking from './Ranking';
import SymbolRanking from './SymbolRanking';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { analytics } = useAppSelector((state) => state.dashboard);
  const [period, setPeriod] = useState<TTimePeriod>('7_days');
  const [lossRankingsOrdering, setLossRankingsOrdering] = useState('desc');
  const [profitRankingsOrdering, setProfitRankingsOrdering] = useState('desc');

  useEffect(() => {
    dispatch(
      getAdminAnalytics({
        type_filter_time: period,
        loss_rankings_ordering: lossRankingsOrdering,
        profit_rankings_ordering: profitRankingsOrdering,
      })
    );
  }, [period, lossRankingsOrdering, profitRankingsOrdering]);

  const {
    analytic_general_amounts: generalAmounts = [],
    analytic_client_volumes: clientVolumes = [],
    analytic_client_profits: clientProfits = [],
    analytic_client_deposits: clientDeposits = [],
    analytic_trade_rankings: tradeRankings = [],
    analytic_symbol_rankings: symbolRankings = [],
    analytic_balance_rankings: balanceRankings = [],
    analytic_deposit_rankings: depositRankings = [],
    analytic_loss_rankings: lossRankings = [],
    analytic_profit_rankings: profitRankings = [],
  } = analytics || {};

  const clientVolumesChartData = convertToChartData(clientVolumes);
  const clientProfitsChartData = convertToChartData(clientProfits);
  const clientDepositsChartData = convertToChartData(clientDeposits);

  const clientVolumesSum = clientVolumes.reduce<number>((acc, curr) => {
    return acc + curr.amount;
  }, 0);

  const clientProfitsSum = clientProfits.reduce<number>((acc, curr) => {
    return acc + curr.amount;
  }, 0);

  const clientDepositsSum = clientDeposits.reduce<number>((acc, curr) => {
    return acc + curr.amount;
  }, 0);

  const onPeriodSelect = (_period: TTimePeriod) => {
    setPeriod(_period);
  };

  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Dashboard</p>
        </div>
      </div>
      <Summary analytics={generalAmounts} />
      <SelectPeriodBlock value={period} onSelect={onPeriodSelect} />
      <ChartCard title='Clients Volume' amount={clientVolumesSum}>
        <Bar data={clientVolumesChartData} />
      </ChartCard>
      <ChartCard title='Clients Profit' amount={clientProfitsSum}>
        <Bar data={clientProfitsChartData} />
      </ChartCard>
      <ChartCard title='Net Deposit' amount={clientDepositsSum}>
        <Bar data={clientDepositsChartData} />
      </ChartCard>
      <div className='dashboard-stat-box'>
        <Ranking title='Volume Ranking' data={tradeRankings} />
        <SymbolRanking title='Symbol Ranking' data={symbolRankings} />
        <Ranking title='Balance at the moment ranking' data={balanceRankings} />
        <Ranking title='Net deposit ranking' data={depositRankings} />
        <Ranking
          title='Loss ranking'
          data={lossRankings}
          ordering={lossRankingsOrdering}
          setOrdering={setLossRankingsOrdering}
        />
        <Ranking
          title='Profit ranking'
          data={profitRankings}
          ordering={profitRankingsOrdering}
          setOrdering={setProfitRankingsOrdering}
        />
      </div>
    </div>
  );
};

export default Dashboard;
