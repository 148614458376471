import React, { FC, useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAssets, getOpenOrders } from 'store/redux-toolkit/trades/trades-thunks';
import { ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import NoDataBlock from 'ui/NoDataBlock';
import OpenOrdersRow from './OpenOrdersRow';
import Pagination from 'ui/Pagination';
import UpdateTpSlOrder from 'components/modals/UpdateTpSlOrder';
import { clearActionPopUp } from 'store/redux-toolkit/trades/trades-slice';
import UpdateExpiration from 'components/modals/UpdateExpiration';

const PendingOrders: FC<ITradeRequest> = (params) => {
  const dispatch = useAppDispatch();
  const { openOrders, is_demo, actionPopUp } = useAppSelector((state) => state.trade);
  const [currentPage, setCurrentPage] = useState(1);

  const closeUpdateTpSlModal = () => {
    dispatch(clearActionPopUp());
  };

  const fetchPendingOrders = useCallback(() => {
    dispatch(getOpenOrders({ ...params, is_demo, current_page: currentPage }));
  }, [currentPage, params, is_demo]);

  useEffect(() => {
    fetchPendingOrders();
  }, [fetchPendingOrders]);

  useEffect(() => {
    setCurrentPage(1);
  }, [openOrders?.last_page]);

  useEffect(() => {
    dispatch(getAssets());
  }, []);

  return (
    <>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--trade-pending-orders table--with-scroll'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>Order</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>User ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Email</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Sub-Admin</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Type</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Volume</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Order Price</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>SL</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>TP</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Market Price</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Expiration data</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name'>Actions</div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {openOrders?.orders && !!Object.keys(openOrders?.orders).length ? (
                Object.keys(openOrders.orders).map((item) => (
                  <OpenOrdersRow
                    key={item}
                    items={openOrders?.orders[item]}
                    queryParams={params}
                    onRefetch={fetchPendingOrders}
                  />
                ))
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {openOrders && openOrders.last_page > 1 && (
        <Pagination
          pageCount={openOrders.last_page}
          forcePage={openOrders.current_page}
          onPageChange={setCurrentPage}
        />
      )}
      <UpdateTpSlOrder
        openModal={!!actionPopUp?.update_tp_sl}
        setOpenModal={closeUpdateTpSlModal}
      />
      <UpdateExpiration
        openModal={!!actionPopUp?.update_expiration}
        setOpenModal={closeUpdateTpSlModal}
      />
    </>
  );
};

export default PendingOrders;
