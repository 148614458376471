import { FC } from 'react';
import { useSelect } from 'hooks/useSelect';
import { ArrowDropStatusIcon } from 'assets/svg-icon';
import { capitalize } from 'utils/strings';
import { ISelectOptionsType } from './types';

export const SelectOptions: FC<ISelectOptionsType> = ({ data, value, onSelect, label }) => {
  const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

  const handleSelect = (selected: string) => {
    setOpen(false);
    onSelect(selected);
  };

  return (
    <div className='input'>
      <p className='input-name'>
        <span className='req'>*</span>
        {label}:
      </p>
      <div className={`select ${open ? 'active' : ''}`}>
        <button type='button' className='select__current' ref={triggerRef} onClick={toggleOpen}>
          {capitalize(value)}
          <span className='select__current-arrow'>
            <ArrowDropStatusIcon />
          </span>
        </button>
        <div className='select__drop' ref={dropRef}>
          <div className='select__drop-scroll'>
            <div className='select__drop-item'>
              <ul>
                {data.map((item) => (
                  <li key={item.id}>
                    {label == 'Asset' ? (
                      <button type='button' onClick={() => handleSelect(item.name)}>
                        {item.name as string}
                      </button>
                    ) : (
                      <button type='button' onClick={() => handleSelect(item.id as string)}>
                        {item.code as string}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
