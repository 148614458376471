import { FC } from 'react';
import { IDeleteAdmin } from '../types';

const DeleteAdmin: FC<IDeleteAdmin> = ({ onClose, onSubmit }) => {
  return (
    <div className='popup-window'>
      <div className='popup-window__inside'>
        <div className='popup popup--width-400'>
          <div className='popup-body'>
            <div className='popup-text popup-text--center'>
              <p>Are you sure you want to delete the admin?</p>
            </div>
            <div className='popup-submit  popup-submit--column'>
              <button
                className='button button--width-120 button--type-red'
                onClick={() => onClose?.()}
              >
                Cancel
              </button>
              <button className='button button--type-text button--text-green' onClick={onSubmit}>
                Yes, I’m sure
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAdmin;
