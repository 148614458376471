import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { Navigate, NavLink, useLocation, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setIsDemo } from 'store/redux-toolkit/trades/trades-slice';
import OpenPositionsTable from './OpenPositionsTable';
import PendingOrdersTable from './PendingOrdersTable';
import TradeHistoryTable from './TradeHistoryTable';
import OpenOrderAdd from '../../../ui/modals/OpenOrderAdd/';
import { getAssets } from '../../../store/redux-toolkit/trades/trades-thunks';
import { getMarkets } from '../../../store/redux-toolkit/commission/commission-thunks';

enum ETableRout {
  tradeHistory = 'trade-history',
  openPositions = 'open-positions',
  pendingOrders = 'pending-orders',
}

const TradeTab = () => {
  const { table } = useParams();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { is_demo } = useAppSelector((state) => state.trade);
  const [isOpenOrderAddOpened, setAddOrderModalOpened] = useState(false);
  const handleClickLive = () => {
    dispatch(setIsDemo(0));
  };

  const handleClickDemo = () => {
    dispatch(setIsDemo(1));
  };

  const getTabContent = useCallback(() => {
    switch (table) {
      case ETableRout.tradeHistory:
        return <TradeHistoryTable />;
      case ETableRout.openPositions:
        return <OpenPositionsTable />;
      case ETableRout.pendingOrders:
        return <PendingOrdersTable />;
      default:
        return <Navigate to={ETableRout.tradeHistory} replace={true} />;
    }
  }, [table]);

  const openAddOrderModal = () => {
    setAddOrderModalOpened(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpened(false);
  };

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getMarkets());
  }, []);

  return (
    <>
      <div className='block-nav'>
        <button
          type='button'
          className={classNames('block-nav__item', { active: !is_demo })}
          onClick={handleClickLive}
        >
          Live
        </button>
        <button
          type='button'
          className={classNames('block-nav__item', { active: is_demo })}
          onClick={handleClickDemo}
        >
          Demo
        </button>
      </div>
      <div className='client-trade-nav-items-block'>
        <div className='block-nav block-nav--big-mt'>
          <NavLink
            to={`${pathname.replace(String(table), '')}${ETableRout.tradeHistory}`}
            className='block-nav__item'
          >
            Trade history
          </NavLink>
          <NavLink
            to={`${pathname.replace(String(table), '')}${ETableRout.openPositions}`}
            className='block-nav__item'
          >
            Open positions
          </NavLink>
          <NavLink
            to={`${pathname.replace(String(table), '')}${ETableRout.pendingOrders}`}
            className='block-nav__item'
          >
            Pending order
          </NavLink>
        </div>
        <button type='button' className='button' onClick={openAddOrderModal}>
          Add order
        </button>
      </div>
      <OpenOrderAdd isOpened={isOpenOrderAddOpened} closeModal={closeAddOrderModal} />

      {getTabContent()}
    </>
  );
};

export default TradeTab;
