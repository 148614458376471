import { getAdminAnalytics } from './dashboard-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardState, IAdminAnalytics } from './dashboard-types';

const initialState: DashboardState = {
  analytics: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAdminAnalytics.fulfilled,
      (state, { payload }: PayloadAction<IAdminAnalytics>) => {
        state.analytics = payload;
      }
    );
  },
});

export default dashboardSlice.reducer;
