import React, { FC, useState } from 'react';
import { ArrowDropIcon, CloseIcon } from 'assets/svg-icon';
import { IOrderItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import RowChild from './RowChild';
import { ETradeType } from 'enum/types';
import classNames from 'classnames';

interface IOpenOrdersRowProps {
  items: IOrderItem[];
  queryParams: ITradeRequest;
  onRefetch?: () => void;
  columnVisibility: {
    order: boolean;
    user_id: boolean;
    type: boolean;
    volume: boolean;
    order_price: boolean;
    sl: boolean;
    tp: boolean;
    market_price: boolean;
    expiration_data: boolean;
    edit: boolean;
    close_all: boolean;
    price_markup: boolean;
    opened_by: boolean;
  };
  trUserTradePendingClassName?: string;
}

const OpenOrdersRow: FC<IOpenOrdersRowProps> = ({
  items,
  queryParams,
  onRefetch,
  columnVisibility,
  trUserTradePendingClassName,
}) => {
  const { asset } = items[0];
  const [show, setShow] = useState(true);

  const value = items.reduce((total: number, order: IOrderItem) => {
    order.transaction_type === ETradeType.buy ? (total += order.amount) : (total -= order.amount);
    return total;
  }, 0);

  const handleShowChild = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className='tr-group'>
      <div className={`tr ${trUserTradePendingClassName}`}>
        {columnVisibility.order && (
          <div className='td'>
            <p className='td-hidden-name'>Order</p>
            <div className='td-flex td-flex--with-tr-drop'>
              <button type='button' className='td-flex__drop-btn' onClick={handleShowChild}>
                <ArrowDropIcon />
              </button>
              <div className='td-symbol'>
                <p className='td-symbol__name'>
                  {asset.code} <span>{asset.market.name}</span>{' '}
                  <span className='td-symbol__count'>{items.length}</span>
                </p>
                <p className='td-symbol-details'>{asset.name} to American Dollar</p>
              </div>
            </div>
          </div>
        )}
        {columnVisibility.user_id && (
          <div className='td td--hide-mob'>
            <p className='td-hidden-name'>User ID</p>
            <p className='td-value td-value--fw-500 '></p>
          </div>
        )}
        {columnVisibility.type && (
          <div className='td td--hide-mob'>
            <p className='td-hidden-name'>Type</p>
            <p className='td-value td-value--fw-500'></p>
          </div>
        )}
        {columnVisibility.volume && (
          <div className='td'>
            <p className='td-hidden-name'>Volume</p>
            <p className='td-value td-value--fw-500'>{value}</p>
          </div>
        )}
      </div>
      <div className={classNames('tr-drop-list', { active: show })}>
        {items.map((el) => (
          <RowChild
            key={el.id}
            item={el}
            queryParams={queryParams}
            onRefetch={onRefetch}
            columnVisibility={columnVisibility}
            trUserTradePendingClassName={trUserTradePendingClassName}
          />
        ))}
      </div>
    </div>
  );
};

export default OpenOrdersRow;
