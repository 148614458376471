import React, { FC } from 'react';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';

interface IConfirmCanceledWithdrawProps {
  handleConfirm: () => void;
}

const ConfirmCanceledWithdraw: FC<IPopupProps & IConfirmCanceledWithdrawProps> = ({
  openModal,
  setOpenModal,
  handleConfirm,
}) => {
  return (
    <ModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      showOnDocumentClick
      customClass='popup--width-400'
    >
      <div className='popup-body'>
        <div className='popup-text popup-text--center'>
          <p>Are you sure you want to canceled?</p>
        </div>
        <div className='popup-submit  popup-submit--column'>
          <button
            className='button button--width-120 button--type-red'
            type='button'
            onClick={setOpenModal}
          >
            Cancel
          </button>
          <button
            className='button button--type-text button--text-green'
            type='button'
            onClick={handleConfirm}
          >
            Yes, I’m sure
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmCanceledWithdraw;
