import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import { ILoginSubmitValue } from '../../../components/Forms/LoginForm/types';
import { endpoint } from '../../../services/endpoint';
import RequestService from '../../../services/interceptor/request-service';
import { notificationContainer } from '../../../ui/notificationContainer';

export const login = createAsyncThunk(
  'auth/login',
  async (payload: ILoginSubmitValue, thunkApi) => {
    try {
      const response = await RequestService.post(endpoint.auth.LOGIN, payload);
      notificationContainer(`${payload.email}`, 'success');
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.data.errors[0] !== 'user_not_registered') {
          showError(error);
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0]);
      }
    }
  }
);
