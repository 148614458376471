import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  closeAllPositions,
  closePositionsByParams,
  getAssets,
  getOpenPositions,
} from 'store/redux-toolkit/trades/trades-thunks';
import { ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import { InfoCircleIcon } from 'assets/svg-icon';
import ClosedSelect from 'ui/ClosedSelect';
import NoDataBlock from 'ui/NoDataBlock';
import OpenPositionsRow from './OpenPositionsRow';
import Pagination from 'ui/Pagination';
import UpdateTpSlPosition from 'components/modals/UpdateTpSlPosition';
import { clearActionPopUp } from 'store/redux-toolkit/trades/trades-slice';
import { useParams } from 'react-router-dom';
import SelectPaginationBlock from '../../../SelectPaginationCount';
import { TPaginationCount } from '../../../SelectPaginationCount/types';

const columnVisibilityKey = 'openPositionsTableColumnVisibility';

const OpenPositionsTable: FC<ITradeRequest> = (params) => {
  const dispatch = useAppDispatch();
  const { openPositions, actionPopUp, is_demo } = useAppSelector((state) => state.trade);
  const [currentPage, setCurrentPage] = useState(1);
  const { userId } = useParams();
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [perPage, setPerPage] = useState<TPaginationCount>(20);

  const paramsMemo = useMemo(() => params, []);

  const isDemoMemo = useMemo(() => is_demo, [is_demo]);

  const initialColumnVisibilityString = localStorage.getItem(columnVisibilityKey);
  const initialColumnVisibility = initialColumnVisibilityString
    ? JSON.parse(initialColumnVisibilityString)
    : {
        position: true,
        user_id: true,
        sub_admin: true,
        type: true,
        volume: true,
        sl: true,
        tp: true,
        open_price: true,
        market_price: true,
        gross_profit: true,
        net_profit: true,
        net_p_l: true,
        rollover: true,
        edit: true,
        delete: true,
        close: true,
        price_markup: false,
        opened_by: false,
      };

  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);

  const closeAll = () => {
    dispatch(closeAllPositions({ apiParams: { is_demo }, filterParams: params }));
  };

  const closeProfitOrLoss = (profit: boolean) => {
    dispatch(
      closePositionsByParams({
        filterParams: params,
        apiParams: {
          is_demo,
          is_close_profitable_positions: profit,
        },
      })
    );
  };

  const closeUpdateTpSlModal = () => {
    dispatch(clearActionPopUp());
  };

  const fetchOpenPositions = useCallback(() => {
    dispatch(
      getOpenPositions({
        ...paramsMemo,
        is_demo: isDemoMemo,
        current_page: currentPage,
        per_page: perPage,
        user_id: Number(userId),
      })
    );
  }, [currentPage, paramsMemo, isDemoMemo]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    dispatch(
      getOpenPositions({
        ...paramsMemo,
        is_demo: isDemoMemo,
        current_page: currentPage,
        per_page: _perPage,
        user_id: Number(userId),
      })
    );
  };

  useEffect(() => {
    fetchOpenPositions();
  }, [fetchOpenPositions]);

  useEffect(() => {
    dispatch(getAssets());
  }, []);

  const handleColumnVisibilityChange = (column: string) => {
    const updatedVisibility = { ...columnVisibility, [column]: !columnVisibility[column] };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem(columnVisibilityKey, JSON.stringify(updatedVisibility));
  };

  const columnCount = Object.values(columnVisibility).filter((visible) => visible).length;
  let trUserTradePositionsClassName = '';

  if (columnCount > 16 && columnCount <= 18) {
    trUserTradePositionsClassName = `tr-${columnCount}`;
  }

  return (
    <>
      <div className='account-trade-column-visibility'>
        <div>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.position}
              onChange={() => handleColumnVisibilityChange('position')}
            />
            Position
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.user_id}
              onChange={() => handleColumnVisibilityChange('user_id')}
            />
            User ID
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.sub_admin}
              onChange={() => handleColumnVisibilityChange('sub_admin')}
            />
            Sub-Admin
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.type}
              onChange={() => handleColumnVisibilityChange('type')}
            />
            Type
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.volume}
              onChange={() => handleColumnVisibilityChange('volume')}
            />
            Volume
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.sl}
              onChange={() => handleColumnVisibilityChange('sl')}
            />
            SL
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.tp}
              onChange={() => handleColumnVisibilityChange('tp')}
            />
            TP
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.open_price}
              onChange={() => handleColumnVisibilityChange('open_price')}
            />
            Open Price
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.market_price}
              onChange={() => handleColumnVisibilityChange('market_price')}
            />
            Market Price
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.gross_profit}
              onChange={() => handleColumnVisibilityChange('gross_profit')}
            />
            Gross Profit
          </label>
        </div>
        <div>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.net_profit}
              onChange={() => handleColumnVisibilityChange('net_profit')}
            />
            Net Profit
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.net_p_l}
              onChange={() => handleColumnVisibilityChange('net_p_l')}
            />
            Net P/L %
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.rollover}
              onChange={() => handleColumnVisibilityChange('rollover')}
            />
            Rollover
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.edit}
              onChange={() => handleColumnVisibilityChange('edit')}
            />
            Edit
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.delete}
              onChange={() => handleColumnVisibilityChange('delete')}
            />
            Delete
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.close}
              onChange={() => handleColumnVisibilityChange('close')}
            />
            Close
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.price_markup}
              onChange={() => handleColumnVisibilityChange('price_markup')}
            />
            Price Markup
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.opened_by}
              onChange={() => handleColumnVisibilityChange('opened_by')}
            />
            Opened By
          </label>
        </div>
      </div>

      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table-mob-sort'>
            <ClosedSelect
              closeAll={closeAll}
              closeProfitLoss={closeProfitOrLoss}
              label='Close All'
            />
          </div>
          <div className='table table--user-trade-positions table--with-scroll'>
            <div className='table-header'>
              <div className={`tr ${trUserTradePositionsClassName}`}>
                {columnVisibility.position && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Position</p>
                    </div>
                  </div>
                )}
                {columnVisibility.user_id && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>User ID</p>
                    </div>
                  </div>
                )}
                {columnVisibility.sub_admin && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Sub-Admin</p>
                    </div>
                  </div>
                )}
                {columnVisibility.type && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Type</p>
                    </div>
                  </div>
                )}
                {columnVisibility.volume && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Volume</p>
                    </div>
                  </div>
                )}
                {columnVisibility.sl && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>SL</p>
                    </div>
                  </div>
                )}
                {columnVisibility.tp && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>TP</p>
                    </div>
                  </div>
                )}
                {columnVisibility.open_price && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Open Price</p>
                    </div>
                  </div>
                )}
                {columnVisibility.market_price && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Market Price</p>
                    </div>
                  </div>
                )}
                {columnVisibility.gross_profit && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Gross Profit</p>
                    </div>
                  </div>
                )}
                {columnVisibility.net_profit && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Net Profit</p>
                    </div>
                  </div>
                )}
                {columnVisibility.net_p_l && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Net P/L %</p>
                    </div>
                  </div>
                )}
                {columnVisibility.rollover && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Rollover</p>
                    </div>
                  </div>
                )}
                {columnVisibility.price_markup && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Price markup</p>
                    </div>
                  </div>
                )}
                {columnVisibility.opened_by && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Opened by</p>
                    </div>
                  </div>
                )}
                {/* <div className='td td--right'>
                  <div className='td-name'>
                    <ClosedSelect
                      closeAll={closeAll}
                      closeProfitLoss={closeProfitOrLoss}
                      label='Close All'
                    />
                  </div>
                </div> */}
                {columnVisibility.edit && (
                  <div className='td '>
                    <div className='td-name td-name--edit'>
                      <p>Edit</p>
                    </div>
                  </div>
                )}
                {columnVisibility.delete && (
                  <div className='td '>
                    <div className='td-name td-name--tooltip-type td-name--color-red'>
                      <p>Delete</p>
                      <span className='tooltip-item'>
                        <span className='tooltip-item__icon'>
                          <InfoCircleIcon />
                        </span>
                        <span className='tooltip  tooltip--dark tooltip--bottom tooltip--right'>
                          <span className='tooltip__text'>
                            Full delete button - completely removes the transaction from the system.
                            As if she never existed. Information about it does not remain in the
                            transaction history.
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                {columnVisibility.close && (
                  <div className='td td--right'>
                    <div className='td-name td-name--tooltip-type td-name--color-red'>
                      <p>Close</p>
                      <span className='tooltip-item'>
                        <span className='tooltip-item__icon'>
                          <InfoCircleIcon />
                        </span>
                        <span className='tooltip tooltip--dark tooltip--bottom tooltip--right'>
                          <span className='tooltip__text'>
                            Force close button - just closes the trade. The trader in his system
                            sees it in the transaction history as closed. It&apos;s like he closed
                            it himself.
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='table-body'>
              {openPositions?.positions && !!Object.keys(openPositions?.positions).length ? (
                Object.keys(openPositions.positions).map((item) => (
                  <OpenPositionsRow
                    key={item}
                    items={openPositions.positions[item]}
                    queryParams={params}
                    onRefetch={fetchOpenPositions}
                    columnVisibility={columnVisibility}
                    trUserTradePositionsClassName={trUserTradePositionsClassName}
                  />
                ))
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='pagination-block'>
        <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
        {openPositions && openPositions.last_page > 1 && (
          <Pagination
            pageCount={openPositions.last_page}
            forcePage={openPositions.current_page}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
      <UpdateTpSlPosition
        openModal={!!actionPopUp?.update_tp_sl}
        setOpenModal={closeUpdateTpSlModal}
      />
    </>
  );
};

export default OpenPositionsTable;
