import { useEffect, FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';
import Input from 'ui/Formik/Input';
import { ERoutes } from 'enum/types';
import { createPortal } from 'react-dom';
import {
  updatePosition,
  recalculateComissionProfit,
  recalculatePrice,
  deletePosition,
  closePosition,
} from 'store/redux-toolkit/trades/trades-thunks';
import { TTransactionType } from 'store/redux-toolkit/trades/trades-types';
import { notificationContainer } from 'ui/notificationContainer';
import { CloseIcon, ClockIcon, SaveIcon, Trash2Icon } from 'assets/svg-icon';
import { openPositionSchema } from 'form-validations/shemValidations';
import { numberOrNullToString } from 'utils/strings';
import { ConfirmPopup } from 'ui/ConfirmPopup';
import { TypeSelect } from './TypeSelect';
import { IOpenPositionEdit, IValues } from './types';

export const OpenPositionEdit: FC<IOpenPositionEdit> = ({
  item,
  onClose,
  onRefetch,
  isUserPage,
  isClosed,
}) => {
  const {
    id,
    order_id,
    user_id: userId,
    asset,
    transaction_type,
    take_profit,
    stop_loss,
    open_price,
    swap,
    profit,
    amount,
    freeze_amount,
    fee,
    updated_at,
  } = item;
  const dispatch = useAppDispatch();
  const { is_demo, deleteLoading } = useAppSelector((state) => state.trade);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [isPriceRecalculating, setIsPriceRecalculating] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [closeConfirm, setCloseConfirm] = useState(false);

  const initialValues: IValues = {
    open_price: numberOrNullToString(open_price),
    lot_volume: numberOrNullToString(amount),
    swap: numberOrNullToString(swap),
    fee: numberOrNullToString(fee),
    margin: numberOrNullToString(freeze_amount),
    transaction_type: transaction_type,
    stop_loss: numberOrNullToString(stop_loss),
    take_profit: numberOrNullToString(take_profit),
    open_time: moment(updated_at).format('DD/MM/YYYY, HH:mm'),
    profit: numberOrNullToString(profit),
    close_time: moment(updated_at).format('DD/MM/YY, HH:mm'),
  };

  useEffect(() => {
    document.body.classList.add('popup-open');
    return () => {
      document.body.classList.remove('popup-open');
    };
  }, []);

  const handleDeletePosition = () => {
    dispatch(
      deletePosition({
        apiParams: {
          position_id: id,
          is_demo,
        },
        onSuccess: () => {
          notificationContainer('Position successfully deleted', 'success');
          onRefetch?.();
          setDeleteConfirm(false);
          onClose();
        },
      })
    );
  };

  const handleClosePosition = () => {
    dispatch(
      closePosition({
        apiParams: { position_id: id, is_demo },
        filterParams: {},
        onSuccess: () => {
          notificationContainer('Position successfully closed', 'success');
          onRefetch?.();
          setCloseConfirm(false);
          onClose();
        },
      })
    );
  };

  return (
    <>
      <div className='popup-window '>
        <div className='popup-window__inside'>
          <div className='popup popup--width-880'>
            <button type='button' className='popup__close' onClick={onClose}>
              <CloseIcon />
            </button>
            <div className='popup-header'>
              {isUserPage ? (
                <div className='popup-header__title-block'>
                  <p className='popup-header__title'>
                    {asset.code.toUpperCase()} {asset.market.name.toUpperCase()}{' '}
                    {asset.name.includes('/')
                      ? asset.name.slice(0, asset.name.indexOf('/'))
                      : asset.name}{' '}
                    to American Dollar
                  </p>
                </div>
              ) : (
                <div className='popup-header__title-block'>
                  <p className='popup-header__title'>Position {id}</p>
                  <p className='popup-header__title'>
                    User ID <Link to={`${ERoutes.users}/${userId}/profile`}>{userId}</Link>{' '}
                  </p>
                </div>
              )}
            </div>
            <div className='popup-body'>
              <Formik
                initialValues={initialValues}
                validationSchema={openPositionSchema}
                onSubmit={(values: IValues, { setSubmitting }) => {
                  setSubmitting(true);
                  dispatch(
                    updatePosition({
                      apiParams: {
                        position_id: id,
                        is_demo,
                        main_order_id: order_id,
                        transaction_type: values.transaction_type
                          ? (values.transaction_type as TTransactionType)
                          : undefined,
                        amount: values.lot_volume ? Number(values.lot_volume) : undefined,
                        trigger_price: values.open_price ? Number(values.open_price) : undefined,
                        take_profit: values.take_profit ? Number(values.take_profit) : undefined,
                        stop_loss: values.stop_loss ? Number(values.stop_loss) : undefined,
                      },
                      onSuccess: () => {
                        notificationContainer('Position successfully updated', 'success');
                        onClose();
                        onRefetch?.();
                      },
                      onFinally: () => {
                        setSubmitting(false);
                      },
                    })
                  );
                }}
              >
                {({ values, setFieldValue, isSubmitting }) => {
                  const handleRecalculate = () => {
                    setIsRecalculating(true);
                    dispatch(
                      recalculateComissionProfit({
                        apiParams: {
                          position_id: id,
                          is_demo,
                          transaction_type: values.transaction_type
                            ? (values.transaction_type as TTransactionType)
                            : undefined,
                          amount: values.lot_volume ? Number(values.lot_volume) : undefined,
                          trigger_price: values.open_price ? Number(values.open_price) : undefined,
                        },
                        onSuccess: (response) => {
                          const { trigger_price, commission, profit } = response;
                          setFieldValue('open_price', trigger_price);
                          setFieldValue('fee', commission);
                          setFieldValue('profit', profit);
                          notificationContainer('Successfully recalculated', 'success');
                        },
                        onFinally: () => {
                          setIsRecalculating(false);
                        },
                      })
                    );
                  };

                  const handleRecalculatePrice = () => {
                    setIsPriceRecalculating(true);
                    dispatch(
                      recalculatePrice({
                        apiParams: {
                          position_id: id,
                          is_demo,
                          transaction_type: values.transaction_type
                            ? (values.transaction_type as TTransactionType)
                            : undefined,
                          amount: values.lot_volume ? Number(values.lot_volume) : undefined,
                          trigger_price: values.open_price ? Number(values.open_price) : undefined,
                        },
                        onSuccess: (response) => {
                          const { trigger_price, commission, profit } = response;
                          setFieldValue('open_price', trigger_price);
                          setFieldValue('fee', commission);
                          setFieldValue('profit', profit);
                          notificationContainer('Successfully recalculated', 'success');
                        },
                        onFinally: () => {
                          setIsPriceRecalculating(false);
                        },
                      })
                    );
                  };

                  return (
                    <Form>
                      <div className='popup-order-box'>
                        <div className='inputs-row inputs-row--order'>
                          <div className='input'>
                            <p className='input-name'>Asset:</p>
                            <p className='value value--fs-14 value--fs-14--fw-500'>
                              {asset.code.toUpperCase()}/USD
                            </p>
                          </div>
                          <TypeSelect
                            value={values.transaction_type || ''}
                            onSelect={(_value) => setFieldValue('transaction_type', _value)}
                          />
                        </div>
                        <div className=' inputs-row  inputs-row--order'>
                          <Field
                            name='open_price'
                            label='Open Price:'
                            placeholder='10000001.000000'
                            component={Input}
                            required
                          />
                          <Field
                            name='stop_loss'
                            label='Stop Loss:'
                            placeholder='0.00000'
                            component={Input}
                          />
                        </div>
                        <div className=' inputs-row  inputs-row--order'>
                          <Field
                            name='lot_volume'
                            label='Lot’s Volume:'
                            placeholder='10'
                            component={Input}
                            required
                          />
                          <Field
                            name='take_profit'
                            label='Take Profit:'
                            placeholder='0.00000'
                            component={Input}
                          />
                        </div>
                        <div className=' inputs-row  inputs-row--order'>
                          <Field
                            name='swap'
                            label='Swap:'
                            placeholder='1.0000'
                            component={Input}
                            disabled
                          />
                          <div className='input'>
                            <p className='input-name'>Open Time:</p>
                            <div className='input-wrapper'>
                              <input
                                className='input-item input-item--pr50 '
                                type='text'
                                placeholder='2022-07-05 06:07:24'
                                disabled
                              />
                              <div className='input-icon input-icon--right'>
                                <ClockIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=' inputs-row  inputs-row--order'>
                          <Field
                            name='fee'
                            label='Commission:'
                            placeholder='0.0000'
                            component={Input}
                            disabled
                          />
                          <Field
                            name='profit'
                            label='Profit:'
                            placeholder='-1117148.2082'
                            component={Input}
                            disabled
                          />
                        </div>
                        <div className=' inputs-row  inputs-row--order'>
                          <Field
                            name='margin'
                            label='Margin:'
                            placeholder='10'
                            component={Input}
                            disabled
                          />
                          {isClosed && (
                            <Field
                              name='close_time'
                              label='Closed Time:'
                              placeholder=''
                              component={Input}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className='popup-submit popup-submit--sb popup-submit--720-wrap popup-submit--mob-column'>
                        <div className='popup-submit__group'>
                          <button
                            type='button'
                            className='button'
                            onClick={handleRecalculate}
                            disabled={isRecalculating}
                          >
                            Recalculate Commission, Profit
                          </button>
                          <button
                            type='button'
                            className='button button--type2'
                            onClick={handleRecalculatePrice}
                            disabled={isPriceRecalculating}
                          >
                            Prices to time
                          </button>
                        </div>
                        <button type='submit' className='button' disabled={isSubmitting}>
                          <span className='btn-icon'>
                            <SaveIcon />
                          </span>
                          Save
                        </button>
                      </div>
                      <div className='popup-submit popup-submit--start popup-submit--mob-column '>
                        <button
                          type='button'
                          className='button button--type4'
                          onClick={() => setDeleteConfirm(true)}
                        >
                          <span className='btn-icon'>
                            <Trash2Icon />
                          </span>
                          Delete position
                        </button>
                        {!isClosed && (
                          <button
                            type='button'
                            className='button button--type2'
                            onClick={() => setCloseConfirm(true)}
                          >
                            <span className='btn-icon'>
                              <CloseIcon />
                            </span>
                            Close Postion
                          </button>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {deleteConfirm &&
        createPortal(
          <ConfirmPopup
            body='Are you sure you want to delete it?'
            onClose={() => setDeleteConfirm(false)}
            onConfirm={handleDeletePosition}
            confirmDisabled={deleteLoading}
          />,
          document.getElementsByTagName('main')[0]
        )}
      {closeConfirm &&
        createPortal(
          <ConfirmPopup
            body='Are you sure you want to close it?'
            onClose={() => setCloseConfirm(false)}
            onConfirm={handleClosePosition}
          />,
          document.getElementsByTagName('main')[0]
        )}
    </>
  );
};
