import { FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCurrencyNetworks } from 'store/redux-toolkit/commission/commission-thunks';
import TableRow from './TableRow';

const Desposit = () => {
  const [editableId, setEditableId] = useState<number | null>(null);
  const { currencyNetworks } = useAppSelector((state) => state.commissions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrencyNetworks({ is_crypto: 1 }));
  }, [dispatch]);

  return (
    <div className='block block--type2'>
      <div className='table-block table-block--mt-0'>
        <div className='table-wrapper'>
          <div className='table table--limitation-deposit'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>Currency name</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Deposit min</p>
                  </div>
                </div>

                <div className='td td--right'>
                  <div className='td-name'>
                    <p>Action</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {currencyNetworks?.map((item) => (
                <TableRow
                  key={item.id}
                  item={item}
                  editableId={editableId}
                  onEdit={setEditableId}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desposit;
