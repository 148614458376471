import TradeTab from 'components/Account/TradeTab';
import ProfileTab from 'components/Account/ProfileTab';
import ReferralsTab from 'components/Account/ReferralsTab';
import TradeAccountTab from 'components/Account/TradeAccountTab';
import TransactionsHistoryTab from 'components/Account/TransactionsHistoryTab';
import AdminManagement from 'components/AdminManagement';
import CreateAdmin from 'components/AdminManagement/CreateAdmin';
import EditAdmin from 'components/AdminManagement/EditAdmin';
import ReferralProgramm from 'components/Commissions/ReferralProgram';
import SwapCommissions from 'components/Commissions/SwapCommissions';
import ComissionDeposit from 'components/Commissions/Deposit';
import ComissionFees from 'components/Commissions/Fees';
import ComissionLeverage from 'components/Commissions/Leverage';
import LimitationsWithdrawal from 'components/Limitations/Withdrawal';
import LimitationsDeposit from 'components/Limitations/Deposit';
import Users from 'components/Users';
import AccountPage from 'pages/AccountPage';
import AdminManagementPage from 'pages/AdminManagementPage';
import CommissionsPage from 'pages/CommissionsPage';
import LimitationsPage from 'pages/LimitationsPage';
import DashbordPage from 'pages/DashbordPage';
import EditUserPage from 'pages/EditUserPage';
import UsersPage from 'pages/UsersPage';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ERoutes } from '../enum/types';
import TradesPage from 'pages/TradesPage';
import DepositsPage from 'pages/DepositsPage';
import WithdrawalsPage from 'pages/WithdrawalsPage';
import TransfersPage from 'pages/TransfersPage';
import ManualDeposit from '../components/Commissions/ManualDeposit';
import TradingConditionsPage from 'pages/SettingsPage/TradingConditionsPage';
import DepositWithdrawalPage from 'pages/SettingsPage/DepositWithdrawalPage';
import MailingSetupPage from 'pages/SettingsPage/MailingSetupPage';
import KYCKYBPage from 'pages/SettingsPage/KYCKYBPage';
import ReferralSystemPage from 'pages/SettingsPage/ReferralSystemPage';
import RegistrationPage from 'pages/SettingsPage/RegistrationPage';
import LocalizationEditorPage from 'pages/SettingsPage/LocalizationEditorPage';
import SettingsSwapCommissions from 'components/Settings/TradingConditions/SwapCommissions';
import OtherSettings from 'components/Settings/TradingConditions/Other';
import SettingsCommissionLeverage from 'components/Settings/TradingConditions/Leverage';
import SettingsCommissionDeposit from 'components/Settings/DepositWithdrawal/Deposit';
import SettingsCommissionFees from 'components/Settings/DepositWithdrawal/Fees';
import SettingsManualDeposit from 'components/Settings/DepositWithdrawal/ManualDeposit';
import SettingsReferralProgram from 'components/Settings/ReferralSystem/ReferralProgram';

const AuthLayout = lazy(() => import('../layouts/AuthLayout'));

const PrivateRoutes = {
  path: ERoutes.home,
  element: <AuthLayout />,
  children: [
    {
      element: <DashbordPage />,
      index: true,
    },
    {
      element: <UsersPage />,
      path: ERoutes.users,
      children: [
        {
          element: <Users />,
          index: true,
        },
        {
          element: <AccountPage />,
          path: ERoutes.account,
          children: [
            {
              element: <ProfileTab />,
              path: ERoutes.profile,
            },
            {
              element: <TradeAccountTab />,
              path: ERoutes.trade_account,
            },
            {
              element: <TradeTab />,
              path: `${ERoutes.orders}/:table?`,
            },
            {
              element: <TransactionsHistoryTab />,
              path: `${ERoutes.trans_history}/:table?`,
            },
            {
              element: <ReferralsTab />,
              path: `${ERoutes.referrals}/:table?`,
            },
          ],
        },
        { element: <EditUserPage />, path: ERoutes.edit },
      ],
    },
    {
      element: <TradesPage />,
      path: `${ERoutes.trades}/:table?`,
    },
    { element: <DepositsPage />, path: `${ERoutes.deposits}` },
    { element: <WithdrawalsPage />, path: `${ERoutes.withdrawals}/:table?` },
    { element: <TransfersPage />, path: `${ERoutes.transfers}/:table?` },
    {
      element: <AdminManagementPage />,
      path: ERoutes.admin_management,
      children: [
        {
          element: <AdminManagement />,
          index: true,
        },
        {
          element: <EditAdmin />,
          path: `${ERoutes.admin_management}/:userId`,
        },
        {
          element: <CreateAdmin />,
          path: ERoutes.create_admin,
        },
      ],
    },
    {
      element: <CommissionsPage />,
      path: ERoutes.commissions,
      children: [
        {
          element: <SwapCommissions />,
          path: ERoutes.assets_commissions,
        },
        {
          element: <ReferralProgramm />,
          path: ERoutes.referral_program,
        },
        {
          element: <ComissionDeposit />,
          path: ERoutes.deposit,
        },
        {
          element: <ComissionFees />,
          path: ERoutes.withdrawal,
        },
        {
          element: <ComissionLeverage />,
          path: ERoutes.leverage,
        },
        {
          element: <ManualDeposit />,
          path: ERoutes.manual_deposit,
        },
        {
          path: ERoutes.commissions,
          element: <Navigate to={ERoutes.assets_commissions} replace />,
        },
      ],
    },
    {
      element: <TradingConditionsPage />,
      path: `${ERoutes.trading_conditions}`,
      children: [
        {
          element: <SettingsSwapCommissions />,
          path: ERoutes.assets_commissions,
        },
        {
          element: <SettingsCommissionLeverage />,
          path: ERoutes.leverage,
        },
        {
          element: <OtherSettings />,
          path: ERoutes.other_settings,
        },
        {
          path: ERoutes.trading_conditions,
          element: <Navigate to={ERoutes.assets_commissions} replace />,
        },
      ],
    },
    {
      element: <DepositWithdrawalPage />,
      path: `${ERoutes.deposit_withdrawal}`,
      children: [
        {
          element: <SettingsCommissionDeposit />,
          path: ERoutes.deposit,
        },
        {
          element: <SettingsCommissionFees />,
          path: ERoutes.withdrawal,
        },
        {
          element: <SettingsManualDeposit />,
          path: ERoutes.manual_deposit,
        },
        {
          path: ERoutes.deposit_withdrawal,
          element: <Navigate to={ERoutes.withdrawal} replace />,
        },
      ],
    },
    { element: <MailingSetupPage />, path: `${ERoutes.mailing_setup}` },
    { element: <KYCKYBPage />, path: `${ERoutes.kyc_kyb}` },
    {
      element: <ReferralSystemPage />,
      path: `${ERoutes.referral_system}`,
      children: [
        {
          element: <SettingsReferralProgram />,
          path: ERoutes.referral_program,
        },
        {
          path: ERoutes.referral_system,
          element: <Navigate to={ERoutes.referral_program} replace />,
        },
      ],
    },
    { element: <RegistrationPage />, path: `${ERoutes.registration}` },
    { element: <LocalizationEditorPage />, path: `${ERoutes.localization_editor}` },
    {
      element: <LimitationsPage />,
      path: ERoutes.limitations,
      children: [
        {
          element: <LimitationsWithdrawal />,
          path: ERoutes.withdrawal,
        },
        {
          element: <LimitationsDeposit />,
          path: ERoutes.deposit,
        },
        {
          path: ERoutes.limitations,
          element: <Navigate to={ERoutes.withdrawal} replace />,
        },
      ],
    },
  ],
};

export default PrivateRoutes;
