import { Helmet } from 'react-helmet';
import Users from 'components/Users';
import { APP_NAME } from 'services/constants/env';
import usePermissions from 'hooks/usePermissions';
import { Outlet, Navigate } from 'react-router-dom';

const UsersPage = () => {
  const appTitle = APP_NAME || '';
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission('clients') ? (
        <>
          <Helmet>
            <title>{`${appTitle} | Clients`}</title>
          </Helmet>
          <Outlet />
        </>
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default UsersPage;
