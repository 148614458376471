import {
  getAdmins,
  getAdminById,
  deleteAdmin,
  activateAdmin,
  getAllPermissions,
  editAdmin,
} from './admins-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminsState, IAdminsResponse, IAdmin, IPermission } from './admins-types';

const initialState: AdminsState = {
  adminsList: null,
  adminItem: null,
  allPermissionsList: null,
};

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmins.fulfilled, (state, { payload }: PayloadAction<IAdminsResponse>) => {
      state.adminsList = payload.data;
    });
    builder.addCase(getAdminById.fulfilled, (state, { payload }: PayloadAction<IAdmin>) => {
      state.adminItem = payload;
    });
    builder.addCase(
      deleteAdmin.fulfilled,
      (state, { payload: id }: PayloadAction<number | undefined>) => {
        if (!state.adminsList?.users) {
          return;
        }
        state.adminsList.users = state.adminsList.users.filter((item) => item.id !== id);
      }
    );
    builder.addCase(activateAdmin.fulfilled, (state, { payload }: PayloadAction<IAdmin>) => {
      if (!state.adminsList?.users) {
        return;
      }
      state.adminsList.users = state.adminsList.users.map((item) =>
        item.id === payload.id ? payload : item
      );
    });
    builder.addCase(
      getAllPermissions.fulfilled,
      (state, { payload }: PayloadAction<IPermission[]>) => {
        state.allPermissionsList = payload;
      }
    );
    builder.addCase(editAdmin.fulfilled, (state, { payload }: PayloadAction<IAdmin>) => {
      state.adminItem = payload;
    });
  },
});

export default adminsSlice.reducer;
