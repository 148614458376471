import { FC, useState, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { editCurrencyNetworks } from 'store/redux-toolkit/commission/commission-thunks';
import { notificationContainer } from 'ui/notificationContainer';
import { CloseTableBigIcon, CheckBigIcon, EditIcon } from 'assets/svg-icon';
import { isDecimal } from 'utils/regex';
import { ITableRow, IEditRowParams } from '../types';

const TableRow: FC<ITableRow> = ({ item, editableId, onEdit }) => {
  const dispatch = useAppDispatch();
  const {
    id,
    currency: { code },
    network_name,
    deposit_min,
  } = item;

  const [depositMin, setDepositMin] = useState<string>(String(deposit_min));

  const handleSubmit = () => {
    dispatch(
      editCurrencyNetworks({
        params: {
          currency_network_id: id,
          deposit_min: Number(depositMin),
        },
        onSuccess: () => {
          notificationContainer('Successfully edited', 'success');
          onEdit?.(null);
        },
      })
    );
  };

  useEffect(() => {
    setDepositMin(String(deposit_min));
  }, [editableId]);

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>Currency name</p>
        <p>
          {code?.toUpperCase()} {network_name?.toUpperCase()}
        </p>
      </div>
      {editableId === id ? (
        <>
          <div className='td'>
            <p className='td-hidden-name'>Deposit min</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={depositMin}
                  placeholder='Withdraw min'
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimal.test(value)) return;
                    setDepositMin(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='td td--right'>
            <p className='td-hidden-name'>Action</p>
            <div className='table-buttons flex-e'>
              <button onClick={() => onEdit?.(null)}>
                <CloseTableBigIcon />
              </button>
              <button onClick={handleSubmit}>
                <CheckBigIcon />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='td'>
            <p className='td-hidden-name'>Deposit min</p>
            <p>{deposit_min}</p>
          </div>

          <div className='td td--right'>
            <p className='td-hidden-name'>Action</p>
            <div className='table-buttons flex-e'>
              <button onClick={() => onEdit?.(id)}>
                <EditIcon />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TableRow;
