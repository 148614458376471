import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ERoutes } from 'enum/types';
import { moneyFormat } from 'utils/moneyFormat';
import { IRanking } from '../types';
import orderIcon from 'assets/images/order-icon.png';

const Ranking: FC<IRanking> = ({ title, data, ordering, setOrdering }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const toggleOrdering = () => {
    if (setOrdering) {
      setOrdering(ordering === 'desc' ? 'asc' : 'desc');
    }
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className='dashboard-stat'>
      <p className='subtitle'>{title}</p>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--volume-ranking'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>User ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Name</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Surname</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name td-ordering'>
                    <p>Balance</p>
                    {(title === 'Loss ranking' || title === 'Profit ranking') && (
                      <img
                        src={orderIcon}
                        alt='Toggle Ordering'
                        onClick={toggleOrdering}
                        style={{ cursor: 'pointer' }}
                        className='td-ordering--order-icon'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {paginatedData.map(({ id, user_id, user_name, user_surname, amount }) => (
                <div className='tr' key={id}>
                  <div className='td'>
                    <p className='td-hidden-name'>User ID</p>
                    <p>
                      <Link
                        to={`${ERoutes.users}/${user_id}/profile`}
                        className='button button--type-text'
                      >
                        {user_id}
                      </Link>
                    </p>
                  </div>
                  <div className='td'>
                    <p className='td-hidden-name'>Name</p>
                    <p>{user_name}</p>
                  </div>
                  <div className='td'>
                    <p className='td-hidden-name'>Surname</p>
                    <p>{user_surname}</p>
                  </div>
                  <div className='td td--right'>
                    <p className='td-hidden-name'>Balance</p>
                    <p>{moneyFormat(amount)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='pagination-rankings'>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handleClick(index + 1)}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Ranking;
