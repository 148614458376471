import { FC, useMemo, useEffect, useCallback } from 'react';
import { Field, FormikErrors, useFormikContext } from 'formik';
import Input from 'ui/Formik/Input';
import debounce from 'lodash.debounce';
import { useAppDispatch } from 'store/hooks';
import { getUser } from 'store/redux-toolkit/users/users-thunks';
import { IGetUserResponse } from 'store/redux-toolkit/users/users-types';
import { IAddDepositPopup, IValues } from '../types';
import Switch from '../../../../ui/Switch';
import { replaceLeadingOneWithZero } from 'utils/integers';

interface IProps extends Pick<IAddDepositPopup, 'onClose'> {
  isTrade: boolean | undefined;
}

const AddDepositFormInner: FC<IProps> = ({ onClose, isTrade }) => {
  const dispatch = useAppDispatch();

  const { values, setFieldError, setFieldTouched, setFieldValue } = useFormikContext<IValues>();

  const checkUserDeb = useMemo(
    () =>
      debounce((userId: string) => {
        dispatch(
          getUser({
            apiParams: { user_id: +replaceLeadingOneWithZero(userId) },
            onSuccess: (resp: IGetUserResponse) => {
              const { status, message } = resp;
              if (status === 'error' && message === 'user_not_found') {
                setFieldTouched('userId', true, false);
                setFieldError('userId', 'The user does not exist with this data');
              }
            },
          })
        );
      }, 250),
    []
  );

  const checkUser = useCallback((userId: string) => checkUserDeb(userId), [checkUserDeb]);

  useEffect(() => {
    values.userId && checkUser(values.userId);
  }, [values.userId]);

  return (
    <>
      {!isTrade && (
        <Field
          name='userId'
          label='User Id:'
          placeholder='12345'
          component={Input}
          className='input--error'
        />
      )}
      <div className='flex'>
        <Field
          name='amount'
          label='Amount:'
          placeholder='Enter amount'
          inputItemClass='input-item--pr75'
          component={Input}
          customLeft={<span className='input-coin'>USD</span>}
        />
        <div className='sec--width-50 to-profile-section'>
          <label>To Profile</label>
          <div className='switch--mt-15'>
            <Switch
              checked={values.toProfile}
              onChange={() => setFieldValue('toProfile', !values.toProfile)}
            />
          </div>
        </div>
      </div>
      {values.toProfile && (
        <Field
          name='paymentSystem'
          label='Payment System:'
          placeholder='Payment System'
          inputItemClass='input-item--pr75'
          component={Input}
        />
      )}
      <Field
        name='comment'
        label='Comment:'
        placeholder='Comment'
        inputItemClass='input-item--pr75'
        component={Input}
      />
      <div className='popup-submit popup-submit--mob-column'>
        <button className='button button--full-width button--type2 ' onClick={onClose}>
          Cancel
        </button>
        <button type='submit' className='button button--full-width '>
          Create
        </button>
      </div>
    </>
  );
};

export default AddDepositFormInner;
