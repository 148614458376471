import { resetAuthState } from 'store/redux-toolkit/auth/auth-slice';
import { store } from 'store/store';
import { IAxiosError } from './types';

const onError = (error: IAxiosError) => {
  const status = error.response ? error.response.status : null;
  const errorResponse: any = error.config;

  if (status === 401 && !errorResponse._retry) {
    store.dispatch(resetAuthState());
  }
  return Promise.reject(error);
};
export default onError;
