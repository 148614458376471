import { FC, useReducer, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { editCurrencyNetworks } from 'store/redux-toolkit/commission/commission-thunks';
import { notificationContainer } from 'ui/notificationContainer';
import { CloseTableBigIcon, CheckBigIcon, EditIcon } from 'assets/svg-icon';
import { isDecimal } from 'utils/regex';
import { ITableRow, IEditRowParams } from '../types';

const DepositTableRow: FC<ITableRow> = ({ item, editableId, onEdit }) => {
  const dispatch = useAppDispatch();
  const {
    id,
    currency: { code },
    network_name,
    deposit_fee_percent,
    deposit_fee_fixed,
  } = item;

  const initialStateValue = {
    feePercent: String(deposit_fee_percent),
    feeFixed: String(deposit_fee_fixed),
  };

  const [state, setState] = useReducer(
    (state: IEditRowParams, newState: Partial<IEditRowParams>) => ({
      ...state,
      ...newState,
    }),
    initialStateValue
  );

  const handleSubmit = () => {
    dispatch(
      editCurrencyNetworks({
        params: {
          currency_network_id: id,
          deposit_fee_fixed: Number(state.feeFixed),
          deposit_fee_percent: Number(state.feePercent),
        },
        onSuccess: () => {
          notificationContainer('Successfully edited', 'success');
          onEdit?.(null);
        },
      })
    );
  };

  useEffect(() => {
    setState(initialStateValue);
  }, [editableId]);

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>Currency</p>
        <p>
          {code?.toUpperCase()} {network_name?.toUpperCase()}
        </p>
      </div>
      {editableId === item.id ? (
        <>
          <div className='td'>
            <p className='td-hidden-name'>Fee %</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.feePercent}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimal.test(value)) return;
                    setState({ feePercent: value });
                  }}
                  placeholder='Fee %'
                />
              </div>
            </div>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Fee Fixed USD</p>
            <div className='td-input td-input--short'>
              <div className='field-wrap'>
                <input
                  type='text'
                  className='input-item input-item--td-type'
                  value={state.feeFixed}
                  placeholder='Fee Fixed USD'
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isDecimal.test(value)) return;
                    setState({ feeFixed: value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className='td td--right'>
            <p className='td-hidden-name'>Action</p>
            <div className='table-buttons flex-e'>
              <button onClick={() => onEdit?.(null)}>
                <CloseTableBigIcon />
              </button>
              <button onClick={handleSubmit}>
                <CheckBigIcon />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='td'>
            <p className='td-hidden-name'>Fee %</p>
            <p>{deposit_fee_percent}</p>
          </div>
          <div className='td'>
            <p className='td-hidden-name'>Fee Fixed USD</p>
            <p>{deposit_fee_fixed}</p>
          </div>
          <div className='td td--right'>
            <p className='td-hidden-name'>Action</p>
            <div className='table-buttons flex-e'>
              <button onClick={() => onEdit?.(id)}>
                <EditIcon />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DepositTableRow;
