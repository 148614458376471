import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getReferralTransactionsByUser } from 'store/redux-toolkit/users/users-thunks';
import NoDataBlock from 'ui/NoDataBlock';
import Pagination from 'ui/Pagination';
import TableRow from './TableRow';

const Payment = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const [page, setPage] = useState(1);

  const { referralTransactions } = useAppSelector((state) => state.users);

  const { data = [], last_page = 1 } = referralTransactions || {};

  useEffect(() => {
    dispatch(
      getReferralTransactionsByUser({
        user_id: Number(userId),
        transaction_type: 'from_balance',
        current_page: page,
      })
    );
  }, [dispatch, userId]);

  return (
    <>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--referral-payment'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Referral User ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Date & Time</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Asset</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name'>
                    <p>Amount</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {data.length > 0 ? (
                data.map((item) => <TableRow key={item.id} item={item} />)
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {last_page > 1 && (
        <Pagination pageCount={last_page} forcePage={page} onPageChange={setPage} />
      )}
    </>
  );
};

export default Payment;
