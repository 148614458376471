import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTradeHistory, getTradeHistoryDropdown } from 'store/redux-toolkit/trades/trades-thunks';
import TradeHistoryRow from './TradeHistoryRow';
import Pagination from 'ui/Pagination';
import { ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import NoDataBlock from 'ui/NoDataBlock';
import SelectPaginationBlock from '../../SelectPaginationCount';
import { TPaginationCount } from '../../SelectPaginationCount/types';

const TradeHistory: FC<ITradeRequest> = (params) => {
  const dispatch = useAppDispatch();
  const { tradeHistory, is_demo } = useAppSelector((state) => state.trade);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState<TPaginationCount>(20);

  const fetchTradeHistory = () => {
    dispatch(getTradeHistory({ ...params, is_demo, current_page: currentPage, per_page: perPage }));
  };

  useEffect(() => {
    fetchTradeHistory();
  }, [currentPage, params, is_demo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tradeHistory?.last_page]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    dispatch(
      getTradeHistory({ ...params, is_demo, current_page: currentPage, per_page: _perPage })
    );
  };

  return (
    <>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--trade-history'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>Symbol</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>User ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Position ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Type</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Volume</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Open time</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Open price</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Close time</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Close price</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Profit</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>NET P/L</p>
                  </div>
                </div>

                <div className='td'>
                  <div className='td-name'>
                    <p>Swap</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Commission</p>
                  </div>
                </div>
                <div className='td '>
                  <div className='td-name td-name--edit'>
                    <p>Edit</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name'>Status</div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {tradeHistory?.data.length ? (
                tradeHistory?.data.map((item) => (
                  <TradeHistoryRow key={item.id} item={item} onRefetch={fetchTradeHistory} />
                ))
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {tradeHistory?.data?.length && (
        <div className='pagination-block'>
          <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
          {tradeHistory.last_page > 1 && (
            <Pagination
              pageCount={tradeHistory.last_page}
              forcePage={tradeHistory.current_page}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TradeHistory;
