import { endpoint } from 'services/endpoint';
import requestService from 'services/interceptor/request-service';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import {
  IClosePositionsRequest,
  ICreateUpdateTpSlRequest,
  IDeleteOrderRequest,
  IDeleteTpSlRequest,
  ITradeRequest,
  IUpdateOrderExpirationRequest,
  IUpdatePositionPayload,
  IRecalcComissProfPayload,
  IDeletePositionPayload,
  IUpdatePendingOrderPayload,
  ICancelOrderPayload,
  ICreatePayloadRequest,
} from './trades-types';

export const getTradeHistory = createAsyncThunk(
  'trade/get-trade-history',
  async (params: ITradeRequest) => {
    try {
      const response = await requestService.get(endpoint.trade.GET_CLOSE_POSITIONS, {
        ...params,
      });
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getTradeHistoryDropdown = createAsyncThunk(
  'trade/get-trade-history-dropdown',
  async ({ isScroll, ...params }: ITradeRequest) => {
    try {
      const response = await requestService.get(endpoint.trade.GET_CLOSE_POSITIONS, {
        per_page: 8,
        ...params,
      });
      return { ...response.data, isScroll };
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getOpenPositions = createAsyncThunk(
  'trade/get-open-positions',
  async (params: ITradeRequest) => {
    try {
      const response = await requestService.get(endpoint.trade.GET_OPEN_POSITIONS, {
        ...params,
      });
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getOpenOrders = createAsyncThunk(
  'trade/get-open-orders',
  async (params: ITradeRequest) => {
    try {
      const response = await requestService.get(endpoint.trade.GET_OPEN_ORDERS, {
        ...params,
      });
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getAssets = createAsyncThunk('trade/get-assets', async () => {
  try {
    const response = await requestService.get(endpoint.trade.GET_ASSETS);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const createTpSlPosition = createAsyncThunk(
  'trade/create-tp-sl-position',
  async (params: ICreateUpdateTpSlRequest) => {
    try {
      const response = await requestService.put(endpoint.trade.CREATE_TP_SL, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const deleteTpSlPosition = createAsyncThunk(
  'trade/delete-tp-sl-position',
  async (params: IDeleteTpSlRequest) => {
    try {
      const response = await requestService.put(endpoint.trade.DELETE_TP_SL, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const deleteTpSlOrder = createAsyncThunk(
  'trade/delete-tp-sl-order',
  async (params: IDeleteTpSlRequest) => {
    try {
      const response = await requestService.put(endpoint.trade.DELETE_TP_SL_ORDER, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const closePosition = createAsyncThunk(
  'trade/close-position',
  async (params: IClosePositionsRequest, { dispatch }) => {
    try {
      await requestService.put(endpoint.trade.CLOSE_POSITION, params.apiParams);
      dispatch(getOpenPositions({ is_demo: params.apiParams.is_demo, ...params.filterParams }));
      params.onSuccess?.();
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const closePositionsByParams = createAsyncThunk(
  'trade/close-positions-by-params',
  async (params: IClosePositionsRequest, { dispatch }) => {
    try {
      if (params.filterParams.user_id) {
        await requestService.put(endpoint.trade.CLOSE_POSITIONS_BY_PARAMS_BY_USER, {
          ...params.apiParams,
          user_id: params.filterParams.user_id,
        });
      } else {
        await requestService.put(endpoint.trade.CLOSE_POSITIONS_BY_PARAMS, params.apiParams);
      }
      dispatch(getOpenPositions({ is_demo: params.apiParams.is_demo, ...params.filterParams }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const closeAllPositions = createAsyncThunk(
  'trade/close-all-positions',
  async (params: IClosePositionsRequest, { dispatch }) => {
    try {
      if (params.filterParams.user_id) {
        await requestService.put(endpoint.trade.CLOSE_ALL_POSITIONS_BY_USER, {
          ...params.apiParams,
          user_id: params.filterParams.user_id,
        });
      } else {
        await requestService.put(endpoint.trade.CLOSE_ALL_POSITIONS, params.apiParams);
      }
      dispatch(getOpenPositions({ is_demo: params.apiParams.is_demo, ...params.filterParams }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const closePositionsByAsset = createAsyncThunk(
  'trade/close-all-positions-by-asset',
  async (params: IClosePositionsRequest, { dispatch }) => {
    try {
      if (params.filterParams.user_id) {
        await requestService.put(endpoint.trade.CLOSE_POSITIONS_BY_ASSET_BY_USER, {
          ...params.apiParams,
          user_id: params.filterParams.user_id,
        });
      } else {
        await requestService.put(endpoint.trade.CLOSE_POSITIONS_BY_ASSET, params.apiParams);
      }
      dispatch(getOpenPositions({ is_demo: params.apiParams.is_demo, ...params.filterParams }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const deleteOrder = createAsyncThunk(
  'trade/delete-orser',
  async (params: IDeleteOrderRequest, { dispatch }) => {
    try {
      await requestService.put(endpoint.trade.DELETE_ORDER, params.apiParams);
      dispatch(getOpenOrders({ is_demo: params.apiParams.is_demo, ...params.filterParams }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const createTpSlOrder = createAsyncThunk(
  'trade/create-tp-sl-order',
  async (params: ICreateUpdateTpSlRequest) => {
    try {
      const response = await requestService.post(endpoint.trade.CREATE_TP_SL_ORDER, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const createOrder = createAsyncThunk(
  'trade/create-order',
  async (payload: ICreatePayloadRequest, { dispatch }) => {
    const { apiParams, onSuccess } = payload;
    try {
      const response = await requestService.post(endpoint.trade.CREATE_ORDER, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const createImmediateOrder = createAsyncThunk(
  'trade/create-immediate-order',
  async (payload: ICreatePayloadRequest, { dispatch }) => {
    const { apiParams, onSuccess } = payload;
    try {
      const response = await requestService.post(endpoint.trade.CREATE_IMMEDIATE_ORDER, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const updateOrderExpiration = createAsyncThunk(
  'trade/update-expiration',
  async (params: IUpdateOrderExpirationRequest) => {
    try {
      const response = await requestService.put(endpoint.trade.UPDATE_EXPIRATION, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const updatePosition = createAsyncThunk(
  'trade/update-position',
  async (payload: IUpdatePositionPayload) => {
    const { apiParams, onSuccess, onFinally } = payload;
    try {
      const response = await requestService.put(endpoint.trade.UPDATE_POSITION, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    } finally {
      onFinally?.();
    }
  }
);

export const recalculateComissionProfit = createAsyncThunk(
  'trade/recalculate-comission-profit',
  async (payload: IRecalcComissProfPayload) => {
    const { apiParams, onSuccess, onFinally } = payload;
    try {
      const response = await requestService.get(
        endpoint.trade.RECALCULATE_COMISSION_PROFIT,
        apiParams
      );
      const result = { ...response.data, position_id: apiParams.position_id };
      onSuccess?.(result);
      return result;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    } finally {
      onFinally?.();
    }
  }
);

export const recalculatePrice = createAsyncThunk(
  'trade/recalculate-price',
  async (payload: IRecalcComissProfPayload) => {
    const { apiParams, onSuccess, onFinally } = payload;
    try {
      const response = await requestService.get(
        endpoint.trade.RECALCULATE_COMISSION_PROFIT_PRICE,
        apiParams
      );
      const result = { ...response.data, position_id: apiParams.position_id };
      onSuccess?.(result);
      return result;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    } finally {
      onFinally?.();
    }
  }
);

export const deletePosition = createAsyncThunk(
  'trade/delete-position',
  async (payload: IDeletePositionPayload) => {
    const { apiParams, onSuccess } = payload;
    try {
      const response = await requestService.put(endpoint.trade.DELETE_POSITION, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const updatePendingOrder = createAsyncThunk(
  'trade/update-pending-order',
  async (payload: IUpdatePendingOrderPayload) => {
    const { apiParams, onSuccess, onFinally } = payload;
    try {
      const response = await requestService.put(endpoint.trade.UPDATE_ORDER, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    } finally {
      onFinally?.();
    }
  }
);

// TODO: Дубликат, нисправить
export const cancelOrder = createAsyncThunk(
  'trade/cancel-order',
  async (payload: ICancelOrderPayload) => {
    const { apiParams, onSuccess, onFinally } = payload;
    try {
      const response = await requestService.put(endpoint.trade.DELETE_ORDER, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    } finally {
      onFinally?.();
    }
  }
);
