import ModalWrapper from '../../../ui/ModalWrapper';
import { addOneAndZeros } from '../../../utils/integers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { CalendarIcon, SaveIcon } from '../../../assets/svg-icon';
import {
  createOrder,
  createImmediateOrder,
  getOpenPositions,
} from '../../../store/redux-toolkit/trades/trades-thunks';
import { Field, Form, Formik } from 'formik';
import Input from '../../Formik/Input';
import {
  addImmediateOrderAdminShema,
  addOrderAdminShema,
} from '../../../form-validations/shemValidations';
import { TypeSelect } from '../OpenPositionEdit/TypeSelect';
import { useEffect, useMemo, useState } from 'react';
import { SelectOptions } from './SelectOptions';
import { notificationContainer } from '../../notificationContainer';
import { useParams } from 'react-router-dom';
import SelectCalendar from '../../SelectCalendar';
import moment from 'moment/moment';
import { getAllUsers } from 'store/redux-toolkit/users/users-thunks';

interface IProps {
  isOpened: boolean;
  closeModal: () => void;
}

function AddOrderModal({ isOpened, closeModal }: IProps) {
  const { user, allUsers } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { assets, is_demo } = useAppSelector((state) => state.trade);
  const { markets } = useAppSelector((state) => state.commissions);
  const [transactionTypeValue, setTransactionTypeValue] = useState('buy');
  const [marketTypeValue, setMarketTypeValue] = useState('STC');
  const [filteredAssets, setFilteredAssets] = useState(assets);
  const [assetsCodeValue, setAssetsCodeValue] = useState('');
  const [selectedAssetValue, setSelectedAssetValue] = useState([] as any);
  const [currentPage, setCurrentPage] = useState(1);
  const { userId } = useParams();
  const isDemoMemo = useMemo(() => is_demo, [is_demo]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);
  const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD HH:mm:ss');
  const [selectedUserId, setSelectedUserId] = useState('');

  const replaceLeadingOneWithZero = (id: string | undefined) => {
    return id?.startsWith('1') ? '0' + id.slice(1) : id;
  };

  useEffect(() => {
    if (isOpened && !user?.id) {
      dispatch(getAllUsers({}));
    }
  }, [isOpened]);

  const handleUserChange = (event: { target: { value: any } }) => {
    const selectedValue = event.target.value;
    setSelectedUserId(selectedValue);
  };

  const addOrder = (values: any) => {
    const apiData = {
      user_id: user?.id ?? Number(selectedUserId),
      is_demo,
      asset_id: selectedAssetValue.id,
      transaction_type: transactionTypeValue,
      amount: values.lot_volume,
      trigger_price: values.open_price || undefined,
      stop_loss: values.stop_loss || undefined,
      take_profit: values.take_profit || undefined,
      date_expiration: formatDate(startDate),
    };

    dispatch(
      createOrder({
        apiParams: apiData,
        onSuccess: () => {
          notificationContainer('Order successfully Created', 'success');
          dispatch(
            getOpenPositions({
              ...{},
              is_demo: isDemoMemo,
              current_page: currentPage,
              user_id: Number(replaceLeadingOneWithZero(userId)),
            })
          );
          closeModal();
        },
      })
    );
  };
  const initialValues = {
    open_price: '',
    stop_loss: '',
    lot_volume: '',
    take_profit: '',
  };

  const immediateOrder = 'Immediate Order';
  const pendingOrder = 'Pending Order';
  const [orderType, setOrderType] = useState(immediateOrder);
  const [markedBid, setMarkedBid] = useState('');
  const [marketAsk, setMarketAsk] = useState('');
  const handleImmediateOrderTypeChange = () => {
    setOrderType(immediateOrder);
  };

  const handlePendingOrderTypeChange = () => {
    setOrderType(pendingOrder);
  };

  const addImmediateOrder = (marketValues: any) => {
    const apiData = {
      user_id: user?.id ?? Number(selectedUserId),
      is_demo,
      asset_id: selectedAssetValue.id,
      ask: marketValues.immediate_ask || undefined,
      bid: marketValues.immediate_bid || undefined,
      transaction_type: transactionTypeValue,
      amount: marketValues.immediate_lot_volume,
      stop_loss: marketValues.immediate_stop_loss || undefined,
      take_profit: marketValues.immediate_take_profit || undefined,
    };

    dispatch(
      createImmediateOrder({
        apiParams: apiData,
        onSuccess: () => {
          notificationContainer('Order successfully Created', 'success');
          dispatch(
            getOpenPositions({
              ...{},
              is_demo: isDemoMemo,
              current_page: currentPage,
              user_id: Number(replaceLeadingOneWithZero(userId)),
            })
          );
          closeModal();
        },
      })
    );
  };
  const initialMarketValues = {
    immediate_stop_loss: '',
    immediate_lot_volume: '',
    immediate_take_profit: '',
    immediate_ask: '',
    immediate_bid: '',
  };

  const handelSelectedAsset = (id: any) => {
    const selectedAsset = filteredAssets.find((el) => el.id === id);
    setSelectedAssetValue(selectedAsset);
    setAssetsCodeValue(selectedAsset?.code || '');
    setMarkedBid(selectedAsset?.bid?.toString() || '');
    setMarketAsk(selectedAsset?.ask?.toString() || '');
  };
  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  };

  useEffect(() => {
    if (isOpened) {
      setStartDate(new Date());
      setMarketTypeValue('STC');
      setTransactionTypeValue('buy');
      setOpenCalendar(false);
    }
  }, [isOpened]);

  useEffect(() => {
    const filteredAssetsList = assets?.filter((el) => el.market === marketTypeValue);
    setFilteredAssets(filteredAssetsList);
    setAssetsCodeValue(filteredAssetsList[0]?.code);
    setSelectedAssetValue(filteredAssetsList[0]);
  }, [marketTypeValue, assets]);

  return (
    <ModalWrapper openModal={isOpened} setOpenModal={closeModal} customClass='popup--width-1000'>
      <div className='popup-header'>
        <div className='popup-header__title-block'>
          {(() => {
            if (user?.id) {
              return (
                <p className='popup-header__title'>
                  Add order for user with ID: {addOneAndZeros(Number(user.id))}
                </p>
              );
            } else {
              return (
                <div className='trade-add-order-user-dropdown'>
                  <label className='popup-header__title'>Add order for user with ID: </label>
                  {allUsers && (
                    <select onChange={handleUserChange} value={selectedUserId} className='select'>
                      <option value=''>Select user</option>
                      {allUsers.map((user) => (
                        <option key={user.id} value={user.id}>
                          {addOneAndZeros(Number(user.id))} - {user.email}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              );
            }
          })()}
        </div>
        <div>
          <button
            type='button'
            className={
              orderType === immediateOrder
                ? 'button add-order-tab-buttons'
                : 'button add-order-tab-buttons button--type2'
            }
            onClick={handleImmediateOrderTypeChange}
          >
            Immediate execution
          </button>
          <button
            type='button'
            className={
              orderType === pendingOrder
                ? 'button add-order-tab-buttons'
                : 'button add-order-tab-buttons button--type2'
            }
            onClick={handlePendingOrderTypeChange}
          >
            Pending Order
          </button>
        </div>
      </div>
      {(() => {
        if (orderType === immediateOrder) {
          return (
            <Formik
              initialValues={initialMarketValues}
              validationSchema={addImmediateOrderAdminShema}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                addImmediateOrder(values);
              }}
              validateOnBlur
            >
              <Form>
                <div className='inputs-row inputs-row--order'>
                  {markets && (
                    <SelectOptions
                      label='Asset'
                      data={markets}
                      value={marketTypeValue}
                      onSelect={(_value) => setMarketTypeValue(_value)}
                    />
                  )}
                  {assets && (
                    <SelectOptions
                      label='Symbol'
                      data={filteredAssets}
                      value={assetsCodeValue}
                      onSelect={(_id) => handelSelectedAsset(_id)}
                    />
                  )}
                </div>
                <div className='inputs-row inputs-row--order'>
                  <Field
                    name='immediate_bid'
                    label='Bid:'
                    value={markedBid}
                    component={Input}
                    disabled
                  />
                  <Field
                    name='immediate_ask'
                    label='Ask:'
                    value={marketAsk}
                    component={Input}
                    disabled
                  />
                </div>
                <div className='inputs-row inputs-row--order'>
                  <Field
                    name='immediate_stop_loss'
                    label='S/L:'
                    placeholder='0.00000'
                    component={Input}
                  />
                  <Field
                    name='immediate_take_profit'
                    label='T/P:'
                    placeholder='0.00000'
                    component={Input}
                  />
                </div>
                <div className='inputs-row inputs-row--order'>
                  <TypeSelect
                    value={transactionTypeValue}
                    onSelect={(_value) => setTransactionTypeValue(_value)}
                  />
                  <Field
                    name='immediate_lot_volume'
                    label='Lot’s Volume:'
                    placeholder='10'
                    component={Input}
                    required
                  />
                </div>
                <div className='popup-submit create-order-save-button-wrapper popup-submit--720-wrap popup-submit--mob-column'>
                  <button type='submit' className='button'>
                    <span className='btn-icon'>
                      <SaveIcon />
                    </span>
                    Save
                  </button>
                </div>
              </Form>
            </Formik>
          );
        } else if (orderType === pendingOrder) {
          return (
            <Formik
              initialValues={initialValues}
              validationSchema={addOrderAdminShema}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                addOrder(values);
              }}
              validateOnBlur
            >
              <Form>
                <div className='inputs-row inputs-row--order'>
                  {markets && (
                    <SelectOptions
                      label='Asset'
                      data={markets}
                      value={marketTypeValue}
                      onSelect={(_value) => setMarketTypeValue(_value)}
                    />
                  )}
                  <TypeSelect
                    value={transactionTypeValue}
                    onSelect={(_value) => setTransactionTypeValue(_value)}
                  />
                </div>
                <div className='inputs-row inputs-row--order'>
                  {assets && (
                    <SelectOptions
                      label='Symbol'
                      data={filteredAssets}
                      value={assetsCodeValue}
                      onSelect={(_id) => handelSelectedAsset(_id)}
                    />
                  )}
                  <Field
                    name='stop_loss'
                    label='Stop Loss:'
                    placeholder='0.00000'
                    component={Input}
                  />
                </div>
                <div className='inputs-row inputs-row--order'>
                  <Field
                    name='open_price'
                    label='Open Price:'
                    placeholder='10000001.000000'
                    component={Input}
                    required
                  />
                  <Field
                    name='take_profit'
                    label='Take Profit:'
                    placeholder='0.00000'
                    component={Input}
                  />
                </div>
                <div className='inputs-row inputs-row--order'>
                  <Field
                    name='lot_volume'
                    label='Lot’s Volume:'
                    placeholder='10'
                    component={Input}
                    required
                  />
                  <div className='input'>
                    <p className='input-name'>Open Time:</p>
                    <div className='input-wrapper'>
                      <input
                        className='input-item input-item--pr50'
                        type='text'
                        placeholder='date'
                        readOnly
                        onClick={handleOpenCalendar}
                        value={moment(startDate).format('DD / MM / YYYY HH:mm:ss')}
                      />

                      {openCalendar && (
                        <SelectCalendar
                          startDate={startDate}
                          setStartDate={setStartDate}
                          setCloseCalendar={setOpenCalendar}
                          inline
                        />
                      )}
                      <div className='input-icon input-icon--right'>
                        <CalendarIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='inputs-row inputs-row--order'>
                  <Field name='bid' label='Bid:' value={markedBid} component={Input} disabled />
                  <Field name='ask' label='Ask:' value={marketAsk} component={Input} disabled />
                </div>
                <div className='popup-submit create-order-save-button-wrapper popup-submit--720-wrap popup-submit--mob-column'>
                  <button type='submit' className='button'>
                    <span className='btn-icon'>
                      <SaveIcon />
                    </span>
                    Save
                  </button>
                </div>
              </Form>
            </Formik>
          );
        }
      })()}
    </ModalWrapper>
  );
}

export default AddOrderModal;
