import { FC, useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  closeAllPositions,
  closePositionsByParams,
  getAssets,
  getOpenPositions,
} from 'store/redux-toolkit/trades/trades-thunks';
import { IPositionItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import ClosedSelect from 'ui/ClosedSelect';
import NoDataBlock from 'ui/NoDataBlock';
import OpenPositionsRow from './OpenPositionsRow';
import Pagination from 'ui/Pagination';
import { InfoCircleIcon } from 'assets/svg-icon';
import UpdateTpSlPosition from 'components/modals/UpdateTpSlPosition';
import { clearActionPopUp } from 'store/redux-toolkit/trades/trades-slice';

const OpenPositions: FC<ITradeRequest> = (params) => {
  const dispatch = useAppDispatch();
  const { openPositions, actionPopUp, is_demo } = useAppSelector((state) => state.trade);
  const [currentPage, setCurrentPage] = useState(1);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const closeAll = () => {
    dispatch(closeAllPositions({ apiParams: { is_demo }, filterParams: params }));
  };

  const closeProfitOrLoss = (profit: boolean) => {
    dispatch(
      closePositionsByParams({
        filterParams: params,
        apiParams: {
          is_demo,
          is_close_profitable_positions: profit,
        },
      })
    );
  };

  const closeUpdateTpSlModal = () => {
    dispatch(clearActionPopUp());
  };

  const fetchOpenPositions = useCallback(() => {
    dispatch(getOpenPositions({ ...params, is_demo, current_page: currentPage }));
  }, [currentPage, params, is_demo]);

  useEffect(() => {
    fetchOpenPositions();
  }, [fetchOpenPositions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [openPositions?.last_page]);

  useEffect(() => {
    dispatch(getAssets());
  }, []);

  return (
    <>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table-mob-sort'>
            <ClosedSelect
              closeAll={closeAll}
              closeProfitLoss={closeProfitOrLoss}
              label='Close All'
            />
          </div>
          <div className='table table--user-trade-positions table--with-scroll'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>Position</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Sub-Admin</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>User ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Type</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Volume</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>SL</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>TP</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Order Price</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Market Price</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Gross Profit</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Net Profit</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Net P/L %</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Rollover</p>
                  </div>
                </div>
                <div className='td '>
                  <div className='td-name td-name--edit'>
                    <p>Edit</p>
                  </div>
                </div>
                <div className='td '>
                  <div className='td-name td-name--tooltip-type td-name--color-red'>
                    <p>Delete</p>
                    <span className='tooltip-item'>
                      <span className='tooltip-item__icon'>
                        <InfoCircleIcon />
                      </span>
                      <span className='tooltip  tooltip--dark tooltip--bottom tooltip--right'>
                        <span className='tooltip__text'>
                          Full delete button - completely removes the transaction from the system.
                          As if she never existed. Information about it does not remain in the
                          transaction history.
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name td-name--tooltip-type td-name--color-red'>
                    <p>Close</p>
                    <span className='tooltip-item'>
                      <span className='tooltip-item__icon'>
                        <InfoCircleIcon />
                      </span>
                      <span className='tooltip tooltip--dark tooltip--bottom tooltip--right'>
                        <span className='tooltip__text'>
                          Force close button - just closes the trade. The trader in his system sees
                          it in the transaction history as closed. It&apos;s like he closed it
                          himself.
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {openPositions?.positions && !!Object.keys(openPositions?.positions).length ? (
                Object.keys(openPositions.positions).map((item) => (
                  <OpenPositionsRow
                    key={item}
                    items={openPositions.positions[item]}
                    queryParams={params}
                    onRefetch={fetchOpenPositions}
                  />
                ))
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {openPositions && openPositions.last_page > 1 && (
        <Pagination
          pageCount={openPositions.last_page}
          forcePage={openPositions.current_page}
          onPageChange={setCurrentPage}
        />
      )}
      <UpdateTpSlPosition
        openModal={!!actionPopUp?.update_tp_sl}
        setOpenModal={closeUpdateTpSlModal}
      />
    </>
  );
};

export default OpenPositions;
