import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IAdminsRequest,
  IAdminByIdRequest,
  IAdminCreateRequest,
  IAdminEditRequest,
  IAdminDeleteRequest,
  IActivateAdminRequest,
} from './admins-types';
import { endpoint } from 'services/endpoint';
import requestService from 'services/interceptor/request-service';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';

export const getAdmins = createAsyncThunk('admins/get-admins', async (params: IAdminsRequest) => {
  try {
    const response = await requestService.get(endpoint.admins.GET_ADMINS, params);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const getAdminById = createAsyncThunk(
  'admins/get-admin-by-id',
  async (params: IAdminByIdRequest) => {
    try {
      const response = await requestService.get(endpoint.admins.GET_ADMIN_BY_ID, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const createAdmin = createAsyncThunk(
  'admins/create-admin',
  async ({ params, onSuccess }: IAdminCreateRequest) => {
    try {
      const response = await requestService.post(endpoint.admins.CREATE_ADMIN, params);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const editAdmin = createAsyncThunk(
  'admins/edit-admin',
  async ({ id, onSuccess, params }: IAdminEditRequest) => {
    try {
      const response = await requestService.put(`${endpoint.admins.EDIT_ADMIN}/${id}`, params);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  'admins/delete-admin',
  async ({ id, onSuccess }: IAdminDeleteRequest) => {
    try {
      const response = await requestService.delete(`${endpoint.admins.DELETE_ADMIN}/${id}`);
      onSuccess?.();
      return id;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const activateAdmin = createAsyncThunk(
  'admins/activate-admin',
  async ({ id, onSuccess, ...rest }: IActivateAdminRequest) => {
    try {
      const response = await requestService.put(`${endpoint.admins.ACTIVATE_ADMIN}/${id}`, rest);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getAllPermissions = createAsyncThunk('admins/permissions', async () => {
  try {
    const response = await requestService.get(`${endpoint.admins.GET_PERMISSIONS}`);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});
