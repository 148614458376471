import { FC } from 'react';
import { IConfirmPopup } from './types';

export const ConfirmPopup: FC<IConfirmPopup> = ({ body, onClose, onConfirm, confirmDisabled }) => {
  return (
    <div className='popup-window popup-window--more-index'>
      <div className='popup-window__inside'>
        <div className='popup popup--width-400'>
          <div className='popup-body'>
            <div className='popup-text popup-text--center'>
              <p>{body}</p>
            </div>
            <div className='popup-submit  popup-submit--column'>
              <button className='button button--width-120 button--type-red ' onClick={onClose}>
                Cancel
              </button>
              <button
                className='button button--type-text button--text-green'
                onClick={onConfirm}
                disabled={confirmDisabled}
              >
                Yes, I’m sure
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
