import React, { FC } from 'react';

import classNames from 'classnames';
import { IPositionItem } from 'store/redux-toolkit/trades/trades-types';
import { ETradeHistopyStatus } from 'enum/types';
import { addZeros } from '../../../../../utils/integers';
import moment from 'moment/moment';
import { moneyFormat } from '../../../../../utils/moneyFormat';

interface IRowChild {
  item: IPositionItem;
  columnVisibility: {
    symbol: boolean;
    position_id: boolean;
    type: boolean;
    volume: boolean;
    open_time: boolean;
    open_price: boolean;
    close_time: boolean;
    close_price: boolean;
    profit: boolean;
    net_p_l: boolean;
    swap: boolean;
    commission: boolean;
    price_markup: boolean;
    close_price_markup: boolean;
    opened_by: boolean;
    closed_by: boolean;
    status: boolean;
  };
  trUserTradeHistoryClassName?: string;
}

const RowChild: FC<IRowChild> = ({ item, columnVisibility, trUserTradeHistoryClassName }) => {
  return (
    <>
      <div className={`tr ${trUserTradeHistoryClassName}`}>
        {columnVisibility.symbol && (
          <div className='td'>
            <p className='td-hidden-name'></p>
            <p></p>
          </div>
        )}
        {columnVisibility?.position_id && (
          <div className='td'>
            <p className='td-hidden-name'>Position ID</p>
            <p>
              <span className='link'>{addZeros(item.order_id)}</span>
            </p>
          </div>
        )}
        {columnVisibility?.type && (
          <div className='td'>
            <p className='td-hidden-name'>Type</p>
            <p className='td-value'>{item.transaction_type}</p>
          </div>
        )}
        {columnVisibility?.volume && (
          <div className='td'>
            <p className='td-hidden-name'>Volume</p>
            <p>{item.amount}</p>
          </div>
        )}
        {columnVisibility?.open_time && (
          <div className='td'>
            <p className='td-hidden-name'>Open time</p>
            <p>{moment(item.created_at).format('DD/MM/YY, HH:mm')}</p>
          </div>
        )}
        {columnVisibility?.open_price && (
          <div className='td'>
            <p className='td-hidden-name'>Open price</p>
            <p>{item.open_price}</p>
          </div>
        )}
        {columnVisibility?.close_time && (
          <div className='td'>
            <p className='td-hidden-name'>Close time</p>
            <p>{moment(item.updated_at).format('DD/MM/YY, HH:mm')}</p>
          </div>
        )}
        {columnVisibility?.close_price && (
          <div className='td'>
            <p className='td-hidden-name'>Close price</p>
            <p>{item.close_price}</p>
          </div>
        )}
        {columnVisibility?.profit && (
          <div className='td'>
            <p className='td-hidden-name'>Profit</p>
            <p className={classNames({ green: item.profit > 0, red: item.profit < 0 })}>
              {item.profit > 0 && '+'}
              {moneyFormat(item.profit)}
            </p>
          </div>
        )}
        {columnVisibility?.net_p_l && (
          <div className='td'>
            <p className='td-hidden-name'>NET P/L</p>
            <p className={classNames({ green: item.pnl_realized > 0, red: item.pnl_realized < 0 })}>
              {item.pnl_realized > 0 && '+'}
              {moneyFormat(item.pnl_realized)}
            </p>
          </div>
        )}
        {columnVisibility?.swap && (
          <div className='td'>
            <p className='td-hidden-name'>Swap</p>
            <p>{moneyFormat(item.swap)}</p>
          </div>
        )}
        {columnVisibility?.commission && (
          <div className='td'>
            <p className='td-hidden-name'>Commission</p>
            <p>{item.fee}</p>
          </div>
        )}
        {columnVisibility?.price_markup && (
          <div className='td'>
            <p className='td-hidden-name'>Price markup</p>
            <p>-</p>
            {/* TODO need to add correct value for price markup */}
          </div>
        )}
        {columnVisibility?.close_price_markup && (
          <div className='td'>
            <p className='td-hidden-name'>Close price markup</p>
            <p>-</p>
            {/* TODO need to add correct value for close_price_markup */}
          </div>
        )}
        {columnVisibility?.opened_by && (
          <div className='td'>
            <p className='td-hidden-name'>Opened by</p>
            <p>{item.order?.creator_class_name ? 'Client' : 'Admin'}</p>
          </div>
        )}
        {columnVisibility?.closed_by && (
          <div className='td'>
            <p className='td-hidden-name'>Closed by</p>
            <p>-</p>
            {/* TODO need to add correct value for closed_by */}
          </div>
        )}
        {columnVisibility?.status && (
          <div className='td td--right'>
            <p className='td-hidden-name'>Status</p>
            <div className='td-status td-status--in-process'>
              <span className='td-status__name'>
                {ETradeHistopyStatus[item.status as keyof typeof ETradeHistopyStatus]}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RowChild;
