export const BASE_URL = 'trade.sbhstocks.com';
export const API_BASE_URL = `https://back.cryptoforex.trade/api`;
export const endpoint = {
  auth: {
    LOGIN: '/admin/login',
    REGISTRATION: '/register',
  },
  dashboard: {
    GET_ADMIN_ANALYTICS: '/admin/get-admin-analytics',
  },
  users: {
    GET_USERS: '/admin/users',
    GET_ALL_USERS: '/admin/all-users',
    GET_USER_SETTINGS: '/admin/user/user_settings',
    BLOCKED_USER: (userId: number) => `/admin/user/${userId}/blocked`,
    EDIT_USER_DATA: '/admin/user/edit-user-data',
    GET_COUNTRIES: '/get-countries',
    DELETE_USER: (userId: number) => `/admin/user/${userId}/delete`,
    GET_TRADE_ANALYTIC_BY_USER: '/admin/get-trade-analytic-by-user',
    GET_REFERRAL_TRANSACTIONS_BY_USER: '/admin/get-referral-balance-transaction-by-user',
    CREATE_DEPOSIT: '/admin/create-deposit',
    RESET_PASSWORD: '/admin/client-password/change',
    NEW_PASSWORD: '/admin/client-password/new',
    GET_USER: '/admin/users/get-user',
    UPDATE_KYC: (userId: number) => `/admin/user/${userId}/update/kyc`,
    VERIFY_KYC: (userId: number) => `/admin/user/${userId}/verify/kyc`,
  },
  commissions: {
    GET_REFERRAL_COMMISSION: '/admin/get-referral-commission',
    EDIT_REFERRAL_COMMISSION: '/admin/edit-referral-commission',
    GET_ALL_ASSETS: '/admin/get-all-assets',
    GET_ALL_MARKETS: '/admin/get-all-markets',
    EDIT_ASSET: '/admin/edit-asset',
    CREATE_ASSET: '/admin/create-asset',
    GET_CURRENCY_NETWORKS: '/admin/get-currency-networks',
    EDIT_CURRENCY_NETWORKS: '/admin/edit-currency-network',
    GET_LEVERAGES: '/admin/get-leverages',
    EDIT_MARKET: '/admin/edit-market',
  },
  admins: {
    GET_ADMINS: '/admin/admins',
    GET_ADMIN_BY_ID: '/admin/get-admin-by-id',
    CREATE_ADMIN: '/admin/admins',
    EDIT_ADMIN: '/admin/admin',
    DELETE_ADMIN: '/admin/admin',
    ACTIVATE_ADMIN: '/admin/admin-activate',
    GET_PERMISSIONS: '/admin/permissions',
  },
  transactions: {
    GET_DEPOSIT_TRANSACTIONS_BY_USER: '/admin/get-all-deposit-transactions-by-user',
    GET_WITHDRAWAL_TRANSACTIONS_BY_USER: '/admin/get-withdrawal-transactions-by-user',
    GET_DEPOSIT_TRANSACTIONS: '/admin/get-all-deposit-transactions',
    GET_WITHDRAWAL_TRANSACTIONS: '/admin/get-withdrawal-transactions',
    APPROVE_WITHDRAWAL: '/admin/pending-withdrawal/approve',
    CANCEL_WITHDRAWAL: '/admin/pending-withdrawal/cancel',
    GET_TRANSFER_TRANSACTIONS: '/admin/get-all-referral-transfers',
    ADD_MANUAL_DEPOSIT: '/admin/manual-deposit',
  },
  trade: {
    GET_CLOSE_POSITIONS: '/admin/show-all-close-positions',
    GET_ASSETS: '/assets',
    GET_OPEN_POSITIONS: '/admin/show-all-open-positions',
    GET_OPEN_ORDERS: '/admin/get-all-open-orders',
    CREATE_TP_SL: '/admin/create-update-tp-sl-position',
    CREATE_TP_SL_ORDER: '/admin/create-update-tp-sl-order',
    DELETE_TP_SL_ORDER: '/admin/delete-tp-sl-order',
    DELETE_TP_SL: '/admin/delete-tp-sl-order-by-position',
    CLOSE_POSITION: '/admin/close-one-position',
    CLOSE_ALL_POSITIONS: '/admin/close-all-positions-on-platform',
    CLOSE_ALL_POSITIONS_BY_USER: '/admin/close-all-positions-by-user',
    CLOSE_POSITIONS_BY_PARAMS: '/admin/close-position-by-params-on-platform',
    CLOSE_POSITIONS_BY_PARAMS_BY_USER: '/admin/close-position-by-params-by-user',
    CLOSE_POSITIONS_BY_ASSET: '/admin/close-all-position-by-asset',
    CLOSE_POSITIONS_BY_ASSET_BY_USER: '/admin/close-all-user-position-by-asset',
    DELETE_ORDER: '/admin/cancel-order',
    UPDATE_EXPIRATION: '/admin/update-delete-date-expiration-order',
    UPDATE_POSITION: '/admin/update-position',
    RECALCULATE_COMISSION_PROFIT: '/admin/recalculate-commission-and-profit',
    RECALCULATE_COMISSION_PROFIT_PRICE: '/admin/recalculate-commission-and-profit-by-price-to-time',
    DELETE_POSITION: '/admin/delete-position',
    UPDATE_ORDER: '/admin/update-pending-order',
    CREATE_ORDER: '/admin/create-order',
    CREATE_IMMEDIATE_ORDER: '/admin/create-immediate-order',
  },
  settings: {
    TOGGLE_SETTING: '/admin/toggle-setting',
    GET_SETTINGS: '/admin/get-settings',
    GET_SETTING_BY_SLUG: '/admin/get-setting-by-slug',
  },
};
