import React, { FC } from 'react';
import { ERoutes, ETransactionStatus } from 'enum/types';
import { Link } from 'react-router-dom';

import { IDepositTransactionItem } from 'store/redux-toolkit/transactions/transactions-type';
import { addZeros, addOneAndZeros } from 'utils/integers';
import moment from 'moment';
import { moneyFormat } from 'utils/moneyFormat';

const DepositRow: FC<IDepositTransactionItem> = ({
  id,
  user_id,
  user,
  created_at,
  requested_currency,
  requested_amount,
  status,
  comment,
  payment_service,
}) => {
  const eStatus = ETransactionStatus[status as keyof typeof ETransactionStatus];
  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>User ID</p>
        <p>
          <Link to={`${ERoutes.users}/${user_id}/profile`} className='link'>
            {addOneAndZeros(user_id)}
          </Link>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Email</p>
        <p>
          <a href={`mailto:${user?.email}`} className='link'>
            {user?.email}
          </a>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Full name</p>
        <p>{user?.username || '-'}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Date</p>
        <p>{moment(created_at).format('MM/DD/YY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Currency</p>
        <p>{requested_currency.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Amount</p>
        <p>{moneyFormat(requested_amount)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Comment</p>
        <p>{comment || ''}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Payment system</p>
        <p>{payment_service || ''}</p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Status</p>
        <div className={`td-status ${status}`}>
          <span className='td-status__name'>{eStatus}</span>
        </div>
      </div>
    </div>
  );
};

export default DepositRow;
