import { useCallback } from 'react';
import { Navigate, NavLink, useLocation, useParams } from 'react-router-dom';
import Payment from './Payment';
import Transfer from './Transfer';

enum ETableRout {
  payment = 'payment',
  transfer = 'transfer',
}

const ReferralsTab = () => {
  const { table } = useParams();
  const { pathname } = useLocation();

  const getTabContent = useCallback(() => {
    switch (table) {
      case ETableRout.payment:
        return <Payment />;
      case ETableRout.transfer:
        return <Transfer />;

      default:
        return <Navigate to={ETableRout.payment} replace={true} />;
    }
  }, [table]);

  return (
    <>
      <div className='block-nav'>
        <NavLink
          to={`${pathname.replace(String(table), '')}${ETableRout.payment}`}
          className='block-nav__item'
        >
          Payment
        </NavLink>
        <NavLink
          to={`${pathname.replace(String(table), '')}${ETableRout.transfer}`}
          className='block-nav__item'
        >
          Transfer
        </NavLink>
      </div>
      {getTabContent()}
    </>
  );
};

export default ReferralsTab;
