import { FC, useRef, useState } from 'react';

import { ArrowDropIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import classNames from 'classnames';
import { ITableSelectProps } from './types';

const TableSelect: FC<ITableSelectProps> = ({ obj, element, setElement, placeholder }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const list = Object.keys(obj);
  const handleChange = (el: string) => {
    setElement(el);
    setOpen(false);
  };

  return (
    <div ref={ref} className={classNames('select select--table', { active: open })}>
      <button type='button' className='select__current' onClick={handleClick}>
        {placeholder}
        <span className='select__current-arrow'>
          <ArrowDropIcon />
        </span>
      </button>

      {list && (
        <div className='select__drop'>
          <div className='select__drop-scroll'>
            <div className='select__drop-item'>
              <ul>
                <li>
                  <button type='button' onClick={() => handleChange('')}>
                    All
                  </button>
                </li>
                {list.map((el: string) => (
                  <li key={el}>
                    <button type='button' onClick={() => handleChange(el)}>
                      {obj[el]}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableSelect;
