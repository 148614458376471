import { FC } from 'react';
import { useSelect } from 'hooks/useSelect';
import { ArrowDropStatusIcon } from 'assets/svg-icon';
import { capitalize } from 'utils/strings';
import { ITypeSelect } from './types';

export const TypeSelect: FC<ITypeSelect> = ({ value, onSelect }) => {
  const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

  const handleSelect = (selected: string) => {
    setOpen(false);
    onSelect(selected);
  };

  return (
    <div className='input'>
      <p className='input-name'>
        <span className='req'>*</span>Type:
      </p>
      <div className={`select ${open ? 'active' : ''}`}>
        <button
          type='button'
          className='select__current select__current--placeholder'
          ref={triggerRef}
          onClick={toggleOpen}
        >
          {capitalize(value)}
          <span className='select__current-arrow'>
            <ArrowDropStatusIcon />
          </span>
        </button>
        <div className='select__drop' ref={dropRef}>
          <div className='select__drop-scroll'>
            <div className='select__drop-item'>
              <ul>
                <li>
                  <button type='button' onClick={() => handleSelect('buy')}>
                    Buy
                  </button>
                </li>
                <li>
                  <button type='button' onClick={() => handleSelect('sell')}>
                    Sell
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
