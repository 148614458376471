import { FC } from 'react';
import { ISwitch } from './types';

const Switch: FC<ISwitch> = ({ checked, onChange }) => {
  return (
    <div className='switch switch--td-value'>
      <label className='switch__label'>
        <input type='checkbox' className='hidden' checked={checked} onChange={onChange} />
        <span className='switch__toggler'></span>
      </label>
    </div>
  );
};

export default Switch;
