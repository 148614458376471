import { FC, useEffect, useRef, useState } from 'react';

import { CircleUploadIcon, SearchIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { ETradeType } from 'enum/types';
import CustomInput from 'ui/CustomInput';
import CustomSelect from 'ui/CustomSelect';
import SelectCalendar from 'ui/SelectCalendar';
import { ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsDemo } from 'store/redux-toolkit/trades/trades-slice';
import { useParams } from 'react-router-dom';
import { ETableRout } from '..';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { getTradeHistoryDropdown } from '../../../store/redux-toolkit/trades/trades-thunks';
import { useInView } from 'react-intersection-observer';

interface IFiltersProps {
  params: ITradeRequest | undefined;
  setParams: (val: ITradeRequest) => void;
}

const SEARCH_TABLE_HEADER = ['userId', 'Email', 'Name', 'Surname'];

const TradesFilter: FC<IFiltersProps> = ({ params, setParams }) => {
  const { table } = useParams();
  const dispatch = useAppDispatch();
  const { tradeHistoryDropdown, tradeHistoryDropdownLoading, is_demo } = useAppSelector(
    (state) => state.trade
  );
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [positionId, setPositionId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [symbol, setSymbol] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [dropOpen, setDropOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(2);

  useOutsideClick(ref, () => setDropOpen(false));
  const { ref: observerTarget, inView } = useInView({
    threshold: 0,
  });
  const handleClickLive = () => {
    dispatch(setIsDemo(0));
  };

  const handleClickDemo = () => {
    dispatch(setIsDemo(1));
  };

  const generateParams = (isScroll = false, addParams?: any) => {
    const newParams = {
      position_id: Number(positionId) || undefined,
      order_id: Number(orderId) || undefined,
      asset_code: symbol || undefined,
      search_term: searchTerm || undefined,
      transaction_type: transactionType || undefined,
      date_from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
      isScroll,
      date_to: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : startDate
        ? moment().format('YYYY-MM-DD')
        : undefined,
      ...addParams,
    };

    return { ...params, ...newParams };
  };

  const handleSearch = () => {
    setParams(generateParams());
  };

  const handleClear = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setPositionId('');
    setOrderId('');
    setSymbol('');
    setSearchTerm('');
    setTransactionType('');
    setParams({ is_demo });
  };

  useEffect(() => {
    const deb = setTimeout(() => {
      handleSearch();
      if (searchTerm) {
        const params = generateParams(false, { current_page: 1, per_page: 15 });
        setPage(2);
        dispatch(getTradeHistoryDropdown({ ...params, is_demo }));
      }
    }, 700);

    return () => {
      clearTimeout(deb);
    };
  }, [searchTerm]);

  const handleSelectDropItem = (value: string) => {
    setSearchTerm(value);
    setDropOpen(false);
  };

  const handleChangeSearch = (value: string) => {
    if (value) {
      setDropOpen(true);
    } else {
      setDropOpen(false);
    }
    setSearchTerm(value);
  };

  useEffect(() => {
    if (
      !inView ||
      tradeHistoryDropdownLoading ||
      (tradeHistoryDropdown && tradeHistoryDropdown.last_page + 1 === page)
    )
      return;
    const params = generateParams(true, { current_page: page, per_page: 15 });
    dispatch(getTradeHistoryDropdown({ ...params, is_demo }));
    setPage((prev) => prev + 1);
  }, [observerTarget, inView, page]);

  return (
    <>
      <div className='table-filter table-filter--flex-type'>
        <button type='button' className='button button--icon' onClick={handleSearch}>
          <CircleUploadIcon />
        </button>
        <SelectCalendar startDate={startDate} setStartDate={setStartDate} />
        <SelectCalendar startDate={endDate} setStartDate={setEndDate} />
      </div>
      <div className='block'>
        <div className='table-filter table-filter--trade  table-filter--trade-fisrt'>
          {table === ETableRout.pending ? (
            <CustomInput
              value={orderId}
              onChange={setOrderId}
              iconRight={<SearchIcon />}
              placeholder='Search'
              label='Order ID'
            />
          ) : (
            <CustomInput
              value={positionId}
              onChange={setPositionId}
              iconRight={<SearchIcon />}
              placeholder='Search'
              label='Position ID'
            />
          )}

          <CustomInput
            value={symbol}
            onChange={setSymbol}
            iconRight={<SearchIcon />}
            placeholder='Search'
            label='Symbol'
          />
        </div>
        <div className='table-filter table-filter--trade table-filter--trade-second'>
          <div className='search-container'>
            <CustomInput
              value={searchTerm}
              onChange={handleChangeSearch}
              iconRight={<SearchIcon />}
              placeholder='Filter by ID, Email, Name, Surname'
              label='Search'
              containerClass='input-container-dropdown'
            />
            {dropOpen && tradeHistoryDropdown && (
              <div ref={ref} className='dropdown-table'>
                <div className='table-block'>
                  <div className='table-wrapper'>
                    {!tradeHistoryDropdown.data.length ? (
                      <p className='table-dropdown-no-result'>No result found.</p>
                    ) : (
                      <div className='table table-dropdown-table'>
                        <div className='table-header'>
                          <div className='tr'>
                            {SEARCH_TABLE_HEADER.map((value) => (
                              <div className='td' key={value}>
                                {value}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='table-body'>
                          {tradeHistoryDropdown.data.map((item, idx) => (
                            <div className='tr' key={idx}>
                              <div className='td'>
                                <button
                                  onClick={() => handleSelectDropItem(item.user.id.toString())}
                                >
                                  {item.user.id}
                                </button>
                              </div>
                              <div className='td'>
                                <button onClick={() => handleSelectDropItem(item.user.email)}>
                                  {item.user.email}
                                </button>
                              </div>
                              <div className='td'>
                                <button
                                  onClick={() =>
                                    handleSelectDropItem(item.user.user_data.first_name)
                                  }
                                >
                                  {item.user.user_data.first_name}
                                </button>
                              </div>
                              <div className='td'>
                                <button
                                  onClick={() =>
                                    handleSelectDropItem(item.user.user_data.last_name)
                                  }
                                >
                                  {item.user.user_data.last_name}
                                </button>
                              </div>
                            </div>
                          ))}
                          <div className='tr observerTarget' ref={observerTarget}></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <CustomSelect
            obj={ETradeType}
            element={transactionType}
            setElement={setTransactionType}
            label='Transaction type'
            placeholder='Transaction type'
            withAll
          />
          <div className='table-filter-btns'>
            <button type='button' className='button' onClick={handleSearch}>
              Search
            </button>
            <button type='button' className='button button--type-text' onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>
      </div>
      <div className='block-tabs '>
        <button
          type='button'
          className={classNames('block-tabs__item', { active: !is_demo })}
          onClick={handleClickLive}
        >
          Live
        </button>
        <button
          type='button'
          className={classNames('block-tabs__item', { active: is_demo })}
          onClick={handleClickDemo}
        >
          Demo
        </button>
      </div>
    </>
  );
};

export default TradesFilter;
