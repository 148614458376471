import React, { FC } from 'react';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { addOneAndZeros } from 'utils/integers';

interface IConfirmDeleteUserProps {
  id: number | undefined;
  confirmDelete: () => void;
}

const ConfirmDeleteUser: FC<IPopupProps & IConfirmDeleteUserProps> = ({
  openModal,
  setOpenModal,
  id,
  confirmDelete,
}) => {
  return (
    <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} customClass='popup--medium'>
      <div className='popup-body'>
        <div className='popup-text popup-text--center'>
          <p>
            Are you sure you want to delete
            <br /> {`user ID ${addOneAndZeros(id)}?`}
          </p>
        </div>
        <div className='popup-submit  popup-submit--column'>
          <button className='button button--width-120 button--type-red' onClick={setOpenModal}>
            Cancel
          </button>
          <button className='button button--type-text button--text-green' onClick={confirmDelete}>
            Yes, I’m sure
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmDeleteUser;
