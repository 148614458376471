import { FC } from 'react';
import { IToastifyComponent } from './types';

const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps }) => {
  return (
    <div className='notification__info'>
      <div className='notification__title'>
        <p>
          {toastProps?.type === 'error' && 'Error'}
          {toastProps?.type === 'success' && 'Success'}
          {toastProps?.type === 'info' && 'Information'}
        </p>
      </div>
      <div className='notification__text'>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ToastifyComponent;
