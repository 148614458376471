import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../enum/types';
import { AuthState, IUserData, IUserResponse } from './auth-types';

import { login } from './auth-thunks';

const initialState: AuthState = {
  email: '',
  user: undefined,
  loadingState: LoadingStatus.LOADING,
  success: '',
  error: '',
  errors: {},
  isAuth: false,
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }: PayloadAction<IUserResponse>) => {
      state.user = payload.user_data;
      state.isAuth = true;
      state.token = payload.token;
      state.loadingState = LoadingStatus.LOADED;
    });
  },
});

export const { resetAuthState } = authSlice.actions;
export default authSlice.reducer;
