import { useAppSelector } from 'store/hooks';

export default function usePermissions() {
  const { user } = useAppSelector((state) => state.auth);
  const { permissions = [] } = user || {};
  const permissionNames = permissions.map((item) => item.name);

  const checkPermission = (value: string | string[]) => {
    if (Array.isArray(value)) {
      return value.reduce<boolean>((acc, curr) => {
        return acc || permissionNames.includes(curr);
      }, false);
    }
    return permissionNames.includes(value);
  };

  return { checkPermission };
}
