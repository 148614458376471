import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getWithdrawalTransactions } from 'store/redux-toolkit/transactions/transactions-thunks';
import { Navigate, useLocation, useParams, NavLink } from 'react-router-dom';
import PendingTable from './PendingTable';
import ApprovedTable from './ApprovedTable';
import CanceledTable from './CanceledTable';
import Pagination from 'ui/Pagination';
import { ITransactionTabProps } from './types';

enum ETableRout {
  pending = 'new',
  approved = 'approved',
  canceled = 'canceled',
}

const WithdrawalTab: FC<ITransactionTabProps> = ({ params, setParams }) => {
  const { table } = useParams();
  const pathname = useLocation();
  const dispatch = useAppDispatch();
  const { withdrawalTransactions } = useAppSelector((state) => state.transactions);
  const [status, setStatus] = useState('new');
  const [currentPage, setCurrentPage] = useState(1);

  const getTableContent = useCallback(() => {
    switch (table) {
      case 'pending':
        return <PendingTable />;
      case ETableRout.approved:
        return <ApprovedTable />;
      case ETableRout.canceled:
        return <CanceledTable />;
      default:
        return <Navigate to='pending' replace={true} />;
    }
  }, [table]);

  useEffect(() => {
    dispatch(
      getWithdrawalTransactions({
        current_page: currentPage,
        status: status || undefined,
        ...params,
      })
    );
  }, [currentPage, status, params]);

  useEffect(() => {
    setCurrentPage(1);
  }, [withdrawalTransactions?.last_page]);

  useEffect(() => {
    if (table) {
      setStatus(ETableRout[table as keyof typeof ETableRout]);
      setCurrentPage(1);
    }
  }, [table]);

  return (
    <>
      <div className='block'>
        <div className='block-nav'>
          <NavLink
            to={`${pathname.pathname.replace(String(table), '')}pending`}
            className='block-nav__item'
          >
            Pending Withdrawal
          </NavLink>
          <NavLink
            to={`${pathname.pathname.replace(String(table), '')}${ETableRout.approved}`}
            className='block-nav__item'
          >
            Approved requests
          </NavLink>
          <NavLink
            to={`${pathname.pathname.replace(String(table), '')}${ETableRout.canceled}`}
            className='block-nav__item'
          >
            Canceled request
          </NavLink>
        </div>
        <div className='table-block'>
          <div className='table-wrapper'>{getTableContent()}</div>
        </div>
      </div>
      {withdrawalTransactions && withdrawalTransactions.last_page > 1 && (
        <Pagination
          pageCount={withdrawalTransactions.last_page}
          forcePage={withdrawalTransactions.current_page}
          onPageChange={setCurrentPage}
        />
      )}
    </>
  );
};

export default WithdrawalTab;
