import React from 'react';
import { ERoutes } from 'enum/types';
import { NavLink, Outlet } from 'react-router-dom';

const DepositWithdrawal = () => {
  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Deposit/Withdrawal</p>
        </div>
      </div>
      <div className='block-tabs'>
        <NavLink to={ERoutes.withdrawal} className='block-tabs__item'>
          Withdrawal
        </NavLink>
        <NavLink to={ERoutes.deposit} className='block-tabs__item'>
          Deposit
        </NavLink>
        <NavLink to={ERoutes.manual_deposit} className='block-tabs__item'>
          Manual Deposit
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default DepositWithdrawal;
