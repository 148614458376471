import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ERoutes } from '../enum/types';
import AuthLayout from '../layouts/AuthLayout';
import PublicLayout from '../layouts/PublicLayout';
import Login from '../pages/Auth/Login';
import HomePage from '../pages/UsersPage';

const PublicRoutes = {
  path: ERoutes.home,
  element: <PublicLayout />,
  children: [
    {
      element: <Login />,
      path: ERoutes.login,
    },
  ],
};

export default PublicRoutes;
