import React, { FC, useState } from 'react';
import { moneyFormat } from 'utils/moneyFormat';
import { IRanking } from '../types';

const SymbolRanking: FC<IRanking> = ({ title, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className='dashboard-stat'>
      <p className='subtitle'>{title}</p>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--symbol-ranking'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>Symbol</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name'>
                    <p>Volume</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {paginatedData.map(({ id, asset_code, amount }) => (
                <div className='tr' key={id}>
                  <div className='td'>
                    <p className='td-hidden-name'>Symbol</p>
                    <p>{asset_code}</p>
                  </div>
                  <div className='td td--right'>
                    <p className='td-hidden-name'>Volume</p>
                    <p>{moneyFormat(amount)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='pagination-rankings'>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handleClick(index + 1)}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SymbolRanking;
