import { FC, useRef, useState } from 'react';
import { ArrowDropIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ICustomSelectProps } from './types';
import classNames from 'classnames';

const CustomSelect: FC<ICustomSelectProps> = ({
  obj,
  element,
  setElement,
  placeholder,
  label,
  errorMsg,
  withAll,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const list = Object.keys(obj);
  const handleChange = (el: string) => {
    setElement(el);
    setOpen(false);
  };

  return (
    <div className={`select-item ${className || ''}`}>
      {label && <p className='input-name'>{label}</p>}
      <div ref={ref} className={classNames('select', { active: open })}>
        <button
          type='button'
          className={classNames('select__current', { 'select__current--placeholder': !element })}
          onClick={handleClick}
        >
          {obj[element as keyof typeof obj] || placeholder}
          <span className='select__current-arrow'>
            <ArrowDropIcon />
          </span>
        </button>
        {errorMsg && <p className='input-notification--error'>{errorMsg}</p>}
        {list && (
          <div className='select__drop'>
            <div className='select__drop-scroll'>
              <div className='select__drop-item'>
                <ul>
                  {withAll && (
                    <li>
                      <button type='button' onClick={() => handleChange('')}>
                        All
                      </button>
                    </li>
                  )}
                  {list.map((el) => (
                    <li key={el}>
                      <button type='button' onClick={() => handleChange(el)}>
                        {obj[el as keyof typeof obj]}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
