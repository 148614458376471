const getLengthAfterDot = (value: string) => {
  return value.slice(value.indexOf('.') + 1).length;
};

/**
 * Precise addition of decimals
 * @param summand1 {number} - Decimal number
 * @param summand2 {number} - Decimal number
 * @returns {number}
 */
export const add = (summand1: number, summand2: number) => {
  const lengthAfterDot = Math.max(
    getLengthAfterDot(String(summand1)),
    getLengthAfterDot(String(summand2))
  );
  return Number((summand1 + summand2).toFixed(lengthAfterDot));
};

/**
 * Precise subtraction of decimals
 * @param minuend {number} - Decimal number
 * @param subtrahend {number} - Decimal number
 * @returns {number}
 */
export const subtract = (minuend: number, subtrahend: number) => {
  const lengthAfterDot = Math.max(
    getLengthAfterDot(String(minuend)),
    getLengthAfterDot(String(subtrahend))
  );
  return Number((minuend - subtrahend).toFixed(lengthAfterDot));
};

export function trimLastZeros(num: string): string {
  const dotIdx = num.indexOf('.');
  if (dotIdx === -1) {
    return num;
  }
  const intPart = num.slice(0, dotIdx);
  const decimalPart = num.slice(dotIdx + 1).replace(/0+$/, '');
  if (decimalPart.length === 0) {
    return intPart;
  }
  return `${intPart}.${decimalPart}`;
}
