import classNames from 'classnames';
import { IEditValues } from 'components/Account/EditUser';
import { FieldProps, useFormikContext } from 'formik';
import { E164Number } from 'libphonenumber-js';
import React, { FC, useState } from 'react';
import Input from 'react-phone-number-input/input';

interface CustomInputProps {
  label?: string;
}

const InputPhone: FC<FieldProps & CustomInputProps> = ({
  field,
  form: { touched, errors },
  label,
  ...props
}) => {
  const { setFieldValue } = useFormikContext<IEditValues>();

  const handleChange = (val: E164Number | undefined) => {
    setFieldValue('phone', val);
  };
  const [value, setValue] = useState<E164Number | undefined>();
  return (
    <div
      className={classNames('input', {
        'input--error': touched[field.name] && errors[field.name],
      })}
    >
      <p className='input__name'>{label}</p>
      <div className='input-wrapper'>
        <Input {...props} {...field} onChange={handleChange} className='input-item' />
        {touched[field.name] && errors[field.name] && (
          <p className='input-notification--error'>{String(errors[field.name])}</p>
        )}
      </div>
    </div>
  );
};

export default InputPhone;
