import {
  ITransactionsByUserRequest,
  IApproveWithdrawRequest,
  ITransactionsRequest,
  IApproveWithdrawParams,
  ICancelWithdrawRequest,
  IAddManualDeposit,
} from './transactions-type';
import { endpoint } from 'services/endpoint';
import requestService from 'services/interceptor/request-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import {
  approveWithdraw,
  clearCancelPopUp,
  clearWithdrawPopUp,
  setApiFilter,
} from './transactions-slice';
import { RootState } from 'store/store';

export const getDepositTransactionsByUser = createAsyncThunk(
  'transactions/deposit-by-user',
  async (params: ITransactionsByUserRequest) => {
    try {
      const response = await requestService.get(
        endpoint.transactions.GET_DEPOSIT_TRANSACTIONS_BY_USER,
        { ...params }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getWithdrawTransactionsByUser = createAsyncThunk(
  'transactions/withdraw-by-user',
  async (params: ITransactionsByUserRequest) => {
    try {
      const response = await requestService.get(
        endpoint.transactions.GET_WITHDRAWAL_TRANSACTIONS_BY_USER,
        { ...params }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getDepositsTransactions = createAsyncThunk(
  'transactions/deposits',
  async (params: ITransactionsRequest) => {
    try {
      const response = await requestService.get(endpoint.transactions.GET_DEPOSIT_TRANSACTIONS, {
        ...params,
      });
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getWithdrawalTransactions = createAsyncThunk(
  'transactions/withdrawal',
  async (params: ITransactionsRequest, { dispatch }) => {
    try {
      const response = await requestService.get(endpoint.transactions.GET_WITHDRAWAL_TRANSACTIONS, {
        per_page: 8,
        ...params,
      });
      dispatch(setApiFilter(params));
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getTransferTransactions = createAsyncThunk(
  'transactions/transfer',
  async (params: ITransactionsRequest) => {
    try {
      const response = await requestService.get(endpoint.transactions.GET_TRANSFER_TRANSACTIONS, {
        per_page: 8,
        ...params,
      });
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const confirmApproveWithdrawal = createAsyncThunk(
  'transactions/approve-withdrawal',
  async (params: IApproveWithdrawRequest, { dispatch, getState }) => {
    try {
      await requestService.post(endpoint.transactions.APPROVE_WITHDRAWAL, params);
      const { transactions } = getState() as RootState;
      dispatch(getWithdrawalTransactions({ ...transactions.apiFilter }));
      dispatch(clearWithdrawPopUp());
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const confirmCancelWithdrawal = createAsyncThunk(
  'transactions/cancel-withdrawal',
  async (params: ICancelWithdrawRequest, { dispatch, getState }) => {
    try {
      await requestService.post(endpoint.transactions.CANCEL_WITHDRAWAL, params);
      const { transactions } = getState() as RootState;
      dispatch(getWithdrawalTransactions({ ...transactions.apiFilter }));
      dispatch(clearCancelPopUp());
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const addManualDeposit = createAsyncThunk(
  'transactions/add-manual-deposit',
  async ({ apiParams, onFinally, onSuccess }: IAddManualDeposit, { rejectWithValue }) => {
    try {
      await requestService.post(endpoint.transactions.ADD_MANUAL_DEPOSIT, apiParams);
      onSuccess();
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
      return rejectWithValue(error);
    } finally {
      onFinally();
    }
  }
);
