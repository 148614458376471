import { CloseIcon } from 'assets/svg-icon';
import { FC, ReactNode } from 'react';
import { Popup } from 'reactjs-popup';

export interface IPopupProps {
  openModal: boolean;
  setOpenModal: () => void;
  children?: ReactNode;
  customClass?: string;
  typePopup?: 'info' | 'error' | 'success';
  showOnDocumentClick?: boolean;
}

const ModalWrapper: FC<IPopupProps> = ({
  openModal,
  setOpenModal,
  children,
  customClass,
  showOnDocumentClick,
}) => {
  return (
    <Popup
      open={openModal}
      closeOnDocumentClick={!showOnDocumentClick}
      onClose={setOpenModal}
      closeOnEscape
      lockScroll
    >
      <div className='popup-window__inside'>
        <div className={`popup ${customClass}`}>
          <button type='button' className='popup__close' onClick={setOpenModal}>
            <CloseIcon />
          </button>
          {children}
        </div>
      </div>
    </Popup>
  );
};

export default ModalWrapper;
