import { FC } from 'react';
import { moneyFormat } from 'utils/moneyFormat';
import SummaryItem from './SummaryItem';
import { ISummary, ISummeryOptionItem } from './types';

const options: ISummeryOptionItem[] = [
  {
    type: 'total_clients_quantity',
    title: 'Total Clients',
  },
  {
    type: 'total_balance_amount',
    title: 'Total users balance',
    showCurrency: true,
  },
  {
    type: 'total_deposit_amount',
    title: 'Total deposit',
    showCurrency: true,
  },
  {
    type: 'total_deposited_clients_quantity',
    title: 'Deposited clients',
  },
  {
    type: 'total_withdrawal_clients_quantity',
    title: 'Withdrawal clients',
    showCurrency: true,
    linkedType: 'total_withdrawal_amount',
  },
  {
    type: 'total_withdrawal_amount',
    title: 'Withdrawal amount',
    showCurrency: true,
    notSeparate: true,
  },
  {
    type: 'total_cash_flow_amount',
    title: 'Cash flow',
    showCurrency: true,
  },
  {
    type: 'total_market_orders_quantity',
    title: 'Total Market Orders',
  },
  {
    type: 'total_limit_orders_quantity',
    title: 'Total Limit Orders',
  },
  {
    type: 'total_open_positions_quantity',
    title: 'Total open positions',
  },
  {
    type: 'total_swap_amount',
    title: 'Total Swap amount',
    showCurrency: true,
  },
  {
    type: 'total_commission',
    title: 'Total commission',
    showCurrency: true,
  },
];

const Summary: FC<ISummary> = ({ analytics }) => {
  return (
    <div className='summary-total'>
      <p className='subtitle'>Summary Total:</p>
      <div className='summary-total-list summary-total-list--mb-type2 summary-total-list--with-bg summary-total-list--type2'>
        {options.map((item, idx, arr) => {
          const { type, showCurrency, linkedType, notSeparate } = item;
          let { title } = item;
          const analyticsItem = analytics.find((a) => a.type === type);
          if (!analyticsItem) {
            return null;
          }
          let amount: number | string = analytics.find((a) => a.type === type)?.amount || 0;
          amount = moneyFormat(amount);
          if (linkedType) {
            const amountTwo = analytics.find((a) => a.type === linkedType)?.amount || 0;
            amount = `${amount} / ${moneyFormat(amountTwo)}`;
            const titleTwo = arr.find((_item) => _item.type === linkedType)?.title;
            title = `${title} / ${titleTwo}`;
          }

          return (
            !notSeparate && (
              <SummaryItem key={type} amount={amount} title={title} showCurrency={showCurrency} />
            )
          );
        })}
      </div>
    </div>
  );
};

export default Summary;
