import Account from 'components/Account';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { APP_NAME } from 'services/constants/env';

const AccountPage = () => {
  const appTitle = APP_NAME || '';
  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Account`}</title>
      </Helmet>
      <Account />
    </>
  );
};

export default AccountPage;
