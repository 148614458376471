import ModalWrapper from '../../../../ui/ModalWrapper';
import { forgetPasswordSchema } from '../../../../form-validations/shemValidations';
import { Field, Form, Formik } from 'formik';
import Input from '../../../../ui/Formik/Input';
import { resetPassword } from '../../../../store/redux-toolkit/users/users-thunks';
import { notificationContainer } from '../../../../ui/notificationContainer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface IProps {
  isOpened: boolean;
  closeModal: () => void;
}

function ForgotModal({ isOpened, closeModal }: IProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);

  const sendEmail = () => {
    if (!user) return;
    dispatch(
      resetPassword({
        apiParams: {
          email: user.email,
        },
        onSuccess: () => {
          notificationContainer('Email sent successfully.', 'success');
          closeModal();
        },
      })
    );
  };

  return (
    <ModalWrapper openModal={isOpened} setOpenModal={closeModal} customClass='popup--width-500'>
      <div className='popup-header'>
        <div className='popup-header__title-block'>
          <p className='popup-header__title'>Reset Password</p>
        </div>
      </div>
      {user && user.email && (
        <div className='popup-body'>
          <div>
            <p className='button--full-width popup-text--center'>
              Do you want to reset password for
            </p>
            <i className='popup-text--center button--full-width forgot-modal--email'>
              {user.email}
            </i>
          </div>

          <div className='popup-submit popup-submit--mob-column'>
            <button onClick={closeModal} className='button button--full-width button--type2'>
              Cancel
            </button>
            <button onClick={sendEmail} className='button button--full-width'>
              Yes
            </button>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
}

export default ForgotModal;
