import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  editAdmin,
  getAdminById,
  getAllPermissions,
} from 'store/redux-toolkit/admins/admins-thunks';
import { notificationContainer } from 'ui/notificationContainer';
import { TFormOnSubmit } from '../types';
import FormAdmin from '../FormAdmin';
import EditAdminPermissions from './EditAdminPermissions';

const EditAdmin: FC = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const { adminItem } = useAppSelector((state) => state.admins);

  const handleSubmit: TFormOnSubmit = (values) => {
    dispatch(
      editAdmin({
        id: Number(userId),
        params: values,
        onSuccess: () => {
          notificationContainer('Successfully edited', 'success');
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAdminById({ admin_id: Number(userId) }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllPermissions());
  }, [dispatch]);

  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Edit administrator</p>
        </div>
      </div>
      <FormAdmin isEdit values={adminItem} onSubmit={handleSubmit} />
      <EditAdminPermissions />
    </div>
  );
};

export default EditAdmin;
