import React, { FC } from 'react';
import { PaginationLeftIcon, PaginationRightIcon } from 'assets/svg-icon';
import ReactPaginate from 'react-paginate';

interface IPaginationProps {
  pageCount: number;
  forcePage: number;
  onPageChange: (selected: number) => void;
  customClass?: string;
}

const Pagination: FC<IPaginationProps> = ({
  pageCount,
  forcePage,
  onPageChange,
  customClass = '',
}) => {
  return (
    <div className='table-pagination'>
      <div className={`pagination-block ${customClass}`}>
        <ReactPaginate
          pageCount={pageCount}
          forcePage={forcePage - 1}
          onPageChange={({ selected }) => onPageChange(selected + 1)}
          className='pagination'
          activeClassName='active'
          activeLinkClassName='active'
          previousLinkClassName='arrow'
          nextLinkClassName='arrow'
          previousLabel={<PaginationLeftIcon />}
          nextLabel={<PaginationRightIcon />}
        />
      </div>
    </div>
  );
};

export default Pagination;
