import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetAuthState } from '../../store/redux-toolkit/auth/auth-slice';
import Logo from 'assets/images/logo.png';

const Header = () => {
  const { isAuth, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleLogOut = () => {
    dispatch(resetAuthState());
  };

  return (
    <header className='header'>
      {isAuth && (
        <button className='mob-nav-btn'>
          <svg className='fill' viewBox='0 0 448 512'>
            <path
              fill='currentColor'
              d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'
            ></path>
          </svg>
        </button>
      )}
      <div className='d-flex header__logo'>
        <img src={Logo} alt='logo' />
      </div>
      {isAuth && (
        <div className='admin'>
          <p className='admin__name'>{user?.name || user?.email}</p>
          <button className='admin__logout' onClick={handleLogOut}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.2 8.00005L11.6 11.9M15.2 8.00005L11.6 4.40005M15.2 8.00005L3.79999 8.00005M8.59999 15.2L0.799988 15.2L0.799988 0.800049L8.59999 0.800049'
                stroke='white'
              ></path>
            </svg>
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
