import { ETransactionStatus, EWithdrawStatus } from 'enum/types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getWithdrawTransactionsByUser } from 'store/redux-toolkit/transactions/transactions-thunks';
import NoDataBlock from 'ui/NoDataBlock';
import Pagination from 'ui/Pagination';
import TableSelect from 'ui/TableSelect';
import WithdrawRow from './WithdrawRow';
import SelectPaginationBlock from '../../../SelectPaginationCount';
import { TPaginationCount } from '../../../SelectPaginationCount/types';

const WithdrawTable = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { withdrawalTransactions } = useAppSelector((state) => state.transactions);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('');
  const [perPage, setPerPage] = useState<TPaginationCount>(20);

  useEffect(() => {
    if (userId) {
      dispatch(
        getWithdrawTransactionsByUser({
          user_id: userId,
          current_page: currentPage,
          per_page: perPage,
          status: status || undefined,
        })
      );
    }
  }, [userId, currentPage, status]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    if (userId) {
      dispatch(
        getWithdrawTransactionsByUser({
          user_id: userId,
          current_page: currentPage,
          per_page: perPage,
          status: status || undefined,
        })
      );
    }
  };

  return (
    <>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--transactions-history-withdraw'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Data</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Asset</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Amount</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Fee</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Destination</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Tx Hash</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name'>
                    <TableSelect
                      element={status}
                      obj={EWithdrawStatus}
                      setElement={setStatus}
                      placeholder='Status'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {withdrawalTransactions?.data.length ? (
                withdrawalTransactions.data.map((item) => <WithdrawRow key={item.id} item={item} />)
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {withdrawalTransactions?.data?.length && (
        <div className='pagination-block'>
          <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
          {withdrawalTransactions.last_page > 1 && (
            <Pagination
              pageCount={withdrawalTransactions.last_page}
              forcePage={withdrawalTransactions.current_page}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default WithdrawTable;
