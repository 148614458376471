import { FC, useState } from 'react';
import { CalendarIcon, ClockIcon, CloseIcon } from 'assets/svg-icon';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addZeros } from 'utils/integers';
import moment from 'moment';
import SelectCalendar from 'ui/SelectCalendar';
import { updateOrderExpiration } from 'store/redux-toolkit/trades/trades-thunks';

const UpdateExpiration: FC<IPopupProps> = ({ openModal, setOpenModal }) => {
  const dispatch = useAppDispatch();
  const { actionPopUp, is_demo } = useAppSelector((state) => state.trade);
  const [startDate, setStartDate] = useState<Date>(
    actionPopUp?.update_tp_sl?.order?.date_expiration
      ? new Date(actionPopUp?.update_tp_sl?.order.date_expiration)
      : new Date()
  );
  const [openCalendar, setOpenCalendar] = useState(false);

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  };

  const sendUpdateDate = () => {
    if (actionPopUp?.update_expiration?.order) {
      dispatch(
        updateOrderExpiration({
          is_demo,
          main_order_id: actionPopUp.update_expiration.order.id,
          date_expiration: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        })
      );
    }
  };

  return (
    <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} customClass='popup--width-500'>
      <div className='popup-header'>
        <p className='popup-text popup-text--center'>{`Update order #${addZeros(
          actionPopUp?.update_expiration?.order?.id
        )}`}</p>
      </div>
      <p className='popup-header__title popup-header__title--type2'>Date expiration</p>
      <div className='popup-body'>
        <div className='input-line td-flex'>
          <div className='input input--size3 input--mb-0'>
            <div className='input-wrapper'>
              <input
                className='input-item'
                type='text'
                placeholder='date'
                readOnly
                onClick={handleOpenCalendar}
                value={moment(startDate).format('DD / MM / YYYY')}
              />

              {openCalendar && (
                <SelectCalendar
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setCloseCalendar={setOpenCalendar}
                  inline
                />
              )}
              <div className='input-icon input-icon--right'>
                <CalendarIcon />
              </div>
            </div>
          </div>
          <div className='input input--size3 input--mb-0'>
            <div className='input-wrapper'>
              <input
                className='input-item'
                type='text'
                placeholder='time'
                readOnly
                onClick={handleOpenCalendar}
                value={moment(startDate).format('HH : mm : ss')}
              />
              <div className='input-icon input-icon--right'>
                <ClockIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='popup-footer'>
        <div className='popup-submit'>
          <button
            type='button'
            className='button button--type2 button--full-width'
            onClick={setOpenModal}
          >
            Cancel
          </button>
          <button type='button' className='button button--full-width' onClick={sendUpdateDate}>
            Add
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UpdateExpiration;
