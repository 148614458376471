import { useAppDispatch, useAppSelector } from 'store/hooks';
import PendingTableRow from './PendingTableRow';
import NoDataBlock from 'ui/NoDataBlock';
import {
  clearCancelPopUp,
  clearWithdrawPopUp,
} from 'store/redux-toolkit/transactions/transactions-slice';
import ConfirmApproveWithdraw from 'components/modals/ConfirmApproveWithdraw';
import ConfirmCanceledWithdraw from 'components/modals/ConfirmCanceledWithdraw';
import { useState } from 'react';
import AddCommentToCancel from 'components/modals/AddCommentToCancel';

const PendingTable = () => {
  const dispatch = useAppDispatch();
  const { withdrawalTransactions, approvePopUp, cancelPopUp } = useAppSelector(
    (state) => state.transactions
  );
  const [openReasonModal, setOpenReasonModal] = useState(false);

  const closeApproveModal = () => {
    dispatch(clearWithdrawPopUp());
  };

  const closeCancelModal = () => {
    dispatch(clearCancelPopUp());
  };

  const closeCommentModal = () => {
    setOpenReasonModal(false);
    dispatch(clearCancelPopUp());
  };

  const handleOpenCommentModal = () => {
    setOpenReasonModal(true);
  };

  return (
    <div className='table table--transactions-withdraw-pending'>
      <div className='table-header'>
        <div className='tr'>
          <div className='td'>
            <div className='td-name'>
              <p>ID</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>User ID</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Email</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Full name</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Created date</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Asset</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Network</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Amount</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Destination</p>
            </div>
          </div>
          <div className='td td--right'>
            <div className='td-name'>Status</div>
          </div>
        </div>
      </div>
      <div className='table-body'>
        {withdrawalTransactions?.data.length ? (
          withdrawalTransactions.data.map((item) => <PendingTableRow key={item.id} item={item} />)
        ) : (
          <NoDataBlock />
        )}
      </div>

      <ConfirmApproveWithdraw openModal={!!approvePopUp} setOpenModal={closeApproveModal} />
      <ConfirmCanceledWithdraw
        openModal={!!cancelPopUp}
        setOpenModal={closeCancelModal}
        handleConfirm={handleOpenCommentModal}
      />
      <AddCommentToCancel
        openModal={!!cancelPopUp && openReasonModal}
        setOpenModal={closeCommentModal}
      />
    </div>
  );
};

export default PendingTable;
