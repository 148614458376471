import EditUser from 'components/Account/EditUser';
import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

const EditUserPage = () => {
  const appTitle = APP_NAME || '';
  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Edit User`}</title>
      </Helmet>
      <EditUser />
    </>
  );
};

export default EditUserPage;
