import React from 'react';
import { useAppSelector } from 'store/hooks';
import ApprovedTableRow from './ApprovedTableRow';
import NoDataBlock from 'ui/NoDataBlock';

const ApprovedTable = () => {
  const { withdrawalTransactions } = useAppSelector((state) => state.transactions);
  return (
    <div className='table table--transactions-withdraw-requests'>
      <div className='table-header'>
        <div className='tr'>
          <div className='td'>
            <div className='td-name'>
              <p>ID</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>User ID</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Admin ID</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Approvment date</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Asset</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Network</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Network fee</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Amount</p>
            </div>
          </div>
          <div className='td'>
            <div className='td-name'>
              <p>Destination</p>
            </div>
          </div>
          <div className='td td--right'>
            <div className='td-name'>Tx Hash</div>
          </div>
        </div>
      </div>
      <div className='table-body'>
        {withdrawalTransactions?.data.length ? (
          withdrawalTransactions.data.map((item) => <ApprovedTableRow key={item.id} item={item} />)
        ) : (
          <NoDataBlock />
        )}
      </div>
    </div>
  );
};

export default ApprovedTable;
