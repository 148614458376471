import { FC, useMemo, useEffect } from 'react';
import { notificationContainer } from 'ui/notificationContainer';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import debounce from 'lodash.debounce';
import { addDepositSchema } from 'form-validations/shemValidations';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUser, createDeposit } from 'store/redux-toolkit/users/users-thunks';
import { IGetUserResponse } from 'store/redux-toolkit/users/users-types';
import AddDepositFormInner from './AddDepositFormInner';
import { IAddDepositPopup, IValues } from './types';
import { replaceLeadingOneWithZero } from '../../../utils/integers';

const initialValues: IValues = {
  userId: '',
  amount: '',
  comment: '',
  toProfile: false,
  paymentSystem: '',
};

const AddDepositPopup: FC<IAddDepositPopup> = ({ onClose, onRefetch, isTrade, userId }) => {
  const email = useAppSelector((state) => state.users.user && state.users.user.email);

  const dispatch = useAppDispatch();

  const checkUserDeb = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(
          getUser({ apiParams: { user_id: +value }, onSuccess: (resp: IGetUserResponse) => {} })
        );
      }, 500),
    []
  );

  return (
    <div className='popup-window '>
      <div className='popup-window__inside'>
        <div className='popup popup--width-500'>
          <button type='button' className='popup__close' onClick={onClose}>
            <svg
              width='14'
              height='14'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13 1L1 13'
                stroke='#011E26'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
              <path
                d='M1 1L13 13'
                stroke='#011E26'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
            </svg>
          </button>
          <div className='popup-header'>
            <div className='popup-header__title-block'>
              <p className='popup-header__title popup-header__title--ta-l'>
                Create deposit
                {isTrade && ` for ${email}`}
              </p>
            </div>
          </div>
          <div className='popup-body'>
            <Formik
              initialValues={{ ...initialValues, ...(userId && { userId }) }}
              validationSchema={addDepositSchema}
              onSubmit={(values: IValues) => {
                const { userId, amount, comment, paymentSystem, toProfile } = values;
                dispatch(
                  createDeposit({
                    apiParams: {
                      user_id: Number(replaceLeadingOneWithZero(userId)),
                      amount: Number(amount),
                      comment: comment || null,
                      payment_system: paymentSystem || null,
                      to_profile: toProfile,
                    },
                    onSuccess: () => {
                      notificationContainer('Deposit successfully created', 'success');
                      onClose();
                      onRefetch();
                    },
                  })
                );
              }}
            >
              {() => {
                return (
                  <Form>
                    <AddDepositFormInner onClose={onClose} isTrade={isTrade} />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDepositPopup;
