import { notificationContainer } from 'ui/notificationContainer';
import requestService from 'services/interceptor/request-service';
import {
  IUsersRequest,
  IBlockedUserRequest,
  IUserSettingsRequest,
  IEditUserRequest,
  IDeleteUserRequest,
  ITradeAnalyticRequest,
  IGetReferralTransactionReq,
  IGetUserRequest,
  ICreateDepositPayload,
  IGetUserPayload,
  IGetUserResponse,
  IUpdateKycRequest,
  IVerifyKycRequest,
  IResetPasswordPayload,
  INewPasswordPayload,
  IAllUsersRequest,
} from './users-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { endpoint } from 'services/endpoint';
import { showError } from 'utils/showError';
import { addOneAndZeros } from 'utils/integers';

export const getUsers = createAsyncThunk('users/get-users', async (params: IUsersRequest) => {
  try {
    const response = await requestService.get(endpoint.users.GET_USERS, { ...params });
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const getAllUsers = createAsyncThunk(
  'users/get-all-users',
  async (params: IAllUsersRequest) => {
    try {
      const response = await requestService.get(endpoint.users.GET_ALL_USERS);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const setBlockedUser = createAsyncThunk(
  'users/set-blocked',
  async (params: IBlockedUserRequest) => {
    try {
      const response = await requestService.put(endpoint.users.BLOCKED_USER(params.id), {
        blocked: params.blocked,
      });
      notificationContainer(
        `User ID ${addOneAndZeros(params.id)} ${params.blocked === '1' ? 'Blocked' : 'Unblocked'}`,
        'info'
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getUserSettings = createAsyncThunk(
  'users/user-settings',
  async (params: IUserSettingsRequest) => {
    try {
      const response = await requestService.get(endpoint.users.GET_USER_SETTINGS, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const editUserData = createAsyncThunk(
  'users/edit-user-data',
  async (params: IEditUserRequest) => {
    try {
      const response = await requestService.put(endpoint.users.EDIT_USER_DATA, params);
      notificationContainer(
        `User ID ${addOneAndZeros(params.user_id, 6)} has been changed`,
        'success'
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getCountries = createAsyncThunk('users/get-countries', async () => {
  try {
    const response = await requestService.get(endpoint.users.GET_COUNTRIES);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const deleteUser = createAsyncThunk(
  'users/delete-user',
  async (params: IDeleteUserRequest) => {
    try {
      await requestService.put(endpoint.users.DELETE_USER(params.id));
      params.onSuccess();
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getTradeAnalyticByUser = createAsyncThunk(
  'users/get-analytic',
  async (params: ITradeAnalyticRequest) => {
    try {
      const response = await requestService.get(endpoint.users.GET_TRADE_ANALYTIC_BY_USER, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getReferralTransactionsByUser = createAsyncThunk(
  'users/get-referral-transactions-by-user',
  async (params: IGetReferralTransactionReq) => {
    try {
      const response = await requestService.get(
        endpoint.users.GET_REFERRAL_TRANSACTIONS_BY_USER,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getUser = createAsyncThunk('users/get-user', async (payload: IGetUserPayload) => {
  const { apiParams, onSuccess } = payload;
  try {
    const response: { data: IGetUserResponse } = await requestService.get(
      endpoint.users.GET_USER,
      apiParams
    );
    onSuccess?.(response.data);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const createDeposit = createAsyncThunk(
  'users/create-deposit',
  async (payload: ICreateDepositPayload) => {
    const { apiParams, onSuccess } = payload;
    try {
      const response = await requestService.post(endpoint.users.CREATE_DEPOSIT, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  'users/reset-password',
  async (payload: IResetPasswordPayload) => {
    const { apiParams, onSuccess } = payload;
    try {
      const response = await requestService.put(endpoint.users.RESET_PASSWORD, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const newPassword = createAsyncThunk(
  'users/new-password',
  async (payload: INewPasswordPayload) => {
    const { apiParams, onSuccess } = payload;
    try {
      const response = await requestService.put(endpoint.users.NEW_PASSWORD, apiParams);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const updateKYC = createAsyncThunk('users/update-kyc', async (params: IUpdateKycRequest) => {
  const { id, kyc } = params;
  try {
    const response = await requestService.put(endpoint.users.UPDATE_KYC(id), { kyc });
    notificationContainer(`KYC was successfully ${kyc === 1 ? 'on' : 'off'}`, 'success');
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const verifyKYC = createAsyncThunk('users/verify-kyc', async (params: IVerifyKycRequest) => {
  const { id, status } = params;
  try {
    const response = await requestService.put(endpoint.users.VERIFY_KYC(id), { status });
    notificationContainer(`Successfully ${status}`, 'success');
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});
