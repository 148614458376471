import React, { FC, useEffect, useRef, useState } from 'react';

import { ArrowDropIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { FieldProps, useFormikContext } from 'formik';
import useOutsideClick from 'hooks/useOutsideClick';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IEditValues } from 'components/Account/EditUser';
import { getCountries } from 'store/redux-toolkit/users/users-thunks';

interface ICountrySelectProps {
  initialCountry: string;
}

const CountrySelect: FC<FieldProps> = ({ field, form: { touched, errors } }) => {
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector((state) => state.users);
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));

  const {
    values: { country },

    setFieldValue,
  } = useFormikContext<IEditValues>();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleChange = (e: React.MouseEvent<HTMLElement>) => {
    const name = e.currentTarget.textContent || '';
    setFieldValue('country', name);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  return (
    <div className='select-block'>
      <p className='input-name'>Country:</p>
      <div
        className={classNames('select', {
          active: open,
          'input--error': touched[field.name] && errors[field.name],
        })}
      >
        <button type='button' className='select__current' onClick={handleClick}>
          {country || 'Select country'}
          <span className='select__current-arrow'>
            <ArrowDropIcon />
          </span>
        </button>
        <div className='select__drop'>
          <div className='select__drop-scroll'>
            <div className='select__drop-item'>
              <ul>
                {countries.map((el) => (
                  <li key={el.id}>
                    <button type='button' onClick={handleChange}>
                      {el.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification--error'>{String(errors[field.name])}</p>
      )}
    </div>
  );
};

export default CountrySelect;
