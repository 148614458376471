import { ERoutes } from 'enum/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IWithdrawalTransactionItem } from 'store/redux-toolkit/transactions/transactions-type';
import { addZeros, addOneAndZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';

interface IRowProps {
  item: IWithdrawalTransactionItem;
}

const CanceledTableRow: FC<IRowProps> = ({ item }) => {
  const { id, user_id, updated_at, currency, currency_network, comment, amount, admin_id } = item;

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>User ID</p>
        <p>
          <Link to={`${ERoutes.users}/${user_id}/profile`} className='link'>
            {addOneAndZeros(user_id)}
          </Link>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Admin ID</p>
        <p>
          <span className='link'>{addZeros(admin_id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Cancel date</p>
        <p>{moment(updated_at).format('MM/DD/YY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Asset</p>
        <p>{currency.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Network</p>
        <p>{currency_network.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Network fee</p>
        <p>{moneyFormat(0, 0, 6)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Comment</p>
        <p>{comment}</p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Amount</p>
        <p>{moneyFormat(amount, 0, 6)}</p>
      </div>
    </div>
  );
};

export default CanceledTableRow;
