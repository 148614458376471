import { FC } from 'react';
import { IDocFile } from './types';

const DocFile: FC<IDocFile> = ({ name, size, src }) => {
  const handleOpen = () => {
    const newTab = window.open();

    newTab?.document.write(
      `<!DOCTYPE html><head><title>Document preview</title></head><body><img src="${src}"></body></html>`
    );

    newTab?.document.close();
  };

  return (
    <div className='doc-file'>
      <p className='doc-file__name'>{name}</p>
      <div className='doc-file__info'>
        {size && <p className='doc-file__info-size'>{size}</p>}
        <div className='doc-actions'>
          <button type='button' className='doc-actions__zoom' onClick={handleOpen}>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 6.97136C0 10.5395 2.93143 13.4427 6.5343 13.4427C7.95905 13.4427 9.26101 12.9886 10.3337 12.2263L14.3624 16.2243C14.5507 16.4108 14.7964 16.5 15.0584 16.5C15.6152 16.5 16 16.0864 16 15.5431C16 15.2836 15.9018 15.0484 15.7298 14.8781L11.7257 10.8882C12.5691 9.80156 13.0686 8.4473 13.0686 6.97136C13.0686 3.40319 10.1372 0.5 6.5343 0.5C2.93143 0.5 0 3.40319 0 6.97136ZM1.40021 6.97136C1.40021 4.16549 3.70114 1.88672 6.5343 1.88672C9.36743 1.88672 11.6684 4.16549 11.6684 6.97136C11.6684 9.77721 9.36743 12.056 6.5343 12.056C3.70114 12.056 1.40021 9.77721 1.40021 6.97136ZM3.83215 6.97136C3.83215 7.30385 4.09418 7.56333 4.42989 7.56333H5.93655V9.05551C5.93655 9.38802 6.19857 9.64752 6.5343 9.64752C6.87002 9.64752 7.14027 9.38802 7.14027 9.05551V7.56333H8.63872C8.97446 7.56333 9.23649 7.30385 9.23649 6.97136C9.23649 6.63887 8.97446 6.37126 8.63872 6.37126H7.14027V4.88722C7.14027 4.55474 6.87002 4.29524 6.5343 4.29524C6.19857 4.29524 5.93655 4.55474 5.93655 4.88722V6.37126H4.42989C4.09418 6.37126 3.83215 6.63887 3.83215 6.97136Z'
                fill='#777E90'
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocFile;
