import { FC } from 'react';
import { ArrowDropIcon } from 'assets/svg-icon';
import { useSelect } from 'hooks/useSelect';
import { TTimePeriod } from 'store/redux-toolkit/dashboard/dashboard-types';
import { ISelectPeriodBlock } from '../types';

const OPTIONS = [
  {
    value: '1_days',
    label: '1 day',
  },
  {
    value: '7_days',
    label: '7 days',
  },
  {
    value: '30_days',
    label: '30 days',
  },
  {
    value: '90_days',
    label: '90 days',
  },
  {
    value: '180_days',
    label: '180 days',
  },
  {
    value: '365_days',
    label: '365 days',
  },
  {
    value: 'all_time',
    label: 'All time',
  },
];

const SelectPeriodBlock: FC<ISelectPeriodBlock> = ({ value, onSelect }) => {
  const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

  const handleSelect = (selected: TTimePeriod) => {
    setOpen(false);
    onSelect(selected);
  };

  return (
    <div className='graph-filter'>
      <p className='block-title block-title--fs-18'>Graph filter</p>
      <div className='select-block select-block--width-170'>
        <div className={`select ${open ? 'active' : ''}`}>
          <button type='button' className='select__current ' ref={triggerRef} onClick={toggleOpen}>
            {value && OPTIONS.find((item) => item.value === value)?.label}
            <span className='select__current-arrow'>
              <ArrowDropIcon />
            </span>
          </button>
          <div className='select__drop' ref={dropRef}>
            <div className='select__drop-scroll'>
              <div className='select__drop-item'>
                <ul>
                  {OPTIONS.map(({ value, label }) => (
                    <li key={value}>
                      <button onClick={() => handleSelect(value as TTimePeriod)} type='button'>
                        {label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPeriodBlock;
