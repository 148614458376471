import { endpoint } from 'services/endpoint';
import requestService from 'services/interceptor/request-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import {
  IEditReferralCommissionRequest,
  IGetSwapComissionRequest,
  IEditSwapComissionRequest,
  IGetCurrencyNetworksRequest,
  IEditCurrencyNetworkRequest,
  IEditMarketRequest,
  IAddSwapComissionRequest,
} from './commission-types';

export const getReferralCommission = createAsyncThunk('commission/referral', async () => {
  try {
    const response = await requestService.get(endpoint.commissions.GET_REFERRAL_COMMISSION);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const editReferralCommission = createAsyncThunk(
  'commission/edit-referral',
  async (params: IEditReferralCommissionRequest) => {
    try {
      const response = await requestService.put(
        endpoint.commissions.EDIT_REFERRAL_COMMISSION,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getSwapComissions = createAsyncThunk(
  'commission/get-swap',
  async (payload: IGetSwapComissionRequest) => {
    try {
      const response = await requestService.get(endpoint.commissions.GET_ALL_ASSETS, payload);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getMarkets = createAsyncThunk('commission/get-markets', async () => {
  try {
    const response = await requestService.get(endpoint.commissions.GET_ALL_MARKETS);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const editSwapComission = createAsyncThunk(
  'commission/edit-swap',
  async (payload: IEditSwapComissionRequest) => {
    const { params, onSuccess } = payload;
    try {
      const response = await requestService.put(endpoint.commissions.EDIT_ASSET, params);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const addSwapComissionRequest = createAsyncThunk(
  'commission/add-swap',
  async (payload: IAddSwapComissionRequest, thunkAPI) => {
    const {
      params: { id: oldId, ...params },
      onSuccess,
    } = payload;

    try {
      const response = await requestService.post(endpoint.commissions.CREATE_ASSET, params);
      onSuccess?.();
      return { oldId, ...response.data };
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrencyNetworks = createAsyncThunk(
  'commission/get-currency-networks',
  async (payload: IGetCurrencyNetworksRequest) => {
    try {
      const response = await requestService.get(
        endpoint.commissions.GET_CURRENCY_NETWORKS,
        payload
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const editCurrencyNetworks = createAsyncThunk(
  'commission/edit-currency-network',
  async (payload: IEditCurrencyNetworkRequest) => {
    const { params, onSuccess } = payload;
    try {
      const response = await requestService.put(
        endpoint.commissions.EDIT_CURRENCY_NETWORKS,
        params
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getLeverages = createAsyncThunk('commission/get-leverages', async () => {
  try {
    const response = await requestService.get(endpoint.commissions.GET_LEVERAGES);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const editMarket = createAsyncThunk(
  'commission/edit-market',
  async (payload: IEditMarketRequest) => {
    const { params, onSuccess } = payload;
    try {
      const response = await requestService.put(endpoint.commissions.EDIT_MARKET, params);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
