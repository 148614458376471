import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTradeAnalyticByUser } from 'store/redux-toolkit/users/users-thunks';
import { moneyFormat } from 'utils/moneyFormat';
import { createPortal } from 'react-dom';
import AddDepositPopup from '../../Deposits/AddDepositPopup';

const TradeAccountTab = () => {
  const [depositOpen, setDepositOpen] = useState(false);

  const { userId } = useParams();
  const dispatch = useAppDispatch();

  const { tradeAnalyticByUser: analytic } = useAppSelector((state) => state.users);

  const fetchTradeInfo = useCallback(() => {
    if (userId) {
      dispatch(getTradeAnalyticByUser({ user_id: userId }));
    }
  }, [userId]);

  useEffect(() => {
    fetchTradeInfo();
  }, [fetchTradeInfo]);

  return (
    <div className='summary-total'>
      <div className='section--flex flex-sb align-items-center'>
        <p className='subtitle'>Summary Total:</p>
        <button type='button' className='button' onClick={() => setDepositOpen(true)}>
          Add deposit
          <span className='btn-icon'>
            <svg
              width='14'
              height='14'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 7.00001C0 7.41578 0.34788 7.75514 0.755154 7.75514H6.24486V13.2449C6.24486 13.6522 6.58426 14 7.00001 14C7.41578 14 7.76368 13.6522 7.76368 13.2449V7.75514H13.2449C13.6522 7.75514 14 7.41578 14 7.00001C14 6.58426 13.6522 6.23638 13.2449 6.23638H7.76368V0.755154C7.76368 0.34788 7.41578 0 7.00001 0C6.58426 0 6.24486 0.34788 6.24486 0.755154V6.23638H0.755154C0.34788 6.23638 0 6.58426 0 7.00001Z'
                fill='white'
              />
            </svg>
          </span>
        </button>
      </div>
      {depositOpen &&
        createPortal(
          <AddDepositPopup
            onClose={() => setDepositOpen(false)}
            onRefetch={() => {
              fetchTradeInfo();
            }}
            isTrade
            userId={userId}
          />,
          document.getElementsByTagName('main')[0]
        )}
      <div className='summary-total-list'>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.total_balance)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Final balance</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.available_balance)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Available Balance</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.available_margin)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Available Margin</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.unrealised_pnl)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Unrealized P&L</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.used_margin)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Used Margin</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.level_margin_percent)} <span>%</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Margin Level</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.frozen_balance)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Frozen balance</p>
          </div>
        </div>
      </div>
      <p className='subtitle '>Referral Total:</p>
      <div className='summary-total-list summary-total-list--type2'>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>{moneyFormat(analytic?.total_referral)}</p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Total referral</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.total_referral_balance_deposit)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Total referral balance</p>
          </div>
        </div>
        <div className='summary-total-list__item'>
          <div className='summary-total-list__item-counter'>
            <p>
              {moneyFormat(analytic?.total_referral_balance_transfer)} <span>USD</span>
            </p>
          </div>
          <div className='summary-total-list__item-title'>
            <p>Total referral balance transfered</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeAccountTab;
