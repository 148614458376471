import { SearchIcon } from 'assets/svg-icon';
import { ETradeType } from 'enum/types';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTradeHistory } from 'store/redux-toolkit/trades/trades-thunks';
import CustomInput from 'ui/CustomInput';
import NoDataBlock from 'ui/NoDataBlock';
import CustomSelect from 'ui/CustomSelect';
import Pagination from 'ui/Pagination';
import TradeHistoryRow from './TradeHistoryRow';
import { useParams } from 'react-router-dom';
import SelectPaginationBlock from '../../../SelectPaginationCount';
import { TPaginationCount } from '../../../SelectPaginationCount/types';
import RowParent from './TradeHistoryRow/RowParent';

const columnVisibilityKey = 'tradeHistoryTableColumnVisibility';

const TradeHistoryTable = () => {
  const dispatch = useAppDispatch();
  const { tradeHistory, is_demo } = useAppSelector((state) => state.trade);
  const [currentPage, setCurrentPage] = useState(0);
  const [positionId, setPositionId] = useState('');
  const [tradeType, setTradeType] = useState('');
  const { userId } = useParams();
  const [perPage, setPerPage] = useState<TPaginationCount>(20);
  const [groupedData, setGroupedData] = useState<{ [key: string]: any[] }>({});
  const [isTableItemsGroupView, setTableItemsGroupView] = useState(() => {
    const savedState = localStorage.getItem('isTableItemsGroupView');
    return savedState !== null ? JSON.parse(savedState) : false;
  });

  const initialColumnVisibilityString = localStorage.getItem(columnVisibilityKey);
  const initialColumnVisibility = initialColumnVisibilityString
    ? JSON.parse(initialColumnVisibilityString)
    : {
        symbol: true,
        position_id: true,
        type: true,
        volume: true,
        open_time: true,
        open_price: true,
        close_time: true,
        close_price: true,
        profit: true,
        net_p_l: true,
        swap: true,
        commission: true,
        price_markup: false,
        close_price_markup: false,
        opened_by: false,
        closed_by: false,
        status: true,
      };

  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);

  useEffect(() => {
    dispatch(
      getTradeHistory({
        is_demo: is_demo ? 1 : 0,
        current_page: currentPage,
        per_page: perPage,
        user_id: Number(userId),
      })
    );
  }, [currentPage, is_demo]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    dispatch(
      getTradeHistory({
        is_demo: is_demo ? 1 : 0,
        current_page: currentPage,
        per_page: _perPage,
        user_id: Number(userId),
      })
    );
  };

  const handleColumnVisibilityChange = (column: string) => {
    const updatedVisibility = { ...columnVisibility, [column]: !columnVisibility[column] };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem(columnVisibilityKey, JSON.stringify(updatedVisibility));
  };

  const columnCount = Object.values(columnVisibility).filter((visible) => visible).length;
  let trUserTradeHistoryClassName = 'tr';

  if (columnCount > 13 && columnCount <= 17) {
    trUserTradeHistoryClassName = `tr-${columnCount}`;
  }

  useEffect(() => {
    if (isTableItemsGroupView && tradeHistory?.data.length) {
      const grouped = tradeHistory.data.reduce((acc: any, item: any) => {
        const key = item.asset.code;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
      setGroupedData(grouped);
    } else {
      setGroupedData({});
    }
  }, [isTableItemsGroupView, tradeHistory]);

  useEffect(() => {
    localStorage.setItem('isTableItemsGroupView', JSON.stringify(isTableItemsGroupView));
  }, [isTableItemsGroupView]);

  const switchTableView = () => {
    setTableItemsGroupView((prevState: boolean) => !prevState);
  };

  return (
    <>
      <div className='table-filter table-filter--mt-type2 table-filter--mb-0 table-filter--user-trade-history'>
        <CustomInput
          value={positionId}
          onChange={setPositionId}
          placeholder='Filter by Position#'
          iconRight={<SearchIcon />}
        />
        <CustomSelect
          obj={ETradeType}
          element={tradeType}
          setElement={setTradeType}
          placeholder='Transaction type'
        />

        <div className='input'>
          <div className='input-wrapper'>
            <input
              className='input-item input-item--pr50'
              type='text'
              placeholder='Filter by Symbol'
            />
            <div className='input-icon input-icon--right'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M23.2 23.2L16.8 16.8M10.4 20C5.09805 20 0.799988 15.702 0.799988 10.4C0.799988 5.09812 5.09805 0.800049 10.4 0.800049C15.7019 0.800049 20 5.09812 20 10.4C20 15.702 15.7019 20 10.4 20Z'
                  stroke='#777E90'
                  strokeWidth='1.5'
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div>
          <button type='button' className='button'>
            Search
          </button>
          <button type='button' className='button button--type-text'>
            Clear
          </button>
          <button type='button' className='button switch-table-button' onClick={switchTableView}>
            {isTableItemsGroupView ? 'Switch to Normal View' : 'Switch to Group View'}
          </button>
        </div>
      </div>

      <div className='account-trade-column-visibility'>
        <div>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.symbol}
              onChange={() => handleColumnVisibilityChange('symbol')}
            />
            Symbol
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.position_id}
              onChange={() => handleColumnVisibilityChange('position_id')}
            />
            Position ID
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.type}
              onChange={() => handleColumnVisibilityChange('type')}
            />
            Type
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.volume}
              onChange={() => handleColumnVisibilityChange('volume')}
            />
            Volume
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.open_time}
              onChange={() => handleColumnVisibilityChange('open_time')}
            />
            Open time
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.open_price}
              onChange={() => handleColumnVisibilityChange('open_price')}
            />
            Open price
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.close_time}
              onChange={() => handleColumnVisibilityChange('close_time')}
            />
            Close time
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.close_price}
              onChange={() => handleColumnVisibilityChange('close_price')}
            />
            Close price
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.profit}
              onChange={() => handleColumnVisibilityChange('profit')}
            />
            Profit
          </label>
        </div>
        <div>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.net_p_l}
              onChange={() => handleColumnVisibilityChange('net_p_l')}
            />
            NET P/L
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.swap}
              onChange={() => handleColumnVisibilityChange('swap')}
            />
            Swap
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.commission}
              onChange={() => handleColumnVisibilityChange('commission')}
            />
            Commission
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.price_markup}
              onChange={() => handleColumnVisibilityChange('price_markup')}
            />
            Price markup
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.close_price_markup}
              onChange={() => handleColumnVisibilityChange('close_price_markup')}
            />
            Close price markup
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.opened_by}
              onChange={() => handleColumnVisibilityChange('opened_by')}
            />
            Opened by
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.closed_by}
              onChange={() => handleColumnVisibilityChange('closed_by')}
            />
            Closed by
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.status}
              onChange={() => handleColumnVisibilityChange('status')}
            />
            Status
          </label>
        </div>
      </div>

      <div className='table-block table-block--mt-16'>
        <div className='table-wrapper'>
          <div className='table table--user-trade-history'>
            <div className='table-header'>
              <div className={`tr ${trUserTradeHistoryClassName}`}>
                {columnVisibility.symbol && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Symbol</p>
                    </div>
                  </div>
                )}
                {columnVisibility.position_id && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Position ID</p>
                    </div>
                  </div>
                )}
                {columnVisibility.type && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Type</p>
                    </div>
                  </div>
                )}
                {columnVisibility.volume && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Volume</p>
                    </div>
                  </div>
                )}
                {columnVisibility.open_time && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Open time</p>
                    </div>
                  </div>
                )}
                {columnVisibility.open_price && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Open price</p>
                    </div>
                  </div>
                )}
                {columnVisibility.close_time && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Close time</p>
                    </div>
                  </div>
                )}
                {columnVisibility.close_price && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Close price</p>
                    </div>
                  </div>
                )}
                {columnVisibility.profit && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Profit</p>
                    </div>
                  </div>
                )}
                {columnVisibility.net_p_l && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>NET P/L</p>
                    </div>
                  </div>
                )}
                {columnVisibility.swap && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Swap</p>
                    </div>
                  </div>
                )}
                {columnVisibility.commission && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Commission</p>
                    </div>
                  </div>
                )}
                {columnVisibility.price_markup && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Price markup</p>
                    </div>
                  </div>
                )}
                {columnVisibility.close_price_markup && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Close price markup</p>
                    </div>
                  </div>
                )}
                {columnVisibility.opened_by && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Opened by</p>
                    </div>
                  </div>
                )}
                {columnVisibility.closed_by && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Closed by</p>
                    </div>
                  </div>
                )}
                {columnVisibility.status && (
                  <div className='td td--right'>
                    <div className='td-name'>Status</div>
                  </div>
                )}
              </div>
            </div>
            <div className='table-body'>
              {!isTableItemsGroupView && tradeHistory?.data.length ? (
                tradeHistory.data.map((item) => (
                  <TradeHistoryRow
                    key={item.id}
                    {...item}
                    columnVisibility={columnVisibility}
                    trUserTradeHistoryClassName={trUserTradeHistoryClassName}
                  />
                ))
              ) : isTableItemsGroupView && Object.keys(groupedData).length !== 0 ? (
                Object.keys(groupedData).map((item) => (
                  <RowParent
                    key={item}
                    items={groupedData[item]}
                    columnVisibility={columnVisibility}
                    trUserTradeHistoryClassName={trUserTradeHistoryClassName}
                  />
                ))
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {tradeHistory?.data?.length && (
        <div className='pagination-block'>
          <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
          {tradeHistory.last_page > 1 && (
            <Pagination
              pageCount={tradeHistory.last_page}
              forcePage={tradeHistory.current_page}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TradeHistoryTable;
