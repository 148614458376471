import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import Switch from '../../../../ui/Switch';
import { getSettingBySlug, toggleSetting } from 'store/redux-toolkit/settings/settings-thunks';

const OtherSettings = () => {
  const dispatch = useAppDispatch();
  const [isDemoAccountActive, setIsDemoAccountActive] = useState(false);
  const [isNettingActive, setIsNettingActive] = useState(false);
  const [isScalpingActive, setIsScalpingActive] = useState(false);
  const [showScalpingOptions, setShowScalpingOptions] = useState(false);
  const [scalpingOptionTimePeriod, setScalpingOptionTimePeriod] = useState('');

  const getDemoAccountSetting = async () => {
    try {
      const action = await dispatch(getSettingBySlug({ slug: 'demo-account' }));
      const responseData = action.payload[0];
      setIsDemoAccountActive(responseData.is_active);
    } catch (error) {
      console.error('Error toggling setting:', error);
    }
  };

  const getNettingSetting = async () => {
    try {
      const action = await dispatch(getSettingBySlug({ slug: 'netting' }));
      const responseData = action.payload[0];
      setIsNettingActive(responseData.is_active);
    } catch (error) {
      console.error('Error toggling setting:', error);
    }
  };

  const getScalpingSetting = async () => {
    try {
      const action = await dispatch(getSettingBySlug({ slug: 'scalping' }));
      const responseData = action.payload[0];
      setIsScalpingActive(responseData.is_active);
      setShowScalpingOptions(!responseData.is_active);
      setScalpingOptionTimePeriod(responseData.time_period);
    } catch (error) {
      console.error('Error toggling setting:', error);
    }
  };

  const getSettingsData = () => {
    getDemoAccountSetting().then(() => {});
    getNettingSetting().then(() => {});
    getScalpingSetting().then(() => {});
  };

  useEffect(() => {
    getSettingsData();
  }, []);

  const handleToggleDemoAccountActive = async () => {
    try {
      const action = await dispatch(
        toggleSetting({
          slug: 'demo-account',
          is_active: !isDemoAccountActive,
          name: 'Demo Account',
        })
      );
      const responseData = action.payload;
      setIsDemoAccountActive(!!responseData.is_active);
    } catch (error) {
      console.error('Error toggling setting:', error);
    }
  };

  const handleToggleNettingActive = async () => {
    try {
      const action = await dispatch(
        toggleSetting({
          slug: 'netting',
          is_active: !isNettingActive,
          name: 'Netting',
        })
      );
      const responseData = action.payload;
      setIsNettingActive(!!responseData.is_active);
    } catch (error) {
      console.error('Error toggling setting:', error);
    }
  };

  const handleToggleScalpingActive = async () => {
    try {
      const action = await dispatch(
        toggleSetting({
          slug: 'scalping',
          is_active: !isScalpingActive,
          name: 'Scalping',
        })
      );
      const responseData = action.payload;
      setIsScalpingActive(!!responseData.is_active);
      setShowScalpingOptions(!responseData.is_active);
    } catch (error) {
      console.error('Error toggling setting:', error);
    }
  };

  const handleScalpingTimeChange = (event: { target: { value: any } }) => {
    const selectedValue = event.target.value;
    setScalpingOptionTimePeriod(selectedValue);
    dispatch(
      toggleSetting({
        slug: 'scalping',
        is_active: isScalpingActive,
        name: 'Scalping',
        time_period: Number(selectedValue),
      })
    );
  };

  return (
    <>
      <div className='block block--type2 trading-conditions-other-settings'>
        <div className='demo-account'>
          <label>Demo Account</label>
          <Switch checked={isDemoAccountActive} onChange={handleToggleDemoAccountActive} />
        </div>
        {/*<div className='netting'>*/}
        {/*  <label>Netting</label>*/}
        {/*  <Switch checked={isNettingActive} onChange={handleToggleNettingActive} />*/}
        {/*</div>*/}
        <div className='scalping'>
          <div className='scalping__label-switch'>
            <label>Scalping</label>
            <Switch checked={isScalpingActive} onChange={handleToggleScalpingActive} />
          </div>
          {showScalpingOptions && (
            <div className='scalping__options'>
              <select
                onChange={handleScalpingTimeChange}
                value={scalpingOptionTimePeriod}
                className='select'
              >
                <option value='' disabled>
                  Select time period
                </option>
                <option value='1'>1 minute</option>
                <option value='10'>10 minutes</option>
                <option value='60'>1 hour</option>
              </select>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OtherSettings;
