import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import NoDataBlock from 'ui/NoDataBlock';
import Pagination from 'ui/Pagination';
import { getDepositTransactionsByUser } from 'store/redux-toolkit/transactions/transactions-thunks';
import { useParams } from 'react-router-dom';
import DepositRow from './DepositRow';
import { ETransactionStatus } from 'enum/types';
import TableSelect from 'ui/TableSelect';
import SelectPaginationBlock from '../../../SelectPaginationCount';
import { TPaginationCount } from '../../../SelectPaginationCount/types';

const DepositTable = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { depositTransactions } = useAppSelector((state) => state.transactions);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('');
  const [perPage, setPerPage] = useState<TPaginationCount>(20);

  useEffect(() => {
    if (userId) {
      dispatch(
        getDepositTransactionsByUser({
          user_id: userId,
          current_page: currentPage,
          per_page: perPage,
          status: status || undefined,
        })
      );
    }
  }, [currentPage, userId, status]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    if (userId) {
      dispatch(
        getDepositTransactionsByUser({
          user_id: userId,
          current_page: currentPage,
          per_page: _perPage,
          status: status || undefined,
        })
      );
    }
  };

  return (
    <>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--transactions-history-deposit'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>System</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Date</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Currency</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Amount</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Fee</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Comment</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name'>
                    <TableSelect
                      obj={ETransactionStatus}
                      placeholder='Status'
                      element={status}
                      setElement={setStatus}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {depositTransactions?.data.length ? (
                depositTransactions.data.map((item) => <DepositRow key={item.id} item={item} />)
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {depositTransactions?.data?.length && (
        <div className='pagination-block'>
          <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
          {depositTransactions.last_page > 1 && (
            <Pagination
              pageCount={depositTransactions.last_page}
              forcePage={depositTransactions.current_page}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DepositTable;
