import classNames from 'classnames';
import { ETradeHistopyStatus } from 'enum/types';
import moment from 'moment';
import { FC } from 'react';
import { IPositionItem } from 'store/redux-toolkit/trades/trades-types';
import { addZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';

const TradeHistoryRow: FC<IPositionItem> = ({
  id,
  asset,
  order_id,
  transaction_type,
  amount,
  created_at,
  updated_at,
  open_price,
  close_price,
  profit,
  pnl_realized,
  swap,
  fee,
  status,
  order,
  columnVisibility,
  trUserTradeHistoryClassName,
}) => {
  return (
    <div className={`tr ${trUserTradeHistoryClassName}`}>
      {columnVisibility?.symbol && (
        <div className='td'>
          <p className='td-hidden-name'>Symbol</p>
          <div className='td-symbol'>
            <p className='td-symbol__name'>
              {asset.code} <span>{asset.market.name}</span>
            </p>
            <p className='td-symbol-details'>{asset.name} to American Dollar</p>
          </div>
        </div>
      )}
      {columnVisibility?.position_id && (
        <div className='td'>
          <p className='td-hidden-name'>Position ID</p>
          <p>
            <span className='link'>{addZeros(order_id)}</span>
          </p>
        </div>
      )}
      {columnVisibility?.type && (
        <div className='td'>
          <p className='td-hidden-name'>Type</p>
          <p>{transaction_type}</p>
        </div>
      )}
      {columnVisibility?.volume && (
        <div className='td'>
          <p className='td-hidden-name'>Volume</p>
          <p>{amount}</p>
        </div>
      )}
      {columnVisibility?.open_time && (
        <div className='td'>
          <p className='td-hidden-name'>Open time</p>
          <p>{moment(created_at).format('DD/MM/YY, HH:mm')}</p>
        </div>
      )}
      {columnVisibility?.open_price && (
        <div className='td'>
          <p className='td-hidden-name'>Open price</p>
          <p>{open_price}</p>
        </div>
      )}
      {columnVisibility?.close_time && (
        <div className='td'>
          <p className='td-hidden-name'>Close time</p>
          <p>{moment(updated_at).format('DD/MM/YY, HH:mm')}</p>
        </div>
      )}
      {columnVisibility?.close_price && (
        <div className='td'>
          <p className='td-hidden-name'>Close price</p>
          <p>{close_price}</p>
        </div>
      )}
      {columnVisibility?.profit && (
        <div className='td'>
          <p className='td-hidden-name'>Profit</p>
          <p className={classNames({ green: profit > 0, red: profit < 0 })}>
            {profit > 0 && '+'}
            {moneyFormat(profit)}
          </p>
        </div>
      )}
      {columnVisibility?.net_p_l && (
        <div className='td'>
          <p className='td-hidden-name'>NET P/L</p>
          <p className={classNames({ green: pnl_realized > 0, red: pnl_realized < 0 })}>
            {pnl_realized > 0 && '+'}
            {moneyFormat(pnl_realized)}
          </p>
        </div>
      )}
      {columnVisibility?.swap && (
        <div className='td'>
          <p className='td-hidden-name'>Swap</p>
          <p>{moneyFormat(swap)}</p>
        </div>
      )}
      {columnVisibility?.commission && (
        <div className='td'>
          <p className='td-hidden-name'>Commission</p>
          <p>{fee}</p>
        </div>
      )}
      {columnVisibility?.price_markup && (
        <div className='td'>
          <p className='td-hidden-name'>Price markup</p>
          <p>-</p>
          {/* TODO need to add correct value for price markup */}
        </div>
      )}
      {columnVisibility?.close_price_markup && (
        <div className='td'>
          <p className='td-hidden-name'>Close price markup</p>
          <p>-</p>
          {/* TODO need to add correct value for close_price_markup */}
        </div>
      )}
      {columnVisibility?.opened_by && (
        <div className='td'>
          <p className='td-hidden-name'>Opened by</p>
          <p>{order?.creator_class_name ? 'Client' : 'Admin'}</p>
        </div>
      )}
      {columnVisibility?.closed_by && (
        <div className='td'>
          <p className='td-hidden-name'>Closed by</p>
          <p>-</p>
          {/* TODO need to add correct value for closed_by */}
        </div>
      )}
      {columnVisibility?.status && (
        <div className='td td--right'>
          <p className='td-hidden-name'>Status</p>
          <div className='td-status td-status--in-process'>
            <span className='td-status__name'>
              {ETradeHistopyStatus[status as keyof typeof ETradeHistopyStatus]}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TradeHistoryRow;
