import React from 'react';
import { ERoutes } from 'enum/types';
import { NavLink, Outlet } from 'react-router-dom';

const TradingConditions = () => {
  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Trading Conditions</p>
        </div>
      </div>
      <div className='block-tabs'>
        <NavLink to={ERoutes.assets_commissions} className='block-tabs__item'>
          Assets
        </NavLink>
        <NavLink to={ERoutes.leverage} className='block-tabs__item'>
          Markets
        </NavLink>
        <NavLink to={ERoutes.other_settings} className='block-tabs__item'>
          Other Settings
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default TradingConditions;
