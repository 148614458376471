import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createTpSlOrder, createTpSlPosition } from 'store/redux-toolkit/trades/trades-thunks';
import InputNum from 'ui/InputNum';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { addZeros } from 'utils/integers';

const UpdateTpSlOrder: FC<IPopupProps> = ({ openModal, setOpenModal }) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<number>(0);
  const { assets, actionPopUp, is_demo } = useAppSelector((state) => state.trade);
  const curAsset = assets?.find((el) => el.id === actionPopUp?.update_tp_sl?.order?.asset.id);

  const sendUpdateSlTp = () => {
    dispatch(
      createTpSlOrder({
        is_demo,
        main_order_id: Number(actionPopUp?.update_tp_sl?.order?.id),
        stop_loss: actionPopUp?.update_tp_sl?.type === 'S/L' ? value : undefined,
        take_profit: actionPopUp?.update_tp_sl?.type === 'T/P' ? value : undefined,
      })
    );
  };

  return (
    <ModalWrapper openModal={openModal} setOpenModal={setOpenModal}>
      <div className='popup-header'>
        <p className='popup-text popup-text--center'>{`Update order #${addZeros(
          actionPopUp?.update_tp_sl?.order?.id
        )}`}</p>
      </div>
      <div className='popup-body'>
        {actionPopUp?.update_tp_sl?.type === 'T/P' ? (
          <InputNum
            value={String(actionPopUp?.update_tp_sl?.order?.take_profit || curAsset?.ask)}
            changeCount={setValue}
            title={actionPopUp?.update_tp_sl?.type || ''}
          />
        ) : (
          <InputNum
            value={String(actionPopUp?.update_tp_sl?.order?.stop_loss || curAsset?.bid)}
            changeCount={setValue}
            title={actionPopUp?.update_tp_sl?.type || ''}
          />
        )}
      </div>
      <div className='popup-footer'>
        <div className='popup-submit'>
          <button
            type='button'
            className='button button--type2 button--full-width'
            onClick={setOpenModal}
          >
            Cancel
          </button>
          <button type='button' className='button button--full-width' onClick={sendUpdateSlTp}>
            Add
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UpdateTpSlOrder;
