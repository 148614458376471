import { PlusIcon, PlusBlueIcon } from 'assets/svg-icon';
import { ERoutes } from 'enum/types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAdmins, deleteAdmin } from 'store/redux-toolkit/admins/admins-thunks';
import Pagination from 'ui/Pagination';
import { notificationContainer } from 'ui/notificationContainer';
import AdminTableRow from './AdminTableRow';
import DeleteAdmin from './DeleteAdmin';

const AdminManagement = () => {
  const dispatch = useAppDispatch();
  const { adminsList } = useAppSelector((state) => state.admins);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getAdmins({ per_page: 8, current_page: currentPage }));
  }, [currentPage]);

  const handleDelete = () => {
    if (!deleteId) {
      return;
    }
    dispatch(
      deleteAdmin({
        id: deleteId,
        onSuccess: () => {
          notificationContainer('Successfully deleted', 'success');
          setDeleteId(null);
        },
      })
    );
  };

  return (
    <>
      {deleteId !== null && (
        <DeleteAdmin onClose={() => setDeleteId(null)} onSubmit={handleDelete} />
      )}
      <div className='main-content'>
        <div className='title-block-wrap'>
          <div className='title-block'>
            <p className='title'>Admin Management</p>
          </div>
        </div>
        <div className='panel panel--mt-0 panel--content-end'>
          <Link to={ERoutes.create_admin} className='button button--type3'>
            Add New Admin
            <span className='btn-icon btn-icon--fill'>
              <PlusBlueIcon />
            </span>
          </Link>
        </div>
        <div className='table-block'>
          <div className='table-wrapper'>
            <div className='table table--admin-management'>
              <div className='table-header'>
                <div className='tr'>
                  <div className='td'>
                    <div className='td-name'>
                      <p>User ID</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>User Email</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>First Name</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Last Name</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Registration Date</p>
                    </div>
                  </div>
                  <div className='td td--right'>
                    <div className='td-name'>
                      <p>Action</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='table-body'>
                {adminsList?.users.map((item) => (
                  <AdminTableRow
                    key={item.id}
                    user={item}
                    onDeleteClick={(id) => setDeleteId(id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        {adminsList && adminsList?.last_page > 1 && (
          <Pagination
            pageCount={adminsList.last_page}
            forcePage={adminsList.current_page}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};

export default AdminManagement;
