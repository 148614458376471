import React, { FC } from 'react';

import { ERoutes } from 'enum/types';
import useInput from 'hooks/useInput';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { confirmApproveWithdrawal } from 'store/redux-toolkit/transactions/transactions-thunks';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { addOneAndZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';

const ConfirmApproveWithdraw: FC<IPopupProps> = ({ openModal, setOpenModal }) => {
  const dispatch = useAppDispatch();
  const { approvePopUp } = useAppSelector((state) => state.transactions);
  const hash = useInput('');

  const handleConfirm = () => {
    if (approvePopUp?.item && hash.value) {
      dispatch(
        confirmApproveWithdrawal({ withdrawal_id: approvePopUp.item.id, tx_hash: hash.value })
      );
    }
  };

  return (
    <ModalWrapper openModal={openModal} setOpenModal={setOpenModal}>
      <div className='popup-header'>
        <p className='popup-header__title'>Transaction approvment</p>
      </div>
      <div className='popup-body'>
        <div className='details-box'>
          <div className='details-item'>
            <p className='details-item__name'>User ID</p>
            <div className='details-item__value'>
              <p>
                <Link
                  to={`${ERoutes.users}/${approvePopUp?.item?.user_id}/profile`}
                  className='link'
                >
                  {addOneAndZeros(approvePopUp?.item?.user_id)}
                </Link>
              </p>
            </div>
          </div>
          <div className='details-item'>
            <p className='details-item__name'>Full Name</p>
            <div className='details-item__value'>
              <p>{approvePopUp?.item?.user.username}</p>
            </div>
          </div>
          <div className='details-item'>
            <p className='details-item__name'>Asset</p>
            <div className='details-item__value'>
              <p>{approvePopUp?.item?.currency.toUpperCase()}</p>
            </div>
          </div>
          <div className='details-item'>
            <p className='details-item__name'>Network</p>
            <div className='details-item__value'>
              <p>{approvePopUp?.item?.currency_network.toUpperCase()}</p>
            </div>
          </div>
          <div className='details-item'>
            <p className='details-item__name'>Amount</p>
            <div className='details-item__value'>
              <p>{moneyFormat(approvePopUp?.item?.amount, 0, 6)}</p>
            </div>
          </div>
        </div>
        <div className='input'>
          <p className='input__name input__name--ta-center input__name--mb-16 '>
            Please enter Tx Hash
          </p>
          <div className='input-wrapper'>
            <input className='input-item ' type='text' placeholder='Tx Hash' {...hash} />
          </div>
        </div>
      </div>
      <div className='popup-submit'>
        <button
          className='button button--type2 button--full-width'
          type='button'
          onClick={setOpenModal}
        >
          Cancel
        </button>
        <button
          className='button button--full-width'
          type='button'
          onClick={handleConfirm}
          disabled={!hash.value}
        >
          Confirm
        </button>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmApproveWithdraw;
