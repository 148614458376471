import { FC, useRef, useState } from 'react';

import { ArrowDropStatusIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import classNames from 'classnames';
import { IWithdrawalTransactionItem } from 'store/redux-toolkit/transactions/transactions-type';
import { useAppDispatch } from 'store/hooks';
import {
  approveWithdraw,
  cancelWithdraw,
} from 'store/redux-toolkit/transactions/transactions-slice';

export interface IStatusSelectProps {
  item: IWithdrawalTransactionItem;
}

const StatusSelect: FC<IStatusSelectProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const handleApprove = () => {
    dispatch(approveWithdraw({ item }));
    setOpen(!open);
  };

  const handleCancel = () => {
    dispatch(cancelWithdraw({ withdrawal_id: item.id }));
    setOpen(!open);
  };

  return (
    <div
      ref={ref}
      className={classNames('td-status td-status--new td-status--select', { active: open })}
    >
      <span className='td-status__name' onClick={handleClick}>
        New
        <span className='td-status__name-arrow'>
          <ArrowDropStatusIcon />
        </span>
      </span>

      <div className='dropdown dropdown--td-drop'>
        <ul>
          <li>
            <button type='button' onClick={handleApprove}>
              Approve
            </button>
          </li>
          <li>
            <button type='button' onClick={handleCancel}>
              Cancel
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StatusSelect;
