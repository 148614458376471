import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createAdmin, getAllPermissions } from 'store/redux-toolkit/admins/admins-thunks';
import { notificationContainer } from 'ui/notificationContainer';
import { ERoutes } from 'enum/types';
import { TFormOnSubmit } from '../types';
import FormAdmin from '../FormAdmin';
import PermissionsList from '../PermissionsList';

const CreateAdmin: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { allPermissionsList } = useAppSelector((state) => state.admins);
  const [permissionIds, setPermissionIds] = useState<number[]>([]);

  const handleSubmit: TFormOnSubmit = (values) => {
    if (!values.email) return;
    dispatch(
      createAdmin({
        params: {
          ...values,
          email: values.email,
          permissions: permissionIds.map((id) => ({
            id,
          })),
        },
        onSuccess: () => {
          notificationContainer('Successfully created', 'success');
          navigate(ERoutes.admin_management);
        },
      })
    );
  };

  const handleChange = (id: number) => {
    const newPermissionIds = [...permissionIds];
    if (permissionIds.includes(id)) {
      newPermissionIds.splice(newPermissionIds.indexOf(id), 1);
    } else {
      newPermissionIds.push(id);
    }
    setPermissionIds(newPermissionIds);
  };

  useEffect(() => {
    dispatch(getAllPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (allPermissionsList?.length && !permissionIds.length) {
      setPermissionIds(allPermissionsList?.map((item) => item.id));
    }
  }, [allPermissionsList]);

  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Add new administrator</p>
        </div>
      </div>
      <FormAdmin onSubmit={handleSubmit} />
      <PermissionsList getIsChecked={(id) => permissionIds.includes(id)} onChange={handleChange} />
    </div>
  );
};

export default CreateAdmin;
