import { CopyIcon } from 'assets/svg-icon';
import { ERoutes } from 'enum/types';
import moment from 'moment';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { IWithdrawalTransactionItem } from 'store/redux-toolkit/transactions/transactions-type';
import StatusSelect from 'ui/StatusSelect';
import { notificationContainer } from 'ui/notificationContainer';
import { addZeros, addOneAndZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';

interface IPendingTableRowProps {
  item: IWithdrawalTransactionItem;
}

const PendingTableRow: FC<IPendingTableRowProps> = ({ item }) => {
  const { id, user_id, user, created_at, currency, currency_network, amount, address } = item;

  const handleCopyAddress = () => {
    notificationContainer('Address copied', 'info');
  };

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>User ID</p>
        <p>
          <Link to={`${ERoutes.users}/${user_id}/profile`} className='link'>
            {addOneAndZeros(user_id)}
          </Link>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Email</p>
        <p>
          <a href={`mailto:${user.email}`} className='link'>
            {user.email}
          </a>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Full name</p>
        <p>{user.username}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Created date</p>
        <p>{moment(created_at).format('MM/DD/YY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Asset</p>
        <p>{currency.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Network</p>
        <p>{currency_network.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Amount</p>
        <p>{moneyFormat(amount, 0, 6)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Destination</p>
        <p className='copy-button'>
          <span className='copy-button__text'>{address || '-'}</span>
          {address && (
            <CopyToClipboard text={address} onCopy={handleCopyAddress}>
              <button type='button'>
                <CopyIcon />
              </button>
            </CopyToClipboard>
          )}
        </p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Status</p>
        <StatusSelect item={item} />
      </div>
    </div>
  );
};

export default PendingTableRow;
