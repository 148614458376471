import React, { FC, useState } from 'react';

import { ArrowDropIcon } from 'assets/svg-icon';
import { IPositionItem } from 'store/redux-toolkit/trades/trades-types';
import { useAppDispatch } from 'store/hooks';
import { ETradeType } from 'enum/types';
import classNames from 'classnames';
import RowChild from './RowChild';

interface IRowParentProps {
  items: IPositionItem[];
  columnVisibility: {
    symbol: boolean;
    position_id: boolean;
    type: boolean;
    volume: boolean;
    open_time: boolean;
    open_price: boolean;
    close_time: boolean;
    close_price: boolean;
    profit: boolean;
    net_p_l: boolean;
    swap: boolean;
    commission: boolean;
    price_markup: boolean;
    close_price_markup: boolean;
    opened_by: boolean;
    closed_by: boolean;
    status: boolean;
  };
  trUserTradeHistoryClassName?: string;
}

interface IAverage {
  value: number;
  profit: number;
  swap: number;
}

const RowParent: FC<IRowParentProps> = ({
  items,
  columnVisibility,
  trUserTradeHistoryClassName,
}) => {
  const dispatch = useAppDispatch();
  const { asset } = items[0];
  const [show, setShow] = useState(true);

  const value = items.reduce((total: number, order) => {
    order.transaction_type === ETradeType.buy ? (total += order.amount) : (total -= order.amount);
    return total;
  }, 0);

  const handleShowChild = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className='tr-group'>
      <div className={`tr ${trUserTradeHistoryClassName}`}>
        {columnVisibility?.symbol && (
          <div className='td'>
            <p className='td-hidden-name'>Symbol</p>
            <div className='td-flex td-flex--with-tr-drop'>
              <button type='button' className='td-flex__drop-btn' onClick={handleShowChild}>
                <ArrowDropIcon />
              </button>
              <div className='td-symbol'>
                <p className='td-symbol__name'>
                  {asset.code} <span>{asset.market.name}</span>{' '}
                  <span className='td-symbol__count'>{items.length}</span>
                </p>
                <p className='td-symbol-details'>{asset.name} to American Dollar</p>
              </div>
            </div>
          </div>
        )}
        {columnVisibility?.position_id && (
          <div className='td td--hide-mob'>
            <p className='td-hidden-name'>Position ID</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.type && (
          <div className='td'>
            <p className='td-hidden-name'>Type</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.volume && (
          <div className='td'>
            <p className='td-hidden-name'>Volume</p>
            <p>{value}</p>
          </div>
        )}
        {columnVisibility?.open_time && (
          <div className='td'>
            <p className='td-hidden-name'>Open time</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.open_price && (
          <div className='td td--hide-mob'>
            <p className='td-hidden-name'>Open price</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.close_time && (
          <div className='td td--hide-mob'>
            <p className='td-hidden-name'>Close time</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.close_price && (
          <div className='td td--hide-mob'>
            <p className='td-hidden-name'>Close price</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.profit && (
          <div className='td'>
            <p className='td-hidden-name'>Profit</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.net_p_l && (
          <div className='td'>
            <p className='td-hidden-name'>NET P/L</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.swap && (
          <div className='td'>
            <p className='td-hidden-name'>Swap</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.commission && (
          <div className='td'>
            <p className='td-hidden-name'>Commission</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.price_markup && (
          <div className='td'>
            <p className='td-hidden-name'>Price markup</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.close_price_markup && (
          <div className='td'>
            <p className='td-hidden-name'>Close price markup</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.opened_by && (
          <div className='td'>
            <p className='td-hidden-name'>Opened by</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.closed_by && (
          <div className='td'>
            <p className='td-hidden-name'>Closed by</p>
            <p></p>
          </div>
        )}
        {columnVisibility?.status && (
          <div className='td td--right'>
            <p className='td-hidden-name'>Status</p>
            <p></p>
          </div>
        )}
      </div>
      <div className={classNames('tr-drop-list', { active: show })}>
        {items.map((el) => (
          <RowChild
            key={el.id}
            item={el}
            columnVisibility={columnVisibility}
            trUserTradeHistoryClassName={trUserTradeHistoryClassName}
          />
        ))}
      </div>
    </div>
  );
};

export default RowParent;
