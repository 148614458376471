import { ClockIcon } from 'assets/svg-icon';
import { FC } from 'react';
import DatePicker from 'react-datepicker';

export interface ISelectCalendarProps {
  startDate: Date | undefined;
  setStartDate: (date: Date) => void;
  setCloseCalendar?: (val: boolean) => void;
  inline?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  maxDate?: Date | null;
}

const SelectCalendar: FC<ISelectCalendarProps> = ({
  startDate,
  setStartDate,
  setCloseCalendar,
  inline,
  showTimeSelect,
  dateFormat,
  ...rest
}) => {
  const handleChangeDate = (date: Date) => {
    setStartDate(date);
    if (setCloseCalendar) setCloseCalendar(false);
  };

  return inline ? (
    <DatePicker
      selected={startDate}
      onChange={handleChangeDate}
      className='input-item input-item--pr50'
      inline
      calendarClassName='calendar--dropdown'
      showTimeSelect
      {...rest}
    />
  ) : (
    <div className='input'>
      <div className='input-wrapper input--datepicker'>
        <DatePicker
          selected={startDate}
          onChange={handleChangeDate}
          className='input-item input-item--pr50'
          placeholderText='--/--/--'
          isClearable
          showTimeSelect={showTimeSelect}
          dateFormat={dateFormat}
          {...rest}
        />
        <div className='input-icon input-icon--right'>
          <ClockIcon />
        </div>
      </div>
    </div>
  );
};

export default SelectCalendar;
