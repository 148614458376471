import React, { FC, useState } from 'react';
import { FieldProps } from 'formik';

import classNames from 'classnames';
import { PasswordHideIcon, PasswordShowIcon } from 'assets/svg-icon';

interface CustomInputProps {
  label: string;
  iconLeft?: JSX.Element;
}

const PasswordField: FC<FieldProps & CustomInputProps> = ({
  field,
  form: { touched, errors },
  label,
  iconLeft,
  ...props
}) => {
  const [isShowPass, setIsShowPass] = useState(false);
  const handleShowPass = () => setIsShowPass(!isShowPass);
  return (
    <div
      className={classNames('input', {
        'input--error': touched[field.name] && errors[field.name],
      })}
    >
      <p className='input__name'>{label}</p>
      <div className='input-wrapper'>
        <input
          {...field}
          {...props}
          type={isShowPass ? 'text' : 'password'}
          className={classNames('input-item', { 'input-item--left-icon': iconLeft })}
        />
        {iconLeft && <span className='input-icon'>{iconLeft}</span>}
        <button type='button' className='show-pass' onClick={handleShowPass}>
          <PasswordHideIcon />
          <PasswordShowIcon />
        </button>
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification--error'>{String(errors[field.name])}</p>
      )}
    </div>
  );
};

export default PasswordField;
