export enum LoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NEVER = 'NEVER',
  SUCCESS = 'SUCCESS',
}

export enum ERoutes {
  home = '/',
  dashbord = '/',
  login = '/login',
  register = '/register',
  users = '/users',
  //Account
  account = ':userId',
  profile = 'profile',
  trade_account = 'trade-account',
  orders = 'trade',
  trans_history = 'transactions-history',
  referrals = 'referrals',
  edit = ':userId/edit',
  // Commissions
  commissions = '/commissions',
  assets_commissions = 'assets',
  referral_program = 'referral-program',
  deposit = 'deposit',
  fees = 'fees',
  // Limitations
  limitations = '/limitations',
  withdrawal = 'withdrawal',
  // Admin management
  admin_management = '/admin-management',
  create_admin = 'create-admin',
  //Ohers
  trades = '/trades',
  deposits = '/deposits',
  withdrawals = '/withdrawals',
  transfers = '/transfers',
  leverage = 'leverage',
  manual_deposit = 'manual-deposit',
  other_settings = 'other-settings',
  //Settings
  trading_conditions = '/settings/trading-conditions',
  deposit_withdrawal = '/settings/deposit-withdrawal',
  mailing_setup = '/settings/mailing-setup',
  kyc_kyb = '/settings/kyc-kyb',
  referral_system = '/settings/referral-system',
  registration = '/settings/registration',
  localization_editor = '/settings/localization-editor',
}

export enum EUserStatus {
  Inactive,
  Activated,
  Pending,
  Rejected,
  Archived,
}

export enum ETransactionStatus {
  success = 'Confirmed',
  waiting = 'In process',
  canceled = 'Canceled',
  failed = 'Failed',
}

export enum EWithdrawStatus {
  approved = 'Successful',
  new = 'New',
  canceled = 'Canceled',
}

export enum ETradeHistopyStatus {
  close = 'Canceled',
}

export enum ETradeType {
  buy = 'buy',
  sell = 'sell',
}

export enum EFilterField {
  user_id = 'User ID',
  email = 'Email',
  first_name = 'Name',
  last_name = 'Surname',
}

export enum ETradeAction {
  update_tp_sl = 'update_tp_sl',
}
