import {
  addManualDeposit,
  getDepositsTransactions,
  getDepositTransactionsByUser,
  getTransferTransactions,
  getWithdrawalTransactions,
  getWithdrawTransactionsByUser,
} from './transactions-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TransactionsState,
  IDepositTransactionsResponse,
  IWithdrawalTransactionsResponse,
  IApproveWithdrawPopUp,
  ITransactionsRequest,
  ICancelWithdrawRequest,
  ITransferTransactionsResponse,
} from './transactions-type';

const initialState: TransactionsState = {
  depositTransactions: null,
  withdrawalTransactions: null,
  transferTransactions: null,
  approvePopUp: null,
  apiFilter: null,
  cancelPopUp: null,
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    approveWithdraw: (state, { payload }: PayloadAction<IApproveWithdrawPopUp>) => {
      state.approvePopUp = payload;
    },
    clearWithdrawPopUp: (state) => {
      state.approvePopUp = null;
    },
    cancelWithdraw: (state, { payload }: PayloadAction<ICancelWithdrawRequest>) => {
      state.cancelPopUp = payload;
    },
    clearCancelPopUp: (state) => {
      state.cancelPopUp = null;
    },
    setApiFilter: (state, { payload }: PayloadAction<ITransactionsRequest>) => {
      state.apiFilter = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getDepositTransactionsByUser.fulfilled,
        (state, { payload }: PayloadAction<IDepositTransactionsResponse>) => {
          state.depositTransactions = payload;
        }
      )
      .addCase(
        getWithdrawTransactionsByUser.fulfilled,
        (state, { payload }: PayloadAction<IWithdrawalTransactionsResponse>) => {
          state.withdrawalTransactions = payload;
        }
      )
      .addCase(
        getDepositsTransactions.fulfilled,
        (state, { payload }: PayloadAction<IDepositTransactionsResponse>) => {
          state.depositTransactions = payload;
        }
      )
      .addCase(
        getWithdrawalTransactions.fulfilled,
        (state, { payload }: PayloadAction<IWithdrawalTransactionsResponse>) => {
          state.withdrawalTransactions = payload;
        }
      )
      .addCase(
        getTransferTransactions.fulfilled,
        (state, { payload }: PayloadAction<ITransferTransactionsResponse>) => {
          state.transferTransactions = payload;
        }
      );
  },
});
export const {
  approveWithdraw,
  clearWithdrawPopUp,
  setApiFilter,
  cancelWithdraw,
  clearCancelPopUp,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
