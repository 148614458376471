import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getMarkets,
  getLeverages,
  editMarket,
} from 'store/redux-toolkit/commission/commission-thunks';
import { notificationContainer } from 'ui/notificationContainer';
import TableRow from './TableRow';

const LeverageTabsContent = () => {
  const dispatch = useAppDispatch();
  const { markets } = useAppSelector((state) => state.commissions);
  const [editId, setEditId] = useState<number | null>(null);

  const handleEdit = (
    id: number,
    leverage: number,
    lot_size: number,
    fee_percent: number,
    fee: number
  ) => {
    dispatch(
      editMarket({
        params: {
          market_id: id,
          leverage,
          lot_size,
          fee_percent,
          fee,
        },
        onSuccess: () => {
          notificationContainer('Leverage was edited', 'success');
          setEditId(null);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getMarkets());
    dispatch(getLeverages());
  }, []);

  return (
    <div className='table-block '>
      <div className='table-wrapper'>
        <div className='table table--settings-leverage'>
          <div className='table-header'>
            <div className='tr'>
              <div className='td'>
                <div className='td-name'>
                  <p>Markets</p>
                </div>
              </div>
              <div className='td'>
                <div className='td-name'>
                  <p>Leverage</p>
                </div>
              </div>
              <div className='td'>
                <div className='td-name'>
                  <p>Lot Size</p>
                </div>
              </div>
              <div className='td'>
                <div className='td-name'>
                  <p>Fee %</p>
                </div>
              </div>
              <div className='td'>
                <div className='td-name'>
                  <p>Fee</p>
                </div>
              </div>
              <div className='td td--right'>
                <div className='td-name'>
                  <p>Action</p>
                </div>
              </div>
            </div>
          </div>
          <div className='table-body'>
            {markets?.map((item) => (
              <TableRow
                key={item.id}
                data={item}
                editId={editId}
                setEditId={setEditId}
                onEdit={handleEdit}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeverageTabsContent;
