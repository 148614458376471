import { FC } from 'react';
import { ArrowDropIcon } from 'assets/svg-icon';
import { useSelect } from 'hooks/useSelect';
import { TPaginationCount, ISelectPaginationBlock } from './types';

const OPTIONS = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
];

const SelectPaginationBlock: FC<ISelectPaginationBlock> = ({ value, onSelect }) => {
  const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

  const handleSelect = (selected: TPaginationCount) => {
    setOpen(false);
    onSelect(selected);
  };

  return (
    <div className='pagination-select-block select-block--width-170'>
      <div className={`select ${open ? 'active' : ''}`}>
        <button type='button' className='select__current ' ref={triggerRef} onClick={toggleOpen}>
          {value && OPTIONS.find((item) => item.value === value)?.label}
          <span className='select__current-arrow'>
            <ArrowDropIcon />
          </span>
        </button>
        <div className='select__drop' ref={dropRef}>
          <div className='select__drop-scroll'>
            <div className='select__drop-item'>
              <ul>
                {OPTIONS.map(({ value, label }) => (
                  <li key={value}>
                    <button onClick={() => handleSelect(value as TPaginationCount)} type='button'>
                      {label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPaginationBlock;
