import { FC } from 'react';

import classNames from 'classnames';
import { IPositionItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ETradeType } from 'enum/types';
import { moneyFormat } from 'utils/moneyFormat';
import ClosedSelect from 'ui/ClosedSelect';
import {
  closePositionsByAsset,
  closePositionsByParams,
} from 'store/redux-toolkit/trades/trades-thunks';
import { EditIcon, Trash2Icon } from 'assets/svg-icon';

interface IRowParentProps {
  items: IPositionItem[];
  filterParams: ITradeRequest;
}

interface IAverage {
  value: number;
  profit: number;
  swap: number;
}

const RowParent: FC<IRowParentProps> = ({ items, filterParams }) => {
  const dispatch = useAppDispatch();
  const { asset } = items[0];
  const { assets, is_demo } = useAppSelector((state) => state.trade);
  const foundAsset = assets?.find((el) => el.id === asset.id);

  const average = items.reduce(
    (average: IAverage, position: IPositionItem) => {
      position.transaction_type === ETradeType.buy
        ? (average.value += position.amount)
        : (average.value -= position.amount);
      average.profit += foundAsset
        ? (position.transaction_type === ETradeType.buy
            ? foundAsset?.bid - position.open_price
            : position.open_price - foundAsset.ask) * position.amount
        : // * position.leverage
          0;
      average.swap += position.swap;
      return average;
    },
    { value: 0, profit: 0, swap: 0 }
  );

  const netProfit = average.profit - average.swap;

  const handleClosePositionsByAsset = () => {
    dispatch(closePositionsByAsset({ apiParams: { is_demo, asset_id: asset.id }, filterParams }));
  };

  const handleCloseProfitLoss = (profit: boolean) => {
    dispatch(
      closePositionsByParams({
        filterParams,
        apiParams: {
          is_demo,
          is_close_profitable_positions: profit,
          asset_id: asset.id,
        },
      })
    );
  };

  return (
    <div className='tr cursor--pointer'>
      <div className='td'>
        <p className='td-hidden-name'>Position</p>
        <div className='td-symbol'>
          <p className='td-symbol__name'>
            {asset.code} <span>{asset.market.name}</span>
            <span className='td-symbol__count'>{items.length}</span>
          </p>
        </div>
        <p className='td-symbol-details'>{asset.name} to American Dollar</p>
      </div>
      <div className='td td--hide-mob'>
        <p className='td-hidden-name'>Sub-admin</p>
        <p className='td-value td-value--fw-500'></p>
      </div>
      <div className='td td--hide-mob'>
        <p className='td-hidden-name'>User ID</p>
        <p className='td-value td-value--fw-500'></p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Type</p>
        {average.value > 0 && <p className='td-value td-value--fw-500 green'>Buy</p>}
        {average.value < 0 && <p className='td-value td-value--fw-500 red'>Sell</p>}
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Volume</p>
        <p className='td-value td-value--fw-500'>
          {String(average.value.toFixed(2)).replace('-', '')}
        </p>
      </div>
      <div className='td td--hide-mob'>
        <p className='td-hidden-name'>SL</p>
      </div>
      <div className='td td--hide-mob'>
        <p className='td-hidden-name'>TP</p>
      </div>
      <div className='td td--hide-mob'>
        <p className='td-hidden-name'>Open Price</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Market Price</p>
        <p className='td-value td-value--fw-500'>
          {average.value > 0 ? foundAsset?.bid : foundAsset?.ask}
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Gross Profit</p>
        <p
          className={classNames('td-value td-value--fw-500', {
            red: average.profit < 0,
            green: average.profit > 0,
          })}
        >
          {moneyFormat(average.profit)}
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Net Profit</p>
        <p
          className={classNames('td-value td-value--fw-500', {
            red: netProfit < 0,
            green: netProfit > 0,
          })}
        >
          {moneyFormat(netProfit)}
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>NET P/L %</p>
        <p className='red'>-</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Rollover</p>
        <p className='td-value td-value--fw-500'>{moneyFormat(average.swap)}</p>
      </div>
      <div className='td '>
        <p className='td-hidden-name'>Edit</p>
        <div className='table-buttons'>
          <button
            type='button'
            className='table-buttons__btn table-buttons__btn--color-blue'
          ></button>
        </div>
      </div>
      <div className='td '>
        <p className='td-hidden-name'>Delete</p>
        <div className='table-buttons'></div>
      </div>
      <div className='td td--right'>
        <ClosedSelect
          closeAll={handleClosePositionsByAsset}
          closeProfitLoss={handleCloseProfitLoss}
        />
      </div>
    </div>
  );
};

export default RowParent;
