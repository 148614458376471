import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useSearchParams } from 'react-router-dom';
import {
  getSwapComissions,
  editSwapComission,
  getMarkets,
  addSwapComissionRequest,
} from 'store/redux-toolkit/commission/commission-thunks';
import {
  IEditSwapComissionRequest,
  IAddSwapComissionRequest,
} from 'store/redux-toolkit/commission/commission-types';
import NoDataBlock from 'ui/NoDataBlock';
import { notificationContainer } from 'ui/notificationContainer';
import Pagination from 'ui/Pagination';
import SwapRow from './SwapRow';
import {
  addSwapComission,
  cancelAddComission,
} from '../../../store/redux-toolkit/commission/commission-slice';

const SwapCommissions = () => {
  const dispatch = useAppDispatch();
  const [editingId, setEditingId] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const { swapCommissions, markets } = useAppSelector((state) => state.commissions);
  const [searchParams, setSearchParams] = useSearchParams();
  const [copyRow, setCopyRow] = useState<string | null>(null);
  const [fromCopy, setFromCopy] = useState<number | null>(null);

  const marketId = searchParams.get('market_id');
  const { data = [], last_page = 1 } = swapCommissions || {};

  useEffect(() => {
    dispatch(getMarkets());
  }, []);

  useEffect(() => {
    if (markets && !marketId) {
      setSearchParams({ market_id: String(markets?.[0].id) });
    }
  }, [markets, marketId]);

  useEffect(() => {
    if (!marketId) {
      return;
    }
    dispatch(getSwapComissions({ market_id: Number(marketId), current_page: page }));
  }, [page, marketId]);

  const handleEditSwap = (params: IEditSwapComissionRequest, isCopy: boolean) => {
    if (isCopy) {
      dispatch(
        addSwapComissionRequest({
          ...(params as IAddSwapComissionRequest),
          onSuccess: () => {
            makeStaticCopyRow();
            notificationContainer('Successfully added', 'success');
          },
        })
      );
    } else {
      dispatch(
        editSwapComission({
          ...params,
          onSuccess: () => notificationContainer('Successfully edited', 'success'),
        })
      );
    }
  };

  const toggleActive = (id: number, isActive: boolean) => {
    dispatch(
      editSwapComission({
        params: { asset_id: id, is_active: isActive },
        onSuccess: () =>
          notificationContainer(
            `Successfully ${isActive ? 'deactivated' : 'activated'}`,
            'success'
          ),
      })
    );
  };

  const handleCancelEdit = () => {
    setEditingId(null);
  };

  const handleSetEditId = (id: number) => {
    setEditingId(id);
  };

  const addRow = (id: number) => {
    setFromCopy(id);
    dispatch(
      addSwapComission({
        params: { id },
        onSuccess: (id) => {
          setCopyRow(id);
        },
      })
    );
  };

  const makeStaticCopyRow = () => {
    setCopyRow(null);
    setFromCopy(null);
  };

  const cancelCopy = () => {
    if (!copyRow) return;
    dispatch(
      cancelAddComission({
        params: {
          id: copyRow,
        },
        onSuccess: () => {
          makeStaticCopyRow();
        },
      })
    );
  };

  return (
    <>
      <div className='block block--type2'>
        <div className='block-nav'>
          {markets?.map(({ id, name }) => (
            <button
              key={id}
              onClick={() => setSearchParams({ market_id: String(id) })}
              className={`block-nav__item ${String(id) === marketId ? 'active' : ''}`}
            >
              {name}
            </button>
          ))}
        </div>
        <div className='table-block '>
          <div className='table-wrapper'>
            <div className='table table--commissions-assets'>
              <div className='table-header'>
                <div className='tr'>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Assets</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Order size min</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Lot size</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Spread min%</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Spread max%</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Swap short %</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Swap long %</p>
                    </div>
                  </div>
                  <div className='td td--flex flex-c'>
                    <div className='td-name'>
                      <p>Action</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='table-body'>
                {data.length ? (
                  data.map((s) => (
                    <SwapRow
                      key={s.id}
                      item={s}
                      editingId={editingId}
                      onEdit={handleEditSwap}
                      toggleActive={toggleActive}
                      onCancelEdit={handleCancelEdit}
                      onSetEditId={handleSetEditId}
                      copyRow={addRow}
                      copiedRow={copyRow}
                      setCopiedRow={cancelCopy}
                      fromCopy={fromCopy}
                    />
                  ))
                ) : (
                  <NoDataBlock />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {last_page > 1 && (
        <Pagination pageCount={last_page} forcePage={page} onPageChange={setPage} />
      )}
    </>
  );
};

export default SwapCommissions;
