import { useAppDispatch, useAppSelector } from 'store/hooks';
import { editAdmin } from 'store/redux-toolkit/admins/admins-thunks';
import { notificationContainer } from 'ui/notificationContainer';
import PermissionsList from '../../PermissionsList';

const EditAdminPermissions = () => {
  const dispatch = useAppDispatch();
  const { adminItem } = useAppSelector((state) => state.admins);
  const adminPermissionIds = adminItem?.permissions?.map((item) => item.id) || [];

  const { id, first_name = '', last_name = '' } = adminItem || {};

  const handleEdit = (permissionId: number) => {
    const checked = adminPermissionIds.includes(permissionId);
    const newIds = [...adminPermissionIds];
    if (checked) {
      newIds.splice(newIds.indexOf(permissionId), 1);
    } else {
      newIds.push(permissionId);
    }

    dispatch(
      editAdmin({
        id: Number(id),
        params: {
          first_name,
          last_name,
          permissions: newIds.map((id) => ({ id })),
        },
        onSuccess: () => {
          notificationContainer('Successfully edited', 'success');
        },
      })
    );
  };

  return (
    <PermissionsList getIsChecked={(id) => adminPermissionIds.includes(id)} onChange={handleEdit} />
  );
};

export default EditAdminPermissions;
