import React, { FC, useState } from 'react';

import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { IPositionItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import { ERoutes, ETradeAction, ETradeType } from 'enum/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CloseTableIcon, InfoCircleIcon, PlusIcon, EditIcon, Trash2Icon } from 'assets/svg-icon';
import { capitalize } from 'utils/strings';
import { addZeros, addOneAndZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';
import { Link } from 'react-router-dom';
import { setActionPopUp } from 'store/redux-toolkit/trades/trades-slice';
import {
  closePosition,
  deleteTpSlPosition,
  deletePosition,
} from 'store/redux-toolkit/trades/trades-thunks';
import { notificationContainer } from 'ui/notificationContainer';
import { ConfirmPopup } from 'ui/ConfirmPopup';
import { OpenPositionEdit } from 'ui/modals/OpenPositionEdit';

interface IRowChild {
  item: IPositionItem;
  childs?: number;
  openList: boolean;
  queryParams: ITradeRequest;
  onRefetch?: () => void;
}

const RowChild: FC<IRowChild> = ({ item, childs = 1, openList, queryParams, onRefetch }) => {
  const {
    id,
    user_id,
    asset,
    amount,
    transaction_type,
    stop_loss,
    take_profit,
    open_price,
    swap,
    leverage,
  } = item;

  const [isOpen, setIsOpen] = useState(false);
  const [closeConfirm, setCloseConfirm] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const dispatch = useAppDispatch();
  const { assets, is_demo, deleteLoading } = useAppSelector((state) => state.trade);
  const foundAsset = assets?.find((el) => el.id === asset.id);

  const currentPrice = transaction_type === ETradeType.buy ? foundAsset?.bid : foundAsset?.ask;

  const profit = currentPrice
    ? (transaction_type === ETradeType.buy
        ? currentPrice - open_price
        : open_price - currentPrice) * amount
    : // * leverage
      0;
  const netProfit = profit - swap;

  const handleUpdateTp = () => {
    dispatch(setActionPopUp({ update_tp_sl: { item, type: 'T/P' } }));
  };

  const handleUpdateSl = () => {
    dispatch(setActionPopUp({ update_tp_sl: { item, type: 'S/L' } }));
  };

  const handleShowInfo = () => {};

  const handleDeleteSl = () => {
    dispatch(
      deleteTpSlPosition({
        is_demo,
        position_id: id,
        is_delete_sl_order: true,
      })
    );
  };

  const handleDeleteTp = () => {
    dispatch(
      deleteTpSlPosition({
        is_demo,
        position_id: id,
        is_delete_tp_order: true,
      })
    );
  };

  const handleClosePosition = () => {
    dispatch(
      closePosition({
        apiParams: { position_id: id, is_demo },
        filterParams: queryParams,
        onSuccess: () => {
          setCloseConfirm(false);
          notificationContainer('Position successfully closed', 'success');
        },
      })
    );
  };

  const handleDeletePosition = () => {
    dispatch(
      deletePosition({
        apiParams: {
          position_id: id,
          is_demo,
        },
        onSuccess: () => {
          notificationContainer('Position successfully deleted', 'success');
          onRefetch?.();
          setDeleteConfirm(false);
        },
      })
    );
  };

  return (
    <>
      <div
        className={classNames('tr', {
          'tr-child': childs > 1,
          active: openList && childs > 1,
        })}
      >
        {childs && childs > 0 ? (
          <div className='td'>
            <p className='td-hidden-name'>Position</p>
            <div className='td-flex'>
              <p>
                <span className='link'>{addZeros(id)}</span>
              </p>
              <span className='tooltip-item'>
                <span className='tooltip-item__icon' onClick={handleShowInfo}>
                  <InfoCircleIcon />
                </span>
              </span>
            </div>
          </div>
        ) : (
          <div className='td'>
            <p className='td-hidden-name'>Position</p>
            <div className='td-symbol'>
              <p className='td-symbol__name'>
                {asset.code} <span className='symbol-info'>{asset.market.name}</span>
              </p>
            </div>
            <span className='td-symbol-details'>{asset.name} to American Dollar</span>
          </div>
        )}
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>Sub-admin</p>
          <p className='td-value td-value--fw-500 '></p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>User ID</p>
          <p>
            <Link to={`${ERoutes.users}/${user_id}/profile`} className='link'>
              {addOneAndZeros(user_id)}
            </Link>
          </p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Type</p>
          <p className={`td-value td-value td-value--side-type td-value--side-${transaction_type}`}>
            {capitalize(transaction_type || '')}
          </p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Volume</p>
          <p className='td-value'>{amount}</p>
        </div>

        <div className='td td--center'>
          <p className='td-hidden-name'>S/L</p>
          {stop_loss ? (
            <div className='td-flex td-flex-value-close'>
              <button type='button' className='td-value' onClick={handleUpdateSl}>
                {stop_loss}
              </button>
              <div className='table-buttons '>
                <button type='button' onClick={handleDeleteSl}>
                  <CloseTableIcon />
                </button>
              </div>
            </div>
          ) : (
            <button type='button' className='button button--type-icon' onClick={handleUpdateSl}>
              <PlusIcon />
            </button>
          )}
        </div>
        <div className='td td--center'>
          <p className='td-hidden-name'>T/P</p>
          {take_profit ? (
            <div className='td-flex td-flex-value-close'>
              <button type='button' className='td-value' onClick={handleUpdateTp}>
                {take_profit}
              </button>
              <div className='table-buttons'>
                <button type='button' onClick={handleDeleteTp}>
                  <CloseTableIcon />
                </button>
              </div>
            </div>
          ) : (
            <button type='button' className='button button--type-icon' onClick={handleUpdateTp}>
              <PlusIcon />
            </button>
          )}
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Open Price</p>
          <p className='td-value'>{open_price}</p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Market Price</p>
          <p className='td-value'>
            {transaction_type === ETradeType.buy ? foundAsset?.bid : foundAsset?.ask}
          </p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Gross Profit</p>
          <p className={classNames('td-value', { red: profit < 0, green: profit > 0 })}>
            {moneyFormat(profit)}
          </p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Net Profit</p>
          <p className={classNames('td-value', { red: netProfit < 0, green: netProfit > 0 })}>
            {moneyFormat(netProfit)}
          </p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Net P/L %</p>
          <p className='td-value'>-</p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Rollover</p>
          <p className='td-value'>{moneyFormat(swap)}</p>
        </div>
        <div className='td '>
          <p className='td-hidden-name'>Edit</p>
          <div className='table-buttons'>
            <button
              type='button'
              className='table-buttons__btn table-buttons__btn--color-blue'
              onClick={() => setIsOpen(true)}
            >
              <EditIcon />
            </button>
          </div>
        </div>
        <div className='td '>
          <p className='td-hidden-name'>Delete</p>
          <div className='table-buttons'>
            <button
              type='button'
              className='table-buttons__btn table-button__btn--color-red'
              onClick={() => setDeleteConfirm(true)}
            >
              <Trash2Icon />
            </button>
          </div>
        </div>
        <div className='td td--right'>
          <p className='td-hidden-name'>Сlose</p>
          <div className='table-buttons'>
            <button type='button' className='' onClick={() => setCloseConfirm(true)}>
              <CloseTableIcon />
            </button>
            <div className='table-buttons-drop '>
              <a href='' className='table-buttons-drop__item'>
                Some option
              </a>
              <a href='' className='table-buttons-drop__item'>
                Option 2
              </a>
            </div>
          </div>
        </div>
      </div>
      {isOpen &&
        createPortal(
          <OpenPositionEdit onClose={() => setIsOpen(false)} item={item} onRefetch={onRefetch} />,
          document.getElementsByTagName('main')[0]
        )}
      {closeConfirm &&
        createPortal(
          <ConfirmPopup
            body='Are you sure you want to close it?'
            onClose={() => setCloseConfirm(false)}
            onConfirm={handleClosePosition}
          />,
          document.getElementsByTagName('main')[0]
        )}
      {deleteConfirm &&
        createPortal(
          <ConfirmPopup
            body='Are you sure you want to delete it?'
            onClose={() => setDeleteConfirm(false)}
            onConfirm={handleDeletePosition}
            confirmDisabled={deleteLoading}
          />,
          document.getElementsByTagName('main')[0]
        )}
    </>
  );
};

export default RowChild;
