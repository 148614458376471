import React from 'react';
import { ERoutes } from 'enum/types';
import { NavLink, Outlet } from 'react-router-dom';

const LocalizationEditor = () => {
  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Localization Editor</p>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default LocalizationEditor;
