import { FC } from 'react';
import { moneyFormat } from 'utils/moneyFormat';
import { ISummaryItem } from './types';

const SummaryItem: FC<ISummaryItem> = ({ amount, title, showCurrency }) => {
  return (
    <div className='summary-total-list__item'>
      <div className='summary-total-list__item-counter'>
        <p>
          {amount} {showCurrency && <span>USD</span>}
        </p>
      </div>
      <div className='summary-total-list__item-title'>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default SummaryItem;
