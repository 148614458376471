import React, { FC, useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { ETransactionStatus } from 'enum/types';
import TableSelect from 'ui/TableSelect';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDepositsTransactions } from 'store/redux-toolkit/transactions/transactions-thunks';
import DepositRow from './DepositRow';
import NoDataBlock from 'ui/NoDataBlock';
import Pagination from 'ui/Pagination';
import AddDepositPopup from './AddDepositPopup';
import { ITransactionTabProps } from './types';
import SelectPaginationBlock from '../SelectPaginationCount';
import { TPaginationCount } from '../SelectPaginationCount/types';

const DepositTab: FC<ITransactionTabProps> = ({ params, setParams }) => {
  const dispatch = useAppDispatch();
  const { depositTransactions } = useAppSelector((state) => state.transactions);
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [addOpen, setAddOpen] = useState(false);
  const [perPage, setPerPage] = useState<TPaginationCount>(20);

  const fetchDepositsTransactions = useCallback(() => {
    dispatch(
      getDepositsTransactions({
        current_page: currentPage,
        per_page: perPage,
        status: status || undefined,
        ...params,
      })
    );
  }, [currentPage, status, params]);

  useEffect(() => {
    fetchDepositsTransactions();
  }, [fetchDepositsTransactions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [depositTransactions?.last_page]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    dispatch(
      getDepositsTransactions({
        current_page: currentPage,
        per_page: _perPage,
        status: status || undefined,
        ...params,
      })
    );
  };

  return (
    <>
      <div className='table-filter table-filter--deposits-second table-filter--mt-0'>
        <button type='button' className='button' onClick={() => setAddOpen(true)}>
          Add deposit
          <span className='btn-icon'>
            <svg
              width='14'
              height='14'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 7.00001C0 7.41578 0.34788 7.75514 0.755154 7.75514H6.24486V13.2449C6.24486 13.6522 6.58426 14 7.00001 14C7.41578 14 7.76368 13.6522 7.76368 13.2449V7.75514H13.2449C13.6522 7.75514 14 7.41578 14 7.00001C14 6.58426 13.6522 6.23638 13.2449 6.23638H7.76368V0.755154C7.76368 0.34788 7.41578 0 7.00001 0C6.58426 0 6.24486 0.34788 6.24486 0.755154V6.23638H0.755154C0.34788 6.23638 0 6.58426 0 7.00001Z'
                fill='white'
              />
            </svg>
          </span>
        </button>
      </div>
      <div className='block'>
        <div className='table-block'>
          <div className='table-wrapper'>
            <div className='table table--transactions-deposit'>
              <div className='table-header'>
                <div className='tr'>
                  <div className='td'>
                    <div className='td-name'>
                      <p>ID</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>User ID</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Email</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Full name</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Date</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Currency</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Amount</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Comment</p>
                    </div>
                  </div>
                  <div className='td'>
                    <div className='td-name'>
                      <p>Payment system</p>
                    </div>
                  </div>
                  <div className='td td--right'>
                    <div className='td-name'>
                      <TableSelect
                        obj={ETransactionStatus}
                        placeholder='Status'
                        element={status}
                        setElement={setStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='table-body'>
                {depositTransactions?.data.length ? (
                  depositTransactions.data.map((item) => <DepositRow key={item.id} {...item} />)
                ) : (
                  <NoDataBlock />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {depositTransactions?.data?.length && (
        <div className='pagination-block'>
          <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
          {depositTransactions.last_page > 1 && (
            <Pagination
              pageCount={depositTransactions.last_page}
              forcePage={depositTransactions.current_page}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      )}
      {addOpen &&
        createPortal(
          <AddDepositPopup
            onClose={() => setAddOpen(false)}
            onRefetch={fetchDepositsTransactions}
          />,
          document.getElementsByTagName('main')[0]
        )}
    </>
  );
};

export default DepositTab;
