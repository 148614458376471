import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('enter a valid email').required('email is required'),
  password: Yup.string().min(6, 'validation length password').required('password is required'),
});

export const editAdminShema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  password: Yup.string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, 'Validation.password_validation')
    .required('Validation.password_required')
    .max(25, 'Validations.password_max_length'),
  password_repeat: Yup.string()
    .required(String('Validation.password_confirm'))
    .oneOf([Yup.ref('password')], String('Validation.password_match')),
});

export const createAdminShema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Enter a valid email').required('Email is required'),
  password: Yup.string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, 'Password validation')
    .required('Password required')
    .max(25, 'Password max length'),
  password_repeat: Yup.string()
    .required(String('Validation.password_confirm'))
    .oneOf([Yup.ref('password')], String('Password match')),
});

export const addOrderAdminShema = Yup.object().shape({
  open_price: Yup.number()
    .typeError('Open price must be a number')
    .positive('Open price must be greater than 0')
    .required('Please enter open price'),
  stop_loss: Yup.number()
    .typeError('Stop loss must be a number')
    .positive('Stop loss must be greater than 0'),
  lot_volume: Yup.number()
    .typeError('Lot’s volume must be a number')
    .required('Please enter lot’s volume'),
  take_profit: Yup.number().typeError('Take profit must be a number'),
});

export const addImmediateOrderAdminShema = Yup.object().shape({
  immediate_lot_volume: Yup.number()
    .typeError('Lot’s volume must be a number')
    .required('Please enter lot’s volume'),
});

export const editSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email').required('Email is required'),
  first_name: Yup.string().min(3, 'Validation length first name'),

  last_name: Yup.string().min(3, 'Validation length last name'),
  country: Yup.string(),
});

export const openPositionSchema = Yup.object().shape({
  open_price: Yup.number()
    .typeError('Open price must be a number')
    .required('Please enter open price'),
  stop_loss: Yup.number().typeError('Stop loss must be a number'),
  lot_volume: Yup.number()
    .typeError('Lot’s volume must be a number')
    .required('Please enter lot’s volume'),
  take_profit: Yup.number().typeError('Take profit must be a number'),
  swap: Yup.number().typeError('Swap must be a number'),
  comission: Yup.number().typeError('Comission must be a number'),
  profit: Yup.number().typeError('Profit must be a number'),
});

export const openOrderSchema = Yup.object().shape({
  trigger_price: Yup.number()
    .typeError('Open price must be a number')
    .required('Please enter open price'),
  stop_loss: Yup.number().typeError('Stop loss must be a number'),
  amount: Yup.number()
    .typeError('Lot’s volume must be a number')
    .required('Please enter lot’s volume'),
  take_profit: Yup.number().typeError('Take profit must be a number'),
});

export const addDepositSchema = Yup.object().shape({
  userId: Yup.number().typeError('User id must be a number').required('Please enter user id'),
  amount: Yup.number().typeError('Amount must be a number').required('Please enter amount'),
  toProfile: Yup.boolean(),
  paymentSystem: Yup.string().when('toProfile', {
    is: true,
    then: () => Yup.string().required('Payment System is required.'),
  }),
});

export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email').required('Email is required'),
});

export const manualDepositSchema = Yup.object().shape({
  crypto_wallet: Yup.string().required('Crypto wallet is required'),
  note: Yup.string().required('Notes is required'),
});
