import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  editReferralCommission,
  getReferralCommission,
} from 'store/redux-toolkit/commission/commission-thunks';

const ReferralProgramm = () => {
  const dispatch = useAppDispatch();
  const { referralCommission } = useAppSelector((state) => state.commissions);
  const [commission, setCommission] = useState('');
  const handleChangeCommission = (e: ChangeEvent<HTMLInputElement>) => {
    setCommission(e.target.value.replace(/[^\d.]/g, '').replace(/^([^.]*\.)|\./g, '$1'));
  };

  const handleSaveCommission = () => {
    dispatch(editReferralCommission({ commission }));
  };

  useEffect(() => {
    dispatch(getReferralCommission());
  }, []);

  useEffect(() => {
    if (referralCommission) setCommission(referralCommission.commission);
  }, [referralCommission]);

  return (
    <div className='block'>
      <p>Commission %</p>
      <div className='ref-commissions-form'>
        <div className='input input--mb-0 ref-commissions-form__input'>
          <div className='input-wrapper'>
            <input
              className='input-item'
              type='text'
              placeholder='Commission %'
              value={commission}
              onChange={handleChangeCommission}
            />
          </div>
          <p className='input-notification input-notification--big'>
            % of the commission that is charged to users from the trading of each of his referrals.
          </p>
        </div>
        <button
          type='button'
          className='button'
          disabled={Number(commission) === Number(referralCommission?.commission)}
          onClick={handleSaveCommission}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ReferralProgramm;
