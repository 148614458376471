import TabsContent from './TabsContent';

const LeverageTab = () => {
  return (
    <div className='block block--type2'>
      <TabsContent />
    </div>
  );
};

export default LeverageTab;
