import { FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCurrencyNetworks } from 'store/redux-toolkit/commission/commission-thunks';
import { ITabsContent } from '../types';
import TableRow from './TableRow';

const FeesTabsContent = () => {
  const [editableId, setEditableId] = useState<number | null>(null);
  const { currencyNetworks } = useAppSelector((state) => state.commissions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrencyNetworks({ is_crypto: 1 }));
  }, [dispatch]);

  return (
    <div className='table-block '>
      <div className='table-wrapper'>
        <div className='table table--commissions-deposit-crypto'>
          <div className='table-header'>
            <div className='tr'>
              <div className='td'>
                <div className='td-name'>
                  <p>Currency</p>
                </div>
              </div>
              <div className='td'>
                <div className='td-name'>
                  <p>Fee %</p>
                </div>
              </div>
              <div className='td'>
                <div className='td-name'>
                  <p>Fee Fixed USD</p>
                </div>
              </div>
              <div className='td td--right'>
                <div className='td-name'>
                  <p>Action</p>
                </div>
              </div>
            </div>
          </div>
          <div className='table-body'>
            {currencyNetworks?.map((item) => (
              <TableRow key={item.id} item={item} editableId={editableId} onEdit={setEditableId} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeesTabsContent;
