import { manualDepositSchema } from '../../../../form-validations/shemValidations';
import { Field, Form, Formik } from 'formik';
import Input from '../../../../ui/Formik/Input';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { addManualDeposit } from '../../../../store/redux-toolkit/transactions/transactions-thunks';
import { notificationContainer } from '../../../../ui/notificationContainer';

const initialValues = {
  crypto_wallet: '',
  note: '',
};

function ManualDeposit() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  if (!user) return <div>No user found.</div>;

  return (
    <div className='block'>
      <Formik
        initialValues={initialValues}
        validationSchema={manualDepositSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            addManualDeposit({
              apiParams: { ...values, admin_id: user.id },
              onFinally: () => {
                setSubmitting(false);
              },
              onSuccess: () => {
                notificationContainer(`Deposit successfully created`, 'success');
              },
            })
          );
        }}
        validateOnBlur
        enableReinitialize
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className='flex'>
                <Field
                  type='text'
                  name='crypto_wallet'
                  placeholder='Enter your crypto wallet'
                  label='Crypto wallet'
                  component={Input}
                />

                <Field
                  type='text'
                  name='note'
                  placeholder='Enter your notes'
                  label='Notes'
                  component={Input}
                />
              </div>
              <div className='form-submit form-submit--start'>
                <button disabled={isSubmitting} type='submit' className='button'>
                  Save
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ManualDeposit;
