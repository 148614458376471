import { FC } from 'react';

import { CopyIcon } from 'assets/svg-icon';
import { EWithdrawStatus } from 'enum/types';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IWithdrawalTransactionItem } from 'store/redux-toolkit/transactions/transactions-type';
import { notificationContainer } from 'ui/notificationContainer';
import { addZeros } from 'utils/integers';
import { shortNotation } from 'utils/strings';
import classNames from 'classnames';
import { moneyFormat } from 'utils/moneyFormat';

interface IWithdrawRowProps {
  item: IWithdrawalTransactionItem;
}

const WithdrawRow: FC<IWithdrawRowProps> = ({
  item: { id, created_at, currency, amount, fee_usd, tx_hash, tx_url, address, status },
}) => {
  const eStatus = EWithdrawStatus[status as keyof typeof EWithdrawStatus];

  const handleCopyAddress = () => {
    notificationContainer('Address copied', 'info');
  };
  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Date</p>
        <p>{moment(created_at).format('DD/MM/YYYY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Asset</p>
        <p>{currency.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Amount</p>
        <p>{moneyFormat(amount, 0, 6)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Fee</p>
        <p>{moneyFormat(fee_usd)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Destination</p>
        <p className='copy-button'>
          <span className='copy-button__text'>{address || '-'}</span>
          {address && (
            <CopyToClipboard text={address} onCopy={handleCopyAddress}>
              <button type='button'>
                <CopyIcon />
              </button>
            </CopyToClipboard>
          )}
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Tx Hash</p>
        <p>
          {tx_hash ? (
            <a href={tx_url} target='_blank' className='link' rel='noreferrer'>
              {shortNotation(tx_hash, 10, 10)}
            </a>
          ) : (
            '-'
          )}
        </p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Status</p>
        <div className={classNames('td-status td-status--select', status)}>{eStatus}</div>
        {/* {status === 'new' ? (
          <StatusSelect id={id} />
        ) : (
          <div className={classNames('td-status td-status--select new', status)}>
            <span className='td-status__name'>New</span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default WithdrawRow;
