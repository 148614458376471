import React, { FC } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { activateAdmin } from 'store/redux-toolkit/admins/admins-thunks';
import { LockIcon, TrashIcon, UnlockIcon, EditIcon } from 'assets/svg-icon';
import { IAdmin } from 'store/redux-toolkit/admins/admins-types';
import { ERoutes } from 'enum/types';
import { notificationContainer } from 'ui/notificationContainer';

interface IAdminTableRowProps {
  user: IAdmin;
  onDeleteClick?: (id: number) => void;
}

const AdminTableRow: FC<IAdminTableRowProps> = ({ user, onDeleteClick }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, email, first_name, is_active, last_name, created_at } = user;

  const handleActivateAdmin = (isActive: boolean) => {
    dispatch(
      activateAdmin({
        id,
        is_active: isActive,
        onSuccess: () => {
          notificationContainer(
            `Successfully ${isActive ? 'activated' : 'deactivated'}`,
            'success'
          );
        },
      })
    );
  };

  const handleEditClick = () => {
    navigate(`${ERoutes.admin_management}/${id}`);
  };

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>User ID</p>
        <p>
          <a href={`${ERoutes.admin_management}/${id}`} className='link'>
            {id}
          </a>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>User email</p>
        <p>
          <a href={`mailto: ${email}`} className='link'>
            {email}
          </a>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>First Name</p>
        <p>{first_name}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Last Name</p>
        <p>{last_name}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Data of create</p>
        <p>{moment(created_at).format('DD/MM/YY, HH:mm')}</p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Action</p>
        <div className={classNames('table-buttons flex-e', { blocked: !is_active })}>
          <button className='button--lock' onClick={() => handleActivateAdmin(false)}>
            <LockIcon />
          </button>
          <button className='button--unlock' onClick={() => handleActivateAdmin(true)}>
            <UnlockIcon />
          </button>
          <button disabled onClick={() => onDeleteClick?.(id)}>
            <TrashIcon />
          </button>
          <button onClick={handleEditClick}>
            <EditIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminTableRow;
