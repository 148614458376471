import { CopyIcon } from 'assets/svg-icon';
import { ERoutes } from 'enum/types';
import moment from 'moment';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { IWithdrawalTransactionItem } from 'store/redux-toolkit/transactions/transactions-type';
import StatusSelect from 'ui/StatusSelect';
import { notificationContainer } from 'ui/notificationContainer';
import { addZeros, addOneAndZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';
import { shortNotation } from 'utils/strings';

interface IRowProps {
  item: IWithdrawalTransactionItem;
}

const ApprovedTableRow: FC<IRowProps> = ({ item }) => {
  const {
    id,
    user_id,
    tx_hash,
    updated_at,
    currency,
    currency_network,
    amount,
    address,
    admin_id,
    fee_usd,
  } = item;

  const handleCopyAddress = () => {
    notificationContainer('Address copied', 'info');
  };

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>User ID</p>
        <p>
          <Link to={`${ERoutes.users}/${user_id}/profile`} className='link'>
            {addOneAndZeros(user_id)}
          </Link>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Admin ID</p>
        <p>
          <span className='link'>{addZeros(admin_id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Approvment date</p>
        <p>{moment(updated_at).format('MM/DD/YY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Asset</p>
        <p>{currency.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Network</p>
        <p>{currency_network.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Network fee</p>
        <p>{moneyFormat(0, 0, 6)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Amount</p>
        <p>{moneyFormat(amount, 0, 6)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Destination</p>
        <p className='copy-button'>
          <span className='copy-button__text'>{address || '-'}</span>
          {address && (
            <CopyToClipboard text={address} onCopy={handleCopyAddress}>
              <button type='button'>
                <CopyIcon />
              </button>
            </CopyToClipboard>
          )}
        </p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Tx Hash</p>
        <p className='tx-hash'>
          <a href='' className='link'>
            {tx_hash && shortNotation(tx_hash, 10, 10)}
          </a>
        </p>
      </div>
    </div>
  );
};

export default ApprovedTableRow;
