import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LoginForm } from '../../../components/Forms/LoginForm';
import { ILoginSubmitValue } from '../../../components/Forms/LoginForm/types';
import { login } from '../../../store/redux-toolkit/auth/auth-thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

const Login: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isAuth } = useAppSelector((state) => state.auth);
  const appTitle = APP_NAME || '';
  const fromPage = location.state?.from?.pathname || '/';

  const handleLoginSubmit = (values: ILoginSubmitValue) => {
    dispatch(login(values));
  };

  if (isAuth) return <Navigate to={fromPage} />;

  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Sign in`}</title>
      </Helmet>
      <section className='authorization-section'>
        <LoginForm loginSubmit={handleLoginSubmit} />
      </section>
    </>
  );
};

export default Login;
