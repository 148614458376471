import React, { FC, useState } from 'react';
import { IPositionItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import RowParent from './RowParent';
import RowChild from './RowChild';

interface IOpenPositionsRowProps {
  items: IPositionItem[];
  queryParams: ITradeRequest;
  onRefetch?: () => void;
  columnVisibility: {
    position: boolean;
    user_id: boolean;
    sub_admin: boolean;
    type: boolean;
    volume: boolean;
    sl: boolean;
    tp: boolean;
    open_price: boolean;
    market_price: boolean;
    gross_profit: boolean;
    net_profit: boolean;
    net_p_l: boolean;
    rollover: boolean;
    edit: boolean;
    delete: boolean;
    close: boolean;
    price_markup: boolean;
    opened_by: boolean;
  };
  trUserTradePositionsClassName?: string;
}

const OpenPositionsRow: FC<IOpenPositionsRowProps> = ({
  items,
  queryParams,
  onRefetch,
  columnVisibility,
  trUserTradePositionsClassName,
}) => {
  const [openList, setOpenList] = useState(true);

  const handleToggleList = () => {
    setOpenList(!openList);
  };
  return (
    <div className='tr-group'>
      <RowParent
        items={items}
        filterParams={queryParams}
        onToggleList={handleToggleList}
        columnVisibility={columnVisibility}
        trUserTradePositionsClassName={trUserTradePositionsClassName}
      />
      {items.map((el) => (
        <RowChild
          key={el.id}
          item={el}
          childs={items.length}
          openList={openList}
          queryParams={queryParams}
          onRefetch={onRefetch}
          columnVisibility={columnVisibility}
          trUserTradePositionsClassName={trUserTradePositionsClassName}
        />
      ))}
    </div>
  );
};

export default OpenPositionsRow;
