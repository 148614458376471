import classNames from 'classnames';
import { FieldProps } from 'formik';
import React, { FC, ReactNode } from 'react';
import SelectCalendar, { ISelectCalendarProps } from 'ui/SelectCalendar';

interface CustomInputProps extends ISelectCalendarProps {
  label?: string;
  required?: boolean;
}

const DatePicker: FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  required,
  ...props
}) => {
  const onChange = (date: Date) => {
    setFieldValue(field.name, date);
  };

  return (
    <div
      className={classNames('input', {
        'input--error': touched[field.name] && errors[field.name],
      })}
    >
      <p className='input__name'>
        {required && <span className='req'>*</span>}
        {label}
      </p>
      <div className='input-wrapper'>
        <SelectCalendar {...props} startDate={field.value} setStartDate={onChange} />
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification--error'>{String(errors[field.name])}</p>
      )}
    </div>
  );
};

export default DatePicker;
