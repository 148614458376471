import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAssets, getOpenOrders } from 'store/redux-toolkit/trades/trades-thunks';
import { ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import NoDataBlock from 'ui/NoDataBlock';
import OpenOrdersRow from './OpenOrdersRow';
import Pagination from 'ui/Pagination';
import UpdateTpSlOrder from 'components/modals/UpdateTpSlOrder';
import { useParams } from 'react-router-dom';
import { clearActionPopUp } from 'store/redux-toolkit/trades/trades-slice';
import UpdateExpiration from 'components/modals/UpdateExpiration';
import SelectPaginationBlock from '../../../SelectPaginationCount';
import { TPaginationCount } from '../../../SelectPaginationCount/types';

const columnVisibilityKey = 'pendingOrdersTableColumnVisibility';

const PendingOrdersTable: FC<ITradeRequest> = (params) => {
  const dispatch = useAppDispatch();
  const { openOrders, is_demo, actionPopUp } = useAppSelector((state) => state.trade);
  const [currentPage, setCurrentPage] = useState(1);
  const { userId } = useParams();
  const [perPage, setPerPage] = useState<TPaginationCount>(20);

  const paramsMemo = useMemo(() => params, []);

  const isDemoMemo = useMemo(() => is_demo, [is_demo]);

  const initialColumnVisibilityString = localStorage.getItem(columnVisibilityKey);
  const initialColumnVisibility = initialColumnVisibilityString
    ? JSON.parse(initialColumnVisibilityString)
    : {
        order: true,
        user_id: true,
        type: true,
        volume: true,
        order_price: true,
        sl: true,
        tp: true,
        market_price: true,
        expiration_data: true,
        edit: true,
        close_all: true,
        price_markup: false,
        opened_by: false,
      };

  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);

  const closeUpdateTpSlModal = () => {
    dispatch(clearActionPopUp());
  };

  const fetchPendingOrders = useCallback(() => {
    dispatch(
      getOpenOrders({
        ...paramsMemo,
        is_demo: isDemoMemo,
        current_page: currentPage,
        per_page: perPage,
        user_id: Number(userId),
      })
    );
  }, [currentPage, paramsMemo, isDemoMemo]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    dispatch(
      getOpenOrders({
        ...paramsMemo,
        is_demo: isDemoMemo,
        current_page: currentPage,
        per_page: _perPage,
        user_id: Number(userId),
      })
    );
  };

  useEffect(() => {
    fetchPendingOrders();
  }, [fetchPendingOrders]);

  useEffect(() => {
    dispatch(getAssets());
  }, []);

  const handleColumnVisibilityChange = (column: string) => {
    const updatedVisibility = { ...columnVisibility, [column]: !columnVisibility[column] };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem(columnVisibilityKey, JSON.stringify(updatedVisibility));
  };

  const columnCount = Object.values(columnVisibility).filter((visible) => visible).length;
  let trUserTradePendingClassName = '';

  if (columnCount > 11 && columnCount <= 13) {
    trUserTradePendingClassName = `tr-${columnCount}`;
  }

  return (
    <>
      <div className='account-trade-column-visibility'>
        <div>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.order}
              onChange={() => handleColumnVisibilityChange('order')}
            />
            Order
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.user_id}
              onChange={() => handleColumnVisibilityChange('user_id')}
            />
            User ID
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.type}
              onChange={() => handleColumnVisibilityChange('type')}
            />
            Type
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.volume}
              onChange={() => handleColumnVisibilityChange('volume')}
            />
            Volume
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.order_price}
              onChange={() => handleColumnVisibilityChange('order_price')}
            />
            Order Price
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.sl}
              onChange={() => handleColumnVisibilityChange('sl')}
            />
            SL
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.tp}
              onChange={() => handleColumnVisibilityChange('tp')}
            />
            TP
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.market_price}
              onChange={() => handleColumnVisibilityChange('market_price')}
            />
            Market Price
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.expiration_data}
              onChange={() => handleColumnVisibilityChange('expiration_data')}
            />
            Expiration Data
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.price_markup}
              onChange={() => handleColumnVisibilityChange('price_markup')}
            />
            Price Markup
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.opened_by}
              onChange={() => handleColumnVisibilityChange('opened_by')}
            />
            Opened By
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.edit}
              onChange={() => handleColumnVisibilityChange('edit')}
            />
            Edit
          </label>
          <label>
            <input
              type='checkbox'
              checked={columnVisibility.close_all}
              onChange={() => handleColumnVisibilityChange('close_all')}
            />
            Close All
          </label>
        </div>
      </div>

      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--user-trade-pending '>
            <div className='table-header'>
              <div className={`tr ${trUserTradePendingClassName}`}>
                {columnVisibility.order && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Order</p>
                    </div>
                  </div>
                )}
                {columnVisibility.user_id && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>User ID</p>
                    </div>
                  </div>
                )}
                {columnVisibility.type && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Type</p>
                    </div>
                  </div>
                )}
                {columnVisibility.volume && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Volume</p>
                    </div>
                  </div>
                )}
                {columnVisibility.order_price && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Order Price</p>
                    </div>
                  </div>
                )}
                {columnVisibility.sl && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>SL</p>
                    </div>
                  </div>
                )}
                {columnVisibility.tp && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>TP</p>
                    </div>
                  </div>
                )}
                {columnVisibility.market_price && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Market Price</p>
                    </div>
                  </div>
                )}
                {columnVisibility.expiration_data && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Expiration data</p>
                    </div>
                  </div>
                )}
                {columnVisibility.price_markup && (
                  <div className='td td'>
                    <div className='td-name'>Price Markup</div>
                  </div>
                )}
                {columnVisibility.opened_by && (
                  <div className='td td'>
                    <div className='td-name'>Opened By</div>
                  </div>
                )}
                {columnVisibility.edit && (
                  <div className='td'>
                    <div className='td-name'>Edit</div>
                  </div>
                )}
                {columnVisibility.close_all && (
                  <div className='td td--right'>
                    <div className='td-name'>Close All</div>
                  </div>
                )}
              </div>
            </div>
            <div className='table-body'>
              {openOrders?.orders && !!Object.keys(openOrders?.orders).length ? (
                Object.keys(openOrders.orders).map((item) => (
                  <OpenOrdersRow
                    key={item}
                    items={openOrders?.orders[item]}
                    queryParams={params}
                    onRefetch={fetchPendingOrders}
                    columnVisibility={columnVisibility}
                    trUserTradePendingClassName={trUserTradePendingClassName}
                  />
                ))
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='pagination-block'>
        <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
        {openOrders && openOrders.last_page > 1 && (
          <Pagination
            pageCount={openOrders.last_page}
            forcePage={openOrders.current_page}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
      <UpdateTpSlOrder
        openModal={!!actionPopUp?.update_tp_sl}
        setOpenModal={closeUpdateTpSlModal}
      />
      <UpdateExpiration
        openModal={!!actionPopUp?.update_expiration}
        setOpenModal={closeUpdateTpSlModal}
      />
    </>
  );
};

export default PendingOrdersTable;
