import { AxiosError, isAxiosError } from 'axios';
import { notificationContainer } from '../ui/notificationContainer';
import { capitalize } from './strings';
import { Errors } from './errorMessages';

// Helper functions
function getResponseErrorMsg(error: any) {
  if (!error.response?.data) {
    return null;
  }

  const { message, errors } = error.response.data;

  if (message && typeof message === 'string') {
    return message;
  }

  if (errors !== null) {
    if (errors.length) {
      return errors.join();
    }
    const firstError: any = Object.values(errors)[0];
    return firstError.join();
  }

  return null;
}

function formatMsgToHuman(message: string) {
  return capitalize(message).split('_').join(' ');
}

export const showError = (error: AxiosError) => {
  if (!isAxiosError(error)) {
    return;
  }
  let humanMsg = '';
  const responseMsg = getResponseErrorMsg(error);
  if (responseMsg) {
    const translatedMsg = Errors.responseMsg;
    humanMsg = translatedMsg || formatMsgToHuman(responseMsg);
  } else {
    humanMsg = Errors.something_went_wrong;
  }
  switch (error.response?.status) {
    case 429: // Too Many Requests
      notificationContainer(Errors.too_many_requests, 'error');
      break;
    case 500: // Internal Server Error
      notificationContainer(Errors.internal_server_error, 'error');
      break;
    default:
      notificationContainer(humanMsg, 'error');
  }
};
