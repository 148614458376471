import {
  getReferralCommission,
  editReferralCommission,
  getSwapComissions,
  editSwapComission,
  getCurrencyNetworks,
  editCurrencyNetworks,
  getMarkets,
  getLeverages,
  editMarket,
  addSwapComissionRequest,
} from './commission-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommissionsState,
  IReferralCommission,
  TSwapCommissions,
  IEditSwapComissionResponse,
  TGetCurrencyNetworksResponse,
  ICurrencyNetwork,
  TMarkets,
  IMarket,
  TAddSwapComission,
  TCancelSwapComission,
  TSwapAddComissions,
} from './commission-types';

const initialState: CommissionsState = {
  swapCommissions: null,
  markets: null,
  editLoading: false,
  referralCommission: null,
  currencyNetworks: null,
  leverages: null,
};

const idGenerator = () => Math.random().toString(16).slice(2);

export const commissionsSlice = createSlice({
  name: 'commissions',
  initialState,
  reducers: {
    addSwapComission: (state, { payload }: PayloadAction<TAddSwapComission>) => {
      if (!state.swapCommissions || !payload) return;
      const idx = state.swapCommissions.data.findIndex(({ id }) => id === payload.params.id);
      const copiedRow = { ...state.swapCommissions.data[idx] };
      copiedRow.id = idGenerator();
      if (!state.swapCommissions.data) return;
      state.swapCommissions.data = [
        ...state.swapCommissions.data.slice(0, idx + 1),
        copiedRow,
        ...state.swapCommissions.data.slice(idx + 1),
      ];
      payload?.onSuccess(copiedRow.id);
    },
    cancelAddComission: (state, { payload }: PayloadAction<TCancelSwapComission>) => {
      if (!state.swapCommissions || !payload) return;
      const idx = state.swapCommissions.data.findIndex(({ id }) => id === payload.params.id);
      state.swapCommissions.data.splice(idx, 1);
      payload.onSuccess?.();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getReferralCommission.fulfilled,
        (state, { payload }: PayloadAction<IReferralCommission>) => {
          state.referralCommission = payload;
        }
      )
      .addCase(
        editReferralCommission.fulfilled,
        (state, { payload }: PayloadAction<IReferralCommission>) => {
          state.referralCommission = payload;
        }
      )
      .addCase(
        getSwapComissions.fulfilled,
        (state, { payload }: PayloadAction<TSwapCommissions>) => {
          state.swapCommissions = payload;
        }
      )
      .addCase(editSwapComission.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(
        editSwapComission.fulfilled,
        (state, { payload }: PayloadAction<IEditSwapComissionResponse>) => {
          const { id, is_active } = payload;
          state.editLoading = false;
          if (!state.swapCommissions?.data) {
            return;
          }
          state.swapCommissions.data =
            state.swapCommissions.data?.map((item) =>
              item.id === id
                ? {
                    ...item,
                    ...payload,
                    is_active: is_active ? 1 : 0,
                  }
                : item
            ) || null;
        }
      )
      .addCase(editSwapComission.rejected, (state) => {
        console.log('editSwapComission.rejected');
        state.editLoading = false;
      })
      .addCase(
        addSwapComissionRequest.fulfilled,
        (state, { payload: { oldId, ...payload } }: PayloadAction<TSwapAddComissions>) => {
          if (!state.swapCommissions) return;
          const foundIdx = state.swapCommissions.data.findIndex(({ id }) => id === oldId);
          state.swapCommissions.data[foundIdx] = {
            ...state.swapCommissions.data[foundIdx],
            ...payload,
          };
        }
      )
      .addCase(
        getCurrencyNetworks.fulfilled,
        (state, { payload }: PayloadAction<TGetCurrencyNetworksResponse>) => {
          state.currencyNetworks = payload;
        }
      )
      .addCase(
        editCurrencyNetworks.fulfilled,
        (state, { payload }: PayloadAction<ICurrencyNetwork>) => {
          state.editLoading = false;
          state.currencyNetworks =
            state.currencyNetworks?.map((item) =>
              item.id === payload.id
                ? {
                    ...item,
                    ...payload,
                  }
                : item
            ) || null;
        }
      )
      .addCase(getMarkets.fulfilled, (state, { payload }: PayloadAction<TMarkets>) => {
        state.markets = payload;
      })
      .addCase(getLeverages.fulfilled, (state, { payload }: PayloadAction<number[]>) => {
        state.leverages = payload;
      })
      .addCase(editMarket.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(editMarket.fulfilled, (state, { payload }: PayloadAction<IMarket>) => {
        state.editLoading = false;
        state.markets =
          state.markets?.map((item) => (item.id === payload.id ? payload : item)) || null;
      })
      .addCase(editMarket.rejected, (state) => {
        state.editLoading = false;
      });
  },
});

export const { addSwapComission, cancelAddComission } = commissionsSlice.actions;

export default commissionsSlice.reducer;
