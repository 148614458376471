export const addZeros = (val: string | number | undefined, quantity: number = 6) => {
  if (val) {
    return String(val).padStart(quantity, '0');
  }
};

export const addOneAndZeros = (val: string | number | undefined, quantity: number = 6) => {
  if (val !== undefined && val !== null) {
    let result = String(val).padStart(quantity, '0');
    if (result.length > quantity) {
      result = '1' + result.slice(1);
    } else {
      result = '1' + result.slice(1 - quantity);
    }
    return result;
  }
  return val;
};

export const replaceLeadingOneWithZero = (id: string) => {
  return id.startsWith('1') ? '0' + id.slice(1) : id;
};
