import { FC } from 'react';

import { Form, Formik } from 'formik';
import { createAdminShema, editAdminShema } from 'form-validations/shemValidations';
import { ILoginFormProps, IFormValues } from '../types';
import FormAdminInner from './FormAdminInner';

const initialValues: IFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_repeat: '',
};

const FormAdmin: FC<ILoginFormProps> = ({ isEdit, onSubmit, values: defaultValues }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={isEdit ? editAdminShema : createAdminShema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        onSubmit({
          first_name: values?.first_name,
          last_name: values?.last_name,
          email: isEdit ? undefined : values?.email,
          password: values.password,
          password_repeat: values.password_repeat,
        });
      }}
      validateOnBlur
      // enableReinitialize
    >
      <Form>
        <FormAdminInner isEdit={isEdit} values={defaultValues} />
      </Form>
    </Formik>
  );
};

export default FormAdmin;
