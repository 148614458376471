import { FC, useRef, useState } from 'react';
import { ArrowDropIcon, EllipsisIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import classNames from 'classnames';

interface IClosedDropdownProps {
  label?: string;
  closeAll: () => void;
  closeProfitLoss: (val: boolean) => void;
}

const ClosedSelect: FC<IClosedDropdownProps> = ({ label, closeAll, closeProfitLoss }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const handleProfitPositions = () => {
    closeProfitLoss(true);
    setOpen(false);
  };
  const handleLossPositions = () => {
    closeProfitLoss(false);
    setOpen(false);
  };

  const handleCloseAll = () => {
    closeAll();
    setOpen(false);
  };

  return (
    <div
      ref={ref}
      className={classNames('select select--table select--red-current', { active: open })}
    >
      <button type='button' className='select__current' onClick={handleClick}>
        {label ? (
          <>
            <p>{label}</p>
            <span className='select__current-arrow'>
              <ArrowDropIcon />
            </span>
          </>
        ) : (
          <EllipsisIcon />
        )}
      </button>

      <div className='select__drop table-buttons-drop'>
        <div className='select__drop-scroll'>
          <div className='select__drop-item'>
            <ul>
              <li>
                <button type='button' onClick={handleCloseAll}>
                  All
                </button>
              </li>
              <li>
                <button type='button' onClick={handleProfitPositions}>
                  All profit
                </button>
              </li>
              <li>
                <button type='button' onClick={handleLossPositions}>
                  All losing
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClosedSelect;
