import {
  createTpSlOrder,
  createTpSlPosition,
  deleteTpSlOrder,
  deleteTpSlPosition,
  getAssets,
  getOpenOrders,
  getOpenPositions,
  getTradeHistory,
  updateOrderExpiration,
  recalculateComissionProfit,
  recalculatePrice,
  deletePosition,
  getTradeHistoryDropdown,
} from './trades-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ITradeHistoryResponse,
  TradeState,
  IOpenPositionsResponse,
  IAsset,
  IPositionItem,
  IActionPopUp,
  IOpenOrdersResponse,
  IOrderItem,
  IRecalcComissProfResponse,
} from './trades-types';

const initialState: TradeState = {
  tradeHistory: null,
  tradeHistoryDropdown: null,
  tradeHistoryDropdownLoading: false,
  openPositions: null,
  openOrders: null,
  assets: [],
  actionPopUp: null,
  is_demo: 0,
  deleteLoading: false,
};

const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    setIsDemo: (state, { payload }: PayloadAction<0 | 1>) => {
      state.is_demo = payload;
    },
    setActionPopUp: (state, { payload }: PayloadAction<IActionPopUp>) => {
      state.actionPopUp = payload;
    },
    clearActionPopUp: (state) => {
      state.actionPopUp = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTradeHistoryDropdown.pending, (state) => {
        state.tradeHistoryDropdownLoading = true;
      })
      .addCase(
        getTradeHistoryDropdown.fulfilled,
        (state, { payload }: PayloadAction<ITradeHistoryResponse>) => {
          if (state.tradeHistoryDropdown && payload.isScroll) {
            state.tradeHistoryDropdown.data = [...state.tradeHistoryDropdown.data, ...payload.data];
          } else {
            state.tradeHistoryDropdown = payload;
          }
          state.tradeHistoryDropdownLoading = false;
        }
      )
      .addCase(
        getTradeHistory.fulfilled,
        (state, { payload }: PayloadAction<ITradeHistoryResponse>) => {
          state.tradeHistory = payload;
        }
      )
      .addCase(
        getOpenPositions.fulfilled,
        (state, { payload }: PayloadAction<IOpenPositionsResponse>) => {
          state.openPositions = payload;
        }
      )
      .addCase(getAssets.fulfilled, (state, { payload }: PayloadAction<IAsset[]>) => {
        state.assets = payload;
      })
      .addCase(createTpSlPosition.fulfilled, (state, { payload }: PayloadAction<IPositionItem>) => {
        const needState = state.openPositions?.positions;
        if (needState) {
          Object.entries(needState).map(([key, value]) => {
            const newArr = value.map((el) => (el.id === payload.id ? { ...el, ...payload } : el));
            needState[key] = newArr;
          });
        }
        if (state.openPositions?.positions) {
          state.openPositions.positions = needState || {};
        }
        state.actionPopUp = null;
      })
      .addCase(deleteTpSlPosition.fulfilled, (state, { payload }: PayloadAction<IPositionItem>) => {
        const needState = state.openPositions?.positions;
        if (needState) {
          Object.entries(needState).map(([key, value]) => {
            const newArr = value.map((el) => (el.id === payload.id ? { ...el, ...payload } : el));
            needState[key] = newArr;
          });
        }
        if (state.openPositions?.positions) {
          state.openPositions.positions = needState || {};
        }
        state.actionPopUp = null;
      })
      .addCase(deleteTpSlOrder.fulfilled, (state, { payload }: PayloadAction<IOrderItem>) => {
        const needState = state.openOrders?.orders;
        if (needState) {
          Object.entries(needState).map(([key, value]) => {
            const newArr = value.map((el) => (el.id === payload.id ? { ...el, ...payload } : el));
            needState[key] = newArr;
          });
        }
        if (state.openOrders?.orders) {
          state.openOrders.orders = needState || {};
        }
        state.actionPopUp = null;
      })
      .addCase(
        getOpenOrders.fulfilled,
        (state, { payload }: PayloadAction<IOpenOrdersResponse>) => {
          state.openOrders = payload;
        }
      )
      .addCase(createTpSlOrder.fulfilled, (state, { payload }: PayloadAction<IOrderItem>) => {
        const needState = state.openOrders?.orders;
        if (needState) {
          Object.entries(needState).map(([key, value]) => {
            const newArr = value.map((el) => (el.id === payload.id ? { ...el, ...payload } : el));
            needState[key] = newArr;
          });
        }
        if (state.openOrders?.orders) {
          state.openOrders.orders = needState || {};
        }
        state.actionPopUp = null;
      })
      .addCase(updateOrderExpiration.fulfilled, (state, { payload }: PayloadAction<IOrderItem>) => {
        const needState = state.openOrders?.orders;
        if (needState) {
          Object.entries(needState).map(([key, value]) => {
            const newArr = value.map((el) => (el.id === payload.id ? { ...el, ...payload } : el));
            needState[key] = newArr;
          });
        }
        if (state.openOrders?.orders) {
          state.openOrders.orders = needState || {};
        }
        state.actionPopUp = null;
      })
      .addCase(
        recalculateComissionProfit.fulfilled,
        (state, { payload }: PayloadAction<IRecalcComissProfResponse>) => {
          const { position_id, trigger_price, commission, ...rest } = payload;
          const needState = state.openOrders?.orders;
          if (needState) {
            Object.entries(needState).map(([key, value]) => {
              const newArr = value.map((el) =>
                el.id === position_id
                  ? { ...el, open_price: trigger_price, fee: commission, ...rest }
                  : el
              );
              needState[key] = newArr;
            });
          }
        }
      )
      .addCase(
        recalculatePrice.fulfilled,
        (state, { payload }: PayloadAction<IRecalcComissProfResponse>) => {
          const { position_id, trigger_price, commission, ...rest } = payload;
          const needState = state.openOrders?.orders;
          if (needState) {
            Object.entries(needState).map(([key, value]) => {
              const newArr = value.map((el) =>
                el.id === position_id
                  ? { ...el, open_price: trigger_price, fee: commission, ...rest }
                  : el
              );
              needState[key] = newArr;
            });
          }
        }
      )
      .addCase(deletePosition.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deletePosition.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deletePosition.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});
export const { setIsDemo, setActionPopUp, clearActionPopUp } = tradeSlice.actions;
export default tradeSlice.reducer;
