import { FC } from 'react';
import Switch from 'ui/Switch';
import { capitalize } from 'utils/strings';
import { useAppSelector } from 'store/hooks';
import { IPermissionsList } from './types';

const PermissionsList: FC<IPermissionsList> = ({ getIsChecked, onChange }) => {
  const { allPermissionsList } = useAppSelector((state) => state.admins);

  return (
    <>
      <div className='title-block  title-block--with-mt'>
        <p className='title'>Permissions</p>
      </div>
      <div className='block block--type2'>
        <div className='table-block table-block--mt-0 '>
          <div className='table-wrapper'>
            <div className='table table--admin-permissions'>
              <div className='table-header'>
                <div className='tr'>
                  <div className='td'>
                    <div className='td-name'></div>
                  </div>
                  <div className='td td--right'>
                    <div className='td-name'>
                      <p>On/Off</p>
                    </div>
                  </div>
                </div>
              </div>

              {allPermissionsList?.map(({ id, name }) => (
                <div className='table-body' key={id}>
                  <div className='tr'>
                    <div className='td'>
                      <p className='td-hidden-name'>Permission</p>
                      <p>{capitalize(name)}</p>
                    </div>
                    <div className='td td--right'>
                      <p className='td-hidden-name'>On/Off</p>
                      <div className='table-buttons flex-e'>
                        <Switch checked={getIsChecked(id)} onChange={() => onChange(id)} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionsList;
