import { FC, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { EditIcon, ArrowDropIcon } from 'assets/svg-icon';
import { useSelect } from 'hooks/useSelect';
import { ILeverageTableRow } from './types';

const LeverageTableRow: FC<ILeverageTableRow> = ({ data, editId, setEditId, onEdit }) => {
  const { id, name, full_name, leverage, lot_size, fee_percent, fee } = data;
  const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
  const { leverages } = useAppSelector((state) => state.commissions);
  const { editLoading } = useAppSelector((state) => state.commissions);
  const [newLeverage, setNewLeverage] = useState<number | null>(leverage);
  const [newLotSize, setNewLotSize] = useState<number>(lot_size);
  const [newFeePercent, setNewFeePercent] = useState<number>(fee_percent);
  const [newFeeUsd, setNewFeeUsd] = useState<number>(fee);

  const handleSelect = (_leverage: number) => {
    setNewLeverage(_leverage);
    setOpen(false);
  };

  const handleEdit = (_leverage: number) => {
    if (editLoading) {
      return;
    }
    onEdit(id, _leverage, newLotSize, newFeePercent, newFeeUsd);
  };

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>Markets</p>
        <p>
          {full_name} ({name})
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Leverage</p>
        <div className={`select select--td-content select--td-leverage ${open ? 'active' : ''}`}>
          {editId === id ? (
            <>
              <button
                type='button'
                className='select__current'
                ref={triggerRef}
                onClick={editId === id ? toggleOpen : undefined}
              >
                1:{newLeverage}
                {editId === id && (
                  <span className='select__current-arrow'>
                    <ArrowDropIcon />
                  </span>
                )}
              </button>
              <div className='select__drop' ref={dropRef}>
                <div className='select__drop-scroll'>
                  <div className='select__drop-item'>
                    <ul>
                      {leverages?.map((item) => (
                        <li key={item}>
                          <button type='button' onClick={() => handleSelect(item)}>
                            1:{item}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>1:{leverage}</p>
          )}
        </div>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Lot Size</p>
        <div className={`select select--td-content select--td-leverage ${open ? 'active' : ''}`}>
          {editId === id ? (
            <div className='flex align-items-center'>
              <span>1:</span>
              <input
                className='input-item input-item--width-75'
                defaultValue={lot_size}
                type='number'
                onChange={(e) => {
                  setNewLotSize(parseInt(e.target.value));
                }}
              />
            </div>
          ) : (
            <p>1:{lot_size}</p>
          )}
        </div>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Fee %</p>
        <div className={`select select--td-content select--td-leverage ${open ? 'active' : ''}`}>
          {editId === id ? (
            <div className='flex align-items-center'>
              <input
                className='input-item input-item--width-75'
                defaultValue={fee_percent}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 0) {
                    setNewFeePercent(value);
                  }
                }}
              />
            </div>
          ) : (
            <p>{fee_percent}</p>
          )}
        </div>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Fee</p>
        <div className={`select select--td-content select--td-leverage ${open ? 'active' : ''}`}>
          {editId === id ? (
            <div className='flex align-items-center'>
              <input
                className='input-item input-item--width-75'
                defaultValue={fee}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 0) {
                    setNewFeeUsd(value);
                  }
                }}
              />
            </div>
          ) : (
            <p>{fee}</p>
          )}
        </div>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Action</p>
        <div className='table-buttons flex-e'>
          {editId === id ? (
            <>
              <button type='button' onClick={() => setEditId(null)}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M0.800049 0.799805L15.2 15.1998M0.800049 15.1998L15.2 0.799805'
                    stroke='#F44336'
                  />
                </svg>
              </button>
              <button
                type='button'
                disabled={editLoading}
                onClick={newLeverage ? () => handleEdit(newLeverage) : undefined}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.19995 8.39961L6.59995 13.7996L16.8 3.59961'
                    stroke='#00DA83'
                    strokeLinecap='square'
                  />
                </svg>
              </button>
            </>
          ) : (
            <button type='button' onClick={() => setEditId(id)}>
              <EditIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeverageTableRow;
