import { LockIcon, TrashIcon, UnlockIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { ERoutes } from 'enum/types';
import moment from 'moment';
import { FC } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { setBlockedUser } from 'store/redux-toolkit/users/users-thunks';
import { IUser } from 'store/redux-toolkit/users/users-types';
import { addOneAndZeros } from 'utils/integers';

export interface IUserRowProps {
  user: IUser;
  handleDelete: (id: number) => void;
  columnVisibility: {
    id: boolean;
    username: boolean;
    email: boolean;
    phone: boolean;
    country: boolean;
    created_at: boolean;
    action: boolean;
    date_of_birth: boolean;
    email_confirmed: boolean;
    affiliate_id: boolean;
    source: boolean;
  };
}

const UserRow: FC<IUserRowProps> = ({ user, handleDelete, columnVisibility }) => {
  const { id, user_data, username, email, created_at, blocked, is_deleted, email_confirmed } = user;
  const dispatch = useAppDispatch();
  const handleBlock = () => {
    dispatch(setBlockedUser({ id, blocked: '1' }));
  };

  const handleUnBlock = () => {
    dispatch(setBlockedUser({ id, blocked: '0' }));
  };

  const handleClickDelete = () => {
    handleDelete(id);
  };

  return (
    <div className={`tr${is_deleted ? ' is-deleted' : ''}`}>
      {columnVisibility.id && (
        <div className='td'>
          <p className='td-hidden-name'>User ID</p>
          <p>
            <Link to={`${ERoutes.users}/${id}/profile`} className='link'>
              {addOneAndZeros(id)}
            </Link>
          </p>
        </div>
      )}
      {columnVisibility.username && (
        <div className='td'>
          <p className='td-hidden-name'>Full Name</p>
          <p>{username}</p>
        </div>
      )}
      {columnVisibility.email && (
        <div className='td'>
          <p className='td-hidden-name'>User Email</p>
          <p>
            <a href={`mailto:${email}`} className='link'>
              {email}
            </a>
          </p>
        </div>
      )}
      {columnVisibility.phone && (
        <div className='td'>
          <p className='td-hidden-name'>Phone number</p>
          <p>{formatPhoneNumberIntl(user_data.phone) || '-'}</p>
        </div>
      )}
      {columnVisibility.country && (
        <div className='td'>
          <p className='td-hidden-name'>Country</p>
          <p>{user_data.country || '-'}</p>
        </div>
      )}
      {columnVisibility.created_at && (
        <div className='td'>
          <p className='td-hidden-name'>Data of create</p>
          <p>{moment(created_at * 1000).format('DD/MM/YY, hh:mm')}</p>
        </div>
      )}
      {columnVisibility.date_of_birth && (
        <div className='td'>
          <p className='td-hidden-name'>Data of birth</p>
          {user_data.date_of_birth ? (
            <p>{moment(user_data.date_of_birth).format('DD/MM/YYYY')}</p>
          ) : (
            <p>-</p>
          )}
        </div>
      )}
      {columnVisibility.email_confirmed && (
        <div className='td'>
          <p className='td-hidden-name'>Verified</p>
          <p>{email_confirmed || '-'}</p>
        </div>
      )}
      {columnVisibility.affiliate_id && (
        <div className='td'>
          <p className='td-hidden-name'>Affiliate Id</p>
          <p>{user_data.affiliate_id || '-'}</p>
        </div>
      )}
      {columnVisibility.source && (
        <div className='td'>
          <p className='td-hidden-name'>Source</p>
          <p>{user_data.source || '-'}</p>
        </div>
      )}
      {columnVisibility.action && (
        <div className='td td--right'>
          <p className='td-hidden-name'>Action</p>
          <div className={classNames('table-buttons flex-e', { blocked: blocked === '1' })}>
            <button type='button' className='button--lock' onClick={handleBlock}>
              <LockIcon />
            </button>
            <button type='button' className='button--unlock' onClick={handleUnBlock}>
              <UnlockIcon />
            </button>
            <button type='button' onClick={handleClickDelete}>
              <TrashIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserRow;
