import React, { FC, useState } from 'react';

import classNames from 'classnames';
import { ERoutes, ETradeHistopyStatus } from 'enum/types';
import moment from 'moment';
import { IPositionItem } from 'store/redux-toolkit/trades/trades-types';
import { addZeros, addOneAndZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';
import { trimLastZeros } from 'utils/number';
import { Link } from 'react-router-dom';
import { EditIcon } from '../../../../assets/svg-icon';
import { createPortal } from 'react-dom';
import { OpenPositionEdit } from '../../../../ui/modals/OpenPositionEdit';

interface IProps {
  item: IPositionItem;
  onRefetch: () => void;
}

const TradeHistoryRow: FC<IProps> = ({ item, onRefetch }) => {
  const {
    id,
    asset,
    asset: {
      market: { name: marketName },
    },

    transaction_type,
    amount,
    created_at,
    updated_at,
    open_price,
    close_price,
    profit,

    swap,
    fee,
    status,
    user_id,
  } = item;
  const [isOpen, setIsOpen] = useState(false);

  const isCrypto = marketName === 'CRT';

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>Symbol</p>
        <div className='td-symbol'>
          <p className='td-symbol__name'>
            {asset.code} <span>{asset.market.name}</span>
          </p>
          <p className='td-symbol-details'>{asset.name} to American Dollar</p>
        </div>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>User ID</p>
        <p>
          <Link to={`${ERoutes.users}/${user_id}/profile`} className='link'>
            {addOneAndZeros(user_id)}
          </Link>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Position ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Type</p>
        <p>{transaction_type}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Volume</p>
        <p>{amount}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Open time</p>
        <p>{moment(created_at).format('DD/MM/YY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Open price</p>
        <p>{isCrypto ? open_price : trimLastZeros(open_price.toFixed(2))}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Close time</p>
        <p>{moment(updated_at).format('DD/MM/YY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Close price</p>
        <p>{close_price}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Profit</p>
        <p className={classNames({ green: profit > 0, red: profit < 0 })}>
          {Number(profit.toFixed(3).slice(0, -1)) > 0 && '+'}
          {moneyFormat(profit, 2, 2)}
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>NET P/L</p>
        <p className={classNames({ red: profit - swap < 0, green: profit - swap > 0 })}>
          {Number(profit.toFixed(3).slice(0, -1)) - swap > 0 && '+'}
          {moneyFormat(profit - swap, 2, 2)}
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Swap</p>
        <p>{moneyFormat(swap)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Commission</p>
        <p>{fee}</p>
      </div>
      <div className='td '>
        <p className='td-hidden-name'>Edit</p>
        <div className='table-buttons'>
          <button
            type='button'
            className='table-buttons__btn table-buttons__btn--color-blue'
            onClick={() => setIsOpen(true)}
          >
            <EditIcon />
          </button>
        </div>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Status</p>
        <div className='td-status td-status--in-process'>
          <span className='td-status__name'>
            {ETradeHistopyStatus[status as keyof typeof ETradeHistopyStatus]}
          </span>
        </div>
      </div>
      {isOpen &&
        createPortal(
          <OpenPositionEdit
            onClose={() => setIsOpen(false)}
            item={item}
            isClosed
            onRefetch={onRefetch}
            isUserPage
          />,
          document.getElementsByTagName('main')[0]
        )}
    </div>
  );
};

export default TradeHistoryRow;
