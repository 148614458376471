import { FC } from 'react';
import { Formik, Form, Field } from 'formik';
import { ILoginForm, ILoginSubmitValue } from './types';
import ErrorMsg from '../../ErrorMsg';
import { loginSchema } from '../../../form-validations/shemValidations';
import Input from 'ui/Formik/Input';
import PasswordField from 'ui/Formik/PasswordField';
import loginLogo from '../../../assets/images/login-icon.svg';
import { MailIcon } from 'assets/svg-icon';
import CheckBox from 'ui/Formik/Checkbox';

const initialValues = {
  email: '',
  password: '',
  captcha: '00000000',
  remember: false,
};

export const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={(values, { setSubmitting }) => {
        loginSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, values }) => {
        return (
          <Form className='authorization-form'>
            <div className='authorization-form__logo'>
              <img src={loginLogo} alt='' />
            </div>
            <p className='form-title'>Sign in Backoffice</p>
            <div className='authorization-form__body'>
              <Field
                type='email'
                name='email'
                placeholder='Email'
                label='Email address'
                component={Input}
                iconLeft={<MailIcon />}
              />

              <Field
                className='form__input'
                label='Confirm password'
                name='password'
                placeholder='Password'
                component={PasswordField}
              />

              <Field text='Remember me' name='remember' component={CheckBox} />
            </div>
            <div className='form-submit'>
              <button
                disabled={!(isValid && values.email && values.password) || isSubmitting}
                type='submit'
                className='button button--full-width'
              >
                Confirm
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
