import { useEffect, FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';
import Input from 'ui/Formik/Input';
import { ERoutes } from 'enum/types';
import { createPortal } from 'react-dom';
import { CloseIcon, SaveIcon, Trash2Icon } from 'assets/svg-icon';
import { numberOrNullToString } from 'utils/strings';
import { notificationContainer } from 'ui/notificationContainer';
import { openOrderSchema } from 'form-validations/shemValidations';
import { updatePendingOrder, cancelOrder } from 'store/redux-toolkit/trades/trades-thunks';
import { TTransactionType } from 'store/redux-toolkit/trades/trades-types';
import { ConfirmPopup } from 'ui/ConfirmPopup';
import { TypeSelect } from './TypeSelect';
import { IOpenOrderEdit, IValues } from './types';

export const OpenOrderEdit: FC<IOpenOrderEdit> = ({ item, onClose, onRefetch, isUserPage }) => {
  const dispatch = useAppDispatch();
  const { is_demo } = useAppSelector((state) => state.trade);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const { id, user_id, trigger_price, amount, transaction_type, take_profit, stop_loss, asset } =
    item;

  const initialValues: IValues = {
    trigger_price: numberOrNullToString(trigger_price),
    amount: numberOrNullToString(amount),
    transaction_type: transaction_type,
    stop_loss: numberOrNullToString(stop_loss),
    take_profit: numberOrNullToString(take_profit),
  };

  useEffect(() => {
    document.body.classList.add('popup-open');
    return () => {
      document.body.classList.remove('popup-open');
    };
  }, []);

  const handleCancelOrder = () => {
    dispatch(
      cancelOrder({
        apiParams: { order_id: id, is_demo: Boolean(is_demo) },
        onSuccess: () => {
          notificationContainer('Order successfully canceled', 'success');
          onRefetch?.();
          setCancelConfirm(false);
          onClose();
        },
      })
    );
  };

  return (
    <>
      <div className='popup-window '>
        <div className='popup-window__inside'>
          <div className='popup popup--width-880'>
            <button type='button' className='popup__close' onClick={onClose}>
              <CloseIcon />
            </button>
            <div className='popup-header'>
              {isUserPage ? (
                <div className='popup-header__title-block'>
                  <p className='popup-header__title'>
                    {asset.code.toUpperCase()} {asset.market.name.toUpperCase()}{' '}
                    {asset.name.slice(0, asset.name.indexOf('/'))} to American Dollar
                  </p>
                </div>
              ) : (
                <div className='popup-header__title-block'>
                  <p className='popup-header__title'>Order {id}</p>
                  <p className='popup-header__title'>
                    User ID <Link to={`${ERoutes.users}/${user_id}/profile`}>{user_id}</Link>{' '}
                  </p>
                </div>
              )}
            </div>
            <div className='popup-body'>
              <Formik
                initialValues={initialValues}
                validationSchema={openOrderSchema}
                onSubmit={(values: IValues, { setSubmitting }) => {
                  setSubmitting(true);
                  dispatch(
                    updatePendingOrder({
                      apiParams: {
                        order_id: id,
                        is_demo: Boolean(is_demo),
                        transaction_type: values.transaction_type
                          ? (values.transaction_type as TTransactionType)
                          : undefined,
                        amount: values.amount ? Number(values.amount) : undefined,
                        trigger_price: values.trigger_price
                          ? Number(values.trigger_price)
                          : undefined,
                        take_profit: values.take_profit ? Number(values.take_profit) : undefined,
                        stop_loss: values.stop_loss ? Number(values.stop_loss) : undefined,
                      },
                      onSuccess: () => {
                        notificationContainer('Order successfully updated', 'success');
                        onClose();
                        onRefetch?.();
                      },
                      onFinally: () => {
                        setSubmitting(false);
                      },
                    })
                  );
                }}
              >
                {({ values, setFieldValue, isSubmitting }) => {
                  return (
                    <Form>
                      <div className='popup-order-box'>
                        <div className='inputs-row inputs-row--order'>
                          <div className='input'>
                            <p className='input-name'>Asset:</p>
                            <p className='value value--fs-14 value--fs-14--fw-500'>
                              {asset?.code.toUpperCase()}
                            </p>
                          </div>
                          <TypeSelect
                            value={values.transaction_type || ''}
                            onSelect={(_value) => setFieldValue('transaction_type', _value)}
                          />
                        </div>
                        <div className=' inputs-row  inputs-row--order'>
                          <Field
                            name='trigger_price'
                            label='Open Price:'
                            placeholder='10000001.000000'
                            component={Input}
                            required
                          />
                          <Field
                            name='stop_loss'
                            label='Stop Loss:'
                            placeholder='0.00000'
                            component={Input}
                          />
                        </div>
                        <div className=' inputs-row  inputs-row--order'>
                          <Field
                            name='amount'
                            label='Lot’s Volume:'
                            placeholder='10'
                            component={Input}
                            required
                          />
                          <Field
                            name='take_profit'
                            label='Take Profit:'
                            placeholder='10'
                            component={Input}
                          />
                        </div>
                      </div>
                      <div className='popup-submit popup-submit--sb popup-submit--720-wrap popup-submit--mob-column'>
                        <div className='popup-submit__group'>
                          <button
                            type='button'
                            className='button button--type4'
                            onClick={() => setCancelConfirm(true)}
                          >
                            <span className='btn-icon'>
                              <Trash2Icon />
                            </span>
                            Cancel order
                          </button>
                        </div>
                        <button type='submit' className='button' disabled={isSubmitting}>
                          <span className='btn-icon'>
                            <SaveIcon />
                          </span>
                          Save
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {cancelConfirm &&
        createPortal(
          <ConfirmPopup
            body='Are you sure you want to cancel it?'
            onClose={() => setCancelConfirm(false)}
            onConfirm={handleCancelOrder}
          />,
          document.getElementsByTagName('main')[0]
        )}
    </>
  );
};
