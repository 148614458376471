import DepositWithdrawal from 'components/Settings/DepositWithdrawal';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { APP_NAME } from 'services/constants/env';
import usePermissions from 'hooks/usePermissions';

const DepositWithdrawalPage = () => {
  const appTitle = APP_NAME || '';
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission('commissions') ? (
        <>
          <Helmet>
            <title>{`${appTitle} | Deposit/Withdrawal`}</title>
          </Helmet>
          <DepositWithdrawal />
        </>
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default DepositWithdrawalPage;
