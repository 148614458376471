import { ERoutes } from 'enum/types';
import moment from 'moment';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ITransferTransactionsItem } from 'store/redux-toolkit/transactions/transactions-type';
import { addZeros, addOneAndZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';

const TransferRow: FC<ITransferTransactionsItem> = ({ id, user_id, created_at, amount }) => {
  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>User ID</p>
        <p>
          <Link to={`${ERoutes.users}/${user_id}/profile`} className='link'>
            {addOneAndZeros(user_id)}
          </Link>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Date and time</p>
        <p>{moment(created_at).format('MM/DD/YY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Asset</p>
        <p>USD</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Amount</p>
        <p>{moneyFormat(amount, 0, 6)}</p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Status</p>
        <div className='td-status '>
          <span className='td-status__name'>Successfull</span>
        </div>
      </div>
    </div>
  );
};

export default TransferRow;
