import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import NoDataBlock from 'ui/NoDataBlock';
import TransferRow from './TransferRow';
import { getTransferTransactions } from 'store/redux-toolkit/transactions/transactions-thunks';
import { ITransactionTabProps } from './types';

const TransferTab: FC<ITransactionTabProps> = ({ params, setParams }) => {
  const dispatch = useAppDispatch();
  const { transferTransactions } = useAppSelector((state) => state.transactions);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      getTransferTransactions({
        current_page: currentPage,
        ...params,
      })
    );
  }, [currentPage, params]);

  useEffect(() => {
    setCurrentPage(1);
  }, [transferTransactions?.last_page]);

  return (
    <div className='block'>
      <div className='table-block'>
        <div className='table-wrapper'>
          <div className='table table--transactions-transfer'>
            <div className='table-header'>
              <div className='tr'>
                <div className='td'>
                  <div className='td-name'>
                    <p>ID</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>User ID</p>
                  </div>
                </div>

                <div className='td'>
                  <div className='td-name'>
                    <p>Date and time</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Asset</p>
                  </div>
                </div>
                <div className='td'>
                  <div className='td-name'>
                    <p>Amount</p>
                  </div>
                </div>
                <div className='td td--right'>
                  <div className='td-name'>
                    <div className='select select--table'>Status</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-body'>
              {transferTransactions?.data.length ? (
                transferTransactions.data.map((item) => <TransferRow key={item.id} {...item} />)
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferTab;
