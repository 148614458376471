import React from 'react';
import Trades from 'components/Trades';
import { Helmet } from 'react-helmet';
import { Outlet, Navigate } from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';
import { APP_NAME } from 'services/constants/env';

const TradesPage = () => {
  const appTitle = APP_NAME || '';
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission('trades') ? (
        <>
          <Helmet>
            <title>{`${appTitle} | Trades`}</title>
          </Helmet>
          <Trades />
        </>
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default TradesPage;
