import React, { FC, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { ArrowDropIcon, CloseIcon, EllipsisIcon } from 'assets/svg-icon';
import { IOrderItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import RowChild from './RowChild';
import { ETradeType } from 'enum/types';
import classNames from 'classnames';
import { add, subtract } from 'utils/number';

interface IOpenOrdersRowProps {
  items: IOrderItem[];
  queryParams: ITradeRequest;
  onRefetch?: () => void;
}

const OpenOrdersRow: FC<IOpenOrdersRowProps> = ({ items, queryParams, onRefetch }) => {
  const { asset } = items[0];
  const [show, setShow] = useState(true);
  const { is_demo } = useAppSelector((state) => state.trade);

  const value = items.reduce((total: number, order: IOrderItem) => {
    order.transaction_type === ETradeType.buy
      ? (total = add(total, order.amount))
      : (total = subtract(total, order.amount));
    return total;
  }, 0);

  const handleShowChild = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className='tr-group'>
      <div className='tr'>
        <div className='td'>
          <p className='td-hidden-name'>Order</p>
          <div className='td-flex td-flex--with-tr-drop'>
            <button type='button' className='td-flex__drop-btn' onClick={handleShowChild}>
              <ArrowDropIcon />
            </button>
            <div className='td-symbol'>
              <p className='td-symbol__name'>
                {asset.code} <span>{asset.market.name}</span>{' '}
                <span className='td-symbol__count'>{items.length}</span>
              </p>
              <p className='td-symbol-details'>{asset.name} to American Dollar</p>
            </div>
          </div>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>User ID</p>
          <p className='td-value td-value--fw-500 '></p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>Email</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Sub-Admin</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>Type</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Volume</p>
          <p className='td-value td-value--fw-500'>{value}</p>
        </div>
        <div className='td  '>
          <p className='td-hidden-name'>Order Price</p>
          <p className='td-value td-value--fw-500'>34294</p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>SL</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>TP</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>Market price</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>Expiration date</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>Actions</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
      </div>
      <div className={classNames('tr-drop-list', { active: show })}>
        {items.map((el) => (
          <RowChild key={el.id} item={el} queryParams={queryParams} onRefetch={onRefetch} />
        ))}
      </div>
    </div>
  );
};

export default OpenOrdersRow;
