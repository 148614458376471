import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { persistor, store } from './store/store';
import { App } from './App';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <ToastContainer />
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);
