import { notificationContainer } from 'ui/notificationContainer';
import requestService from 'services/interceptor/request-service';
import { IGetSettingsRequest, IToggleSettingRequest } from './settings-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { endpoint } from 'services/endpoint';
import { showError } from 'utils/showError';

export const getSettings = createAsyncThunk(
  'settings/get-settings',
  async (params: IGetSettingsRequest) => {
    try {
      const response = await requestService.get(endpoint.settings.GET_SETTINGS, params);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getSettingBySlug = createAsyncThunk(
  'settings/get-setting-by-slug',
  async (params: IGetSettingsRequest) => {
    try {
      const response = await requestService.get(endpoint.settings.GET_SETTING_BY_SLUG, params);
      // console.log('####', response.data);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const toggleSetting = createAsyncThunk(
  'settings/toggle-setting',
  async (params: IToggleSettingRequest) => {
    try {
      const response = await requestService.put(endpoint.settings.TOGGLE_SETTING, params);
      notificationContainer(`${params.name} setting has been changed`, 'success');
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
