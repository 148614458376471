import { FC, useState } from 'react';
import { moneyFormat } from 'utils/moneyFormat';
import { IChartCard } from './types';

const ChartCard: FC<IChartCard> = ({ title, amount, children }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className='block block--type2 block--radius-5 '>
      <div className='dashboard-item'>
        <div className='dashboard-header'>
          <div className='dashboard-header-info'>
            <p className='dashboard-header-info__title'>{title}</p>
            <p className='dashboard-header-info__value'>Sum: ${moneyFormat(amount)} USD</p>
          </div>
          <button className='dashboard-header__btn' onClick={toggleShow}>
            {show ? 'Hide' : 'Show'}
          </button>
        </div>
        <div className={`dashboard-drop ${show ? 'active' : ''}`}>
          <div className='dashboard-chart'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ChartCard;
