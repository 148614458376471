import React, { FC, useState } from 'react';
import { IPositionItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import RowParent from './RowParent';
import RowChild from './RowChild';

interface IOpenPositionsRowProps {
  items: IPositionItem[];
  queryParams: ITradeRequest;
  onRefetch?: () => void;
}

const OpenPositionsRow: FC<IOpenPositionsRowProps> = ({ items, queryParams, onRefetch }) => {
  const [openList, setOpenList] = useState(false);

  const handleToggleList = () => {
    setOpenList(!openList);
  };
  return (
    <div className='tr-group'>
      <RowParent items={items} filterParams={queryParams} />
      {items.map((el) => (
        <RowChild
          key={el.id}
          item={el}
          childs={items.length}
          openList={openList}
          queryParams={queryParams}
          onRefetch={onRefetch}
        />
      ))}
    </div>
  );
};

export default OpenPositionsRow;
