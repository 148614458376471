import React, { useEffect } from 'react';

import { ERoutes } from 'enum/types';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userInitState } from 'store/redux-toolkit/users/users-slice';
import { getUserSettings } from 'store/redux-toolkit/users/users-thunks';

const Account = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.users);

  useEffect(() => {
    if (userId) {
      dispatch(getUserSettings({ id: userId }));
    }
    return () => {
      dispatch(userInitState());
    };
  }, [userId]);
  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>
            {(user?.data.first_name &&
              user?.data.last_name &&
              `${user?.data.first_name} ${user?.data.last_name}`) ||
              user?.email}
          </p>
        </div>
      </div>
      <div className='block-tabs'>
        <NavLink to={ERoutes.profile} className='block-tabs__item'>
          Profile
        </NavLink>
        <NavLink to={ERoutes.trade_account} className='block-tabs__item'>
          Trade account
        </NavLink>
        <NavLink to={ERoutes.orders} className='block-tabs__item'>
          Trade
        </NavLink>
        <NavLink to={ERoutes.trans_history} className='block-tabs__item'>
          Transactions history
        </NavLink>
        <NavLink to={ERoutes.referrals} className='block-tabs__item'>
          Referral
        </NavLink>
      </div>
      <div className='block'>
        <Outlet />
      </div>
    </div>
  );
};

export default Account;
