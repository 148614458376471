import { CircleUploadIcon, SearchIcon } from 'assets/svg-icon';
import ConfirmDeleteUser from 'components/modals/ConfirmDeleteUser';
import useInput from 'hooks/useInput';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteUser, getUsers } from 'store/redux-toolkit/users/users-thunks';
import CustomInput from 'ui/CustomInput';
import NoDataBlock from 'ui/NoDataBlock';
import Pagination from 'ui/Pagination';
import UserRow from './UserRow';
import SelectPaginationBlock from '../SelectPaginationCount';
import { TPaginationCount } from '../SelectPaginationCount/types';
import SelectCalendar from 'ui/SelectCalendar';
import moment from 'moment';

const columnVisibilityKey = 'userTableColumnVisibility';

const Users = () => {
  const dispatch = useAppDispatch();
  const { usersList } = useAppSelector((state) => state.users);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState<TPaginationCount>(20);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const initialColumnVisibilityString = localStorage.getItem(columnVisibilityKey);
  const initialColumnVisibility = initialColumnVisibilityString
    ? JSON.parse(initialColumnVisibilityString)
    : {
        id: true,
        username: true,
        email: true,
        phone: true,
        country: true,
        created_at: true,
        action: true,
        date_of_birth: false,
        email_confirmed: false,
        affiliate_id: false,
        source: false,
      };

  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteUser = (id: number) => {
    setSelectedId(id);
    setOpenDeleteModal(true);
  };

  if (usersList && usersList?.last_page < currentPage) {
    setCurrentPage(usersList?.last_page);
  }

  const confirmDelete = () => {
    if (selectedId) {
      dispatch(
        deleteUser({
          id: selectedId,
          onSuccess: () => dispatch(getUsers({ current_page: currentPage, per_page: perPage })),
        })
      );
      setOpenDeleteModal(false);
    }
  };

  const handleSearch = () => {
    if (searchValue || startDate || endDate) {
      dispatch(
        getUsers({
          current_page: 1,
          per_page: perPage,
          search_value: searchValue,
          date_from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
          date_to: endDate
            ? moment(endDate).format('YYYY-MM-DD')
            : startDate
            ? moment().format('YYYY-MM-DD')
            : undefined,
        })
      );
    } else {
      handleClearSearch();
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setStartDate(undefined);
    setEndDate(undefined);
    dispatch(getUsers({ current_page: 1, per_page: perPage }));
  };

  useEffect(() => {
    dispatch(getUsers({ current_page: currentPage, per_page: perPage }));
  }, [currentPage]);

  useEffect(() => {
    const deb = setTimeout(() => {
      if (searchValue) {
        dispatch(getUsers({ current_page: 1, per_page: perPage, search_value: searchValue }));
      } else {
        handleClearSearch();
      }
    }, 700);

    return () => {
      clearTimeout(deb);
    };
  }, [searchValue]);

  const onPaginationSelect = (_perPage: TPaginationCount) => {
    setPerPage(_perPage);
    dispatch(getUsers({ current_page: 1, per_page: _perPage }));
  };

  const handleColumnVisibilityChange = (column: string) => {
    const updatedVisibility = { ...columnVisibility, [column]: !columnVisibility[column] };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem(columnVisibilityKey, JSON.stringify(updatedVisibility));
  };

  const columnCount = Object.values(columnVisibility).filter((visible) => visible).length;
  let tableUserManagementClassName = 'table--user-management';

  if (columnCount > 7 && columnCount <= 11) {
    tableUserManagementClassName += `-${columnCount}`;
  }

  return (
    <div className='main-content'>
      <div className='title-block-wrap'>
        <div className='title-block'>
          <p className='title'>Clients</p>
        </div>
      </div>
      <div className='table-filter table-filter--flex-type'>
        <button type='button' className='button button--icon' onClick={handleSearch}>
          <CircleUploadIcon />
        </button>
        <div className='clients-filter-input'>
          <CustomInput
            value={searchValue}
            onChange={setSearchValue}
            placeholder='Filter by ID, Email, Name, Surname'
            iconRight={<SearchIcon />}
          />
        </div>
        <SelectCalendar startDate={startDate} setStartDate={setStartDate} />
        <SelectCalendar startDate={endDate} setStartDate={setEndDate} />

        <button type='button' className='button button--type-text' onClick={handleClearSearch}>
          Clear
        </button>
      </div>

      <div className='column-visibility'>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.id}
            onChange={() => handleColumnVisibilityChange('id')}
          />
          User ID
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.username}
            onChange={() => handleColumnVisibilityChange('username')}
          />
          Full Name
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.email}
            onChange={() => handleColumnVisibilityChange('email')}
          />
          User Email
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.phone}
            onChange={() => handleColumnVisibilityChange('phone')}
          />
          Phone Number
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.country}
            onChange={() => handleColumnVisibilityChange('country')}
          />
          Country
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.created_at}
            onChange={() => handleColumnVisibilityChange('created_at')}
          />
          Date of Create
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.date_of_birth}
            onChange={() => handleColumnVisibilityChange('date_of_birth')}
          />
          Date of birth
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.email_confirmed}
            onChange={() => handleColumnVisibilityChange('email_confirmed')}
          />
          Verified
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.affiliate_id}
            onChange={() => handleColumnVisibilityChange('affiliate_id')}
          />
          Affiliate Id
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.source}
            onChange={() => handleColumnVisibilityChange('source')}
          />
          Source
        </label>
        <label>
          <input
            type='checkbox'
            checked={columnVisibility.action}
            onChange={() => handleColumnVisibilityChange('action')}
          />
          Action
        </label>
      </div>

      <div className='table-block'>
        <div className='table-wrapper'>
          <div className={`table ${tableUserManagementClassName}`}>
            <div className='table-header'>
              <div className='tr'>
                {columnVisibility.id && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>User ID</p>
                    </div>
                  </div>
                )}
                {columnVisibility.username && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Full Name</p>
                    </div>
                  </div>
                )}
                {columnVisibility.email && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>User Email</p>
                    </div>
                  </div>
                )}
                {columnVisibility.phone && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Phone number</p>
                    </div>
                  </div>
                )}
                {columnVisibility.country && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Country</p>
                    </div>
                  </div>
                )}
                {columnVisibility.created_at && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Date of create</p>
                    </div>
                  </div>
                )}
                {columnVisibility.date_of_birth && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Date of birth</p>
                    </div>
                  </div>
                )}
                {columnVisibility.email_confirmed && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Verified</p>
                    </div>
                  </div>
                )}
                {columnVisibility.affiliate_id && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Affiliate Id</p>
                    </div>
                  </div>
                )}
                {columnVisibility.source && (
                  <div className='td'>
                    <div className='td-name'>
                      <p>Source</p>
                    </div>
                  </div>
                )}
                {columnVisibility.action && (
                  <div className='td td--right'>
                    <div className='td-name'>
                      <p>Action</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='table-body'>
              {usersList?.users.length ? (
                usersList?.users.map((item) => (
                  <UserRow
                    key={item.id}
                    user={item}
                    handleDelete={handleDeleteUser}
                    columnVisibility={columnVisibility}
                  />
                ))
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>
        </div>
      </div>
      {usersList?.users?.length && (
        <div className='pagination-block'>
          <SelectPaginationBlock value={perPage} onSelect={onPaginationSelect} />
          {usersList.last_page > 1 && (
            <Pagination
              pageCount={usersList.last_page}
              forcePage={usersList.current_page}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      )}

      <ConfirmDeleteUser
        openModal={openDeleteModal}
        setOpenModal={closeDeleteModal}
        id={selectedId}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default Users;
