import classNames from 'classnames';
import { FieldProps } from 'formik';
import React, { FC, ReactNode } from 'react';

interface CustomInputProps {
  type?: string;
  label?: string;
  iconLeft?: JSX.Element;
  required?: boolean;
  customLeft?: ReactNode;
  inputItemClass?: string;
}

const Input: FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors },
  type = 'text',
  label,
  iconLeft,
  required,
  customLeft,
  inputItemClass,
  ...props
}) => {
  return (
    <div
      className={classNames('input', {
        'input--error': touched[field.name] && errors[field.name],
      })}
    >
      <p className='input__name'>
        {required && <span className='req'>*</span>}
        {label}
      </p>
      <div className='input-wrapper'>
        <input
          type={type}
          {...field}
          {...props}
          className={classNames('input-item', inputItemClass, {
            'input-item--left-icon': iconLeft,
          })}
        />
        {customLeft || (iconLeft && <span className='input-icon'>{iconLeft}</span>)}
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification--error'>{String(errors[field.name])}</p>
      )}
    </div>
  );
};

export default Input;
