import React, { FC, useState } from 'react';

import { CloseIcon, CloseTableIcon, InfoCircleIcon, PlusIcon, EditIcon } from 'assets/svg-icon';
import { IOrderItem, ITradeRequest } from 'store/redux-toolkit/trades/trades-types';
import { addZeros, addOneAndZeros } from 'utils/integers';
import { Link } from 'react-router-dom';
import { ERoutes, ETradeType } from 'enum/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createPortal } from 'react-dom';
import {
  deleteOrder,
  deleteTpSlOrder,
  updateOrderExpiration,
} from 'store/redux-toolkit/trades/trades-thunks';
import { setActionPopUp } from 'store/redux-toolkit/trades/trades-slice';
import { OpenOrderEdit } from 'ui/modals/OpenOrderEdit';
import { ConfirmPopup } from 'ui/ConfirmPopup';

interface IRowChild {
  item: IOrderItem;
  queryParams: ITradeRequest;
  onRefetch?: () => void;
}

const RowChild: FC<IRowChild> = ({ item, queryParams, onRefetch }) => {
  const dispatch = useAppDispatch();
  const { assets, is_demo } = useAppSelector((state) => state.trade);
  const curAsset = assets?.find((el) => el.id === item.asset.id);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleUpdateTp = () => {
    dispatch(setActionPopUp({ update_tp_sl: { order: item, type: 'T/P' } }));
  };

  const handleUpdateSl = () => {
    dispatch(setActionPopUp({ update_tp_sl: { order: item, type: 'S/L' } }));
  };

  const handleUpdateExpiration = () => {
    dispatch(setActionPopUp({ update_expiration: { order: item } }));
  };

  const handleDeleteSl = () => {
    dispatch(deleteTpSlOrder({ is_demo, main_order_id: item.id, is_delete_sl_order: true }));
  };

  const handleDeleteTp = () => {
    dispatch(deleteTpSlOrder({ is_demo, main_order_id: item.id, is_delete_tp_order: true }));
  };

  const handleDeleteOrder = () => {
    setDeleteConfirm(false);
    dispatch(
      deleteOrder({
        apiParams: {
          is_demo,
          order_id: item.id,
        },
        filterParams: queryParams,
      })
    );
  };

  const handleDeleteExpiration = () => {
    dispatch(
      updateOrderExpiration({
        is_demo,
        main_order_id: item.id,
      })
    );
  };

  return (
    <>
      <div className='tr'>
        <div className='td'>
          <p className='td-hidden-name'>Order</p>
          <div className='td-flex'>
            <p>
              <span className='link'>{addZeros(item.id)}</span>
            </p>
            <span className='tooltip-item'>
              <span className='tooltip-item__icon'>
                <InfoCircleIcon />
              </span>
              <span className='tooltip tooltip--top tooltip--left'>
                <span className='tooltip__text'>
                  When a limit order expires, no cancellation fee is charged. In case of manually
                  cancelling a limit order, a gas fee is charged at the time of signing the
                  transaction.
                </span>
              </span>
            </span>
          </div>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>User ID</p>
          <p>
            <Link to={`${ERoutes.users}/${item.user_id}/profile`} className='link'>
              {addOneAndZeros(item.user_id)}
            </Link>
          </p>
        </div>
        <div className='td td--hide-mob'>
          <p className='td-hidden-name'>Email</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Sub-Admin</p>
          <p className='td-value td-value--fw-500'></p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Type</p>
          <p className='td-value  '>{item.transaction_type}</p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Volume</p>
          <p className='td-value '>{item.amount}</p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Order Price</p>
          <p className='td-value '>{item.trigger_price}</p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>SL</p>
          {item.stop_loss ? (
            <div className='td-flex td-flex-value-close'>
              <button type='button' className='td-value' onClick={handleUpdateSl}>
                {item.stop_loss}
              </button>
              <div className='table-buttons '>
                <button type='button' onClick={handleDeleteSl}>
                  <CloseTableIcon />
                </button>
              </div>
            </div>
          ) : (
            <button type='button' className='button button--type-icon' onClick={handleUpdateSl}>
              <PlusIcon />
            </button>
          )}
        </div>
        <div className='td'>
          <p className='td-hidden-name'>TP</p>
          {item.take_profit ? (
            <div className='td-flex td-flex-value-close'>
              <button type='button' className='td-value' onClick={handleUpdateTp}>
                {item.take_profit}
              </button>
              <div className='table-buttons'>
                <button type='button' onClick={handleDeleteTp}>
                  <CloseTableIcon />
                </button>
              </div>
            </div>
          ) : (
            <button type='button' className='button button--type-icon' onClick={handleUpdateTp}>
              <PlusIcon />
            </button>
          )}
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Market Price</p>
          <p className='td-value'>
            {item.transaction_type === ETradeType.buy ? curAsset?.ask : curAsset?.bid}
          </p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Expiration data</p>
          <div className='td-flex td-flex-value-close'>
            {item.date_expiration ? (
              <>
                <button type='button' onClick={handleUpdateExpiration}>
                  {item.date_expiration}
                </button>
                <div className='table-buttons '>
                  <button type='button' onClick={handleDeleteExpiration}>
                    <CloseTableIcon />
                  </button>
                </div>
              </>
            ) : (
              <button
                type='button'
                className='button button--type-icon'
                onClick={handleUpdateExpiration}
              >
                <PlusIcon />
              </button>
            )}
          </div>
        </div>
        <div className='table-buttons flex-e'>
          <button
            type='button'
            className='table-button__btn table-button__btn--color-blue'
            onClick={() => setEditOpen(true)}
          >
            <EditIcon />
          </button>
          <button type='button' className='inactive' onClick={handleDeleteOrder}>
            <CloseIcon />
          </button>
        </div>
      </div>
      {editOpen &&
        createPortal(
          <OpenOrderEdit onClose={() => setEditOpen(false)} item={item} onRefetch={onRefetch} />,
          document.getElementsByTagName('main')[0]
        )}
      {deleteConfirm &&
        createPortal(
          <ConfirmPopup
            body='Are you sure you want to delete it?'
            onClose={() => setDeleteConfirm(false)}
            onConfirm={handleDeleteOrder}
          />,
          document.getElementsByTagName('main')[0]
        )}
    </>
  );
};

export default RowChild;
