import React, { FC } from 'react';
import { ETransactionStatus } from 'enum/types';
import moment from 'moment';
import { IDepositTransactionItem } from 'store/redux-toolkit/transactions/transactions-type';
import { addZeros } from 'utils/integers';
import { moneyFormat } from 'utils/moneyFormat';

interface IDepositRowProps {
  item: IDepositTransactionItem;
}

const DepositRow: FC<IDepositRowProps> = ({
  item: {
    id,
    created_at,
    requested_currency,
    requested_amount,
    fee,
    status,
    comment,
    pay_address,
    payment_service,
  },
}) => {
  const eStatus = ETransactionStatus[status as keyof typeof ETransactionStatus];

  let paymentSystem = '';

  const capitalize = (str: string) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (pay_address) {
    const splitStr = pay_address.split(' : ');

    const adminOrCrm = splitStr[0] ?? '';
    const adminOrCrmEmail = splitStr[1] ?? '';

    if (adminOrCrm === 'admin' || adminOrCrm === 'crm') {
      if (adminOrCrmEmail) {
        paymentSystem = 'Manual ' + adminOrCrm.toUpperCase() + ' (' + adminOrCrmEmail + ')';
      } else {
        paymentSystem = 'Manual ' + adminOrCrm.toUpperCase();
      }
    } else {
      paymentSystem = pay_address;
    }
  } else if (payment_service) {
    paymentSystem = capitalize(payment_service);
  }

  return (
    <div className='tr'>
      <div className='td'>
        <p className='td-hidden-name'>ID</p>
        <p>
          <span className='link'>{addZeros(id)}</span>
        </p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>System</p>
        <p>{paymentSystem}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Date</p>
        <p>{moment(created_at).format('DD/MM/YYYY, HH:mm')}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Currency</p>
        <p>{requested_currency.toUpperCase()}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Amount</p>
        <p>{moneyFormat(requested_amount)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Fee</p>
        <p>{moneyFormat(fee)}</p>
      </div>
      <div className='td'>
        <p className='td-hidden-name'>Comment</p>
        <p>{comment}</p>
      </div>
      <div className='td td--right'>
        <p className='td-hidden-name'>Status</p>
        <div className={`td-status ${status}`}>
          <span className='td-status__name'>{eStatus}</span>
        </div>
      </div>
    </div>
  );
};

export default DepositRow;
