import { ChangeEvent, MouseEvent, useState } from 'react';
import {
  UserCircleIcon,
  UserCheckIcon,
  CheckInCircle,
  InfoCircleIcon,
  CrossInRedCircle,
} from 'assets/svg-icon';
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { addOneAndZeros } from 'utils/integers';
import moment from 'moment';
import { ERoutes, EUserStatus } from 'enum/types';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { updateKYC, verifyKYC } from 'store/redux-toolkit/users/users-thunks';
import { TVerifyKycStatus } from 'store/redux-toolkit/users/users-types';
import DocFile from './DocFile';
import ForgotModal from './ForgotModal';
import NewPasswordModal from './NewPasswordModal';

const getImageName = (url: string) => {
  return url.slice(url.lastIndexOf('/') + 1);
};

const ProfileTab = () => {
  const dispatch = useAppDispatch();
  const [isForgotModalOpened, setForgotModalOpened] = useState(false);
  const [isNewPasswordModalOpened, setNewPasswordModalOpened] = useState(false);

  const { user } = useAppSelector((state) => state.users);

  const handleKYCCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (!user?.id) {
      return;
    }
    dispatch(updateKYC({ id: user?.id, kyc: checked ? 1 : 0 }));
  };

  const handleStatusChange = (e: MouseEvent<HTMLButtonElement>) => {
    const button: HTMLButtonElement = e.currentTarget;
    const { name } = button;
    if (!user?.id) {
      return;
    }
    dispatch(verifyKYC({ id: user?.id, status: name as TVerifyKycStatus }));
  };

  const dateOfBirth = user?.data?.date_of_birth || user?.userKyc?.date_of_birth || null;

  const openForgotModal = () => {
    setForgotModalOpened(true);
  };

  const closeForgotModal = () => {
    setForgotModalOpened(false);
  };

  const openNewPasswordModal = () => {
    setNewPasswordModalOpened(true);
  };

  const closeNewPasswordModal = () => {
    setNewPasswordModalOpened(false);
  };

  return (
    <div className='client-account-wrap'>
      <div className='client-account'>
        <div className='client-header'>
          <div className='title-wrap'>
            <div className='title-wrap__icon'>
              <UserCircleIcon />
            </div>
            <p className='block-title block-title--mb-0 block-title--type2'>User data</p>
          </div>
          <div className='client-account-button'>
            <button
              type='button'
              className='button button--type2 button--size2'
              onClick={openNewPasswordModal}
            >
              New Password
            </button>
            <button
              type='button'
              className='button button--type2 button--size2'
              onClick={openForgotModal}
            >
              Reset Password
            </button>
            <ForgotModal isOpened={isForgotModalOpened} closeModal={closeForgotModal} />
            <NewPasswordModal
              isOpened={isNewPasswordModalOpened}
              closeModal={closeNewPasswordModal}
            />
            <Link to={`${ERoutes.users}/${user?.id}/edit`} className='button button--size2'>
              Edit
            </Link>
          </div>
        </div>
        {user && (
          <>
            <div className='client-account__row'>
              <div className='client-account__list'>
                <div className='client-account__list-item'>
                  <p>User ID:</p>
                  <p>{addOneAndZeros(Number(user?.id))}</p>
                </div>
                <div className='client-account__list-item'>
                  <p>Status:</p>
                  <div className='client-list-val'>
                    <p className='green'>Active</p>
                    <div className='switch switch--green'>
                      <label className='switch__label'>
                        <input
                          type='checkbox'
                          className='hidden'
                          defaultChecked={user.is_active === 1}
                        />
                        <span className='switch__toggler'></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='client-account__list-item'>
                  <p>Date of birth:</p>
                  <p>
                    {dateOfBirth && moment(dateOfBirth).isValid()
                      ? moment(dateOfBirth).format('DD.MM.YYYY')
                      : '-'}
                  </p>
                </div>
                <div className='client-account__list-item'>
                  <p>Full Name:</p>
                  <p>{`${user?.userKyc?.first_name || user?.data?.first_name || '-'} ${
                    user?.userKyc?.last_name || user?.data?.last_name || '-'
                  }`}</p>
                </div>
                <div className='client-account__list-item'>
                  <p>Country:</p>
                  <p>{user?.data.country || '-'}</p>
                </div>
              </div>
              <div className='client-account__list'>
                <div className='client-account__list-item'>
                  <p>Email:</p>
                  <p>{user.email}</p>
                </div>
                <div className='client-account__list-item'>
                  <p>Phone Number:</p>
                  <p>{formatPhoneNumberIntl(user.data.phone) || '-'}</p>
                </div>
                <div className='client-account__list-item'>
                  <p>Affiliate ID:</p>
                  <p>
                    <span>{user.data.affiliate_id}</span>
                  </p>
                </div>
                <div className='client-account__list-item'>
                  <p>A source:</p>
                  <p>{user.data.source}</p>
                </div>
                <div className='client-account__list-item'>
                  <p>KYC</p>
                  <div className='client-list-val'>
                    <p>Off/On</p>
                    <div className='switch switch--green'>
                      <label className='switch__label'>
                        <input
                          type='checkbox'
                          className='hidden'
                          onChange={handleKYCCheck}
                          defaultChecked={user.kyc === 1}
                        />
                        <span className='switch__toggler'></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {user.userKyc && (
              <div className='client-verify-box'>
                <div className='title-wrap'>
                  <div className='title-wrap__icon'>
                    <UserCheckIcon />
                  </div>
                  <p className='block-title block-title--mb-0 block-title--type2'>Identification</p>
                </div>
                <div className='client-verify'>
                  <p className='input-name input-name--color2 input-name--with-hint'>
                    Passport
                    <span className='tooltip-item'>
                      <span className='tooltip-item__icon'>
                        <InfoCircleIcon />
                      </span>
                      <span className='tooltip tooltip--bottom tooltip--arrow-true tooltip--arrow-top-left tooltip--dark tooltip--left '>
                        <span className='tooltip__text'>
                          <span className='tooltip-info'>
                            <span className='tooltip-info__icon'>
                              <CheckInCircle />
                            </span>
                            Government-issued
                          </span>
                          <span className='tooltip-info'>
                            <span className='tooltip-info__icon'>
                              <InfoCircleIcon />
                            </span>
                            Original full-size, unedited documents
                          </span>
                          <span className='tooltip-info'>
                            <span className='tooltip-info__icon'>
                              <InfoCircleIcon />
                            </span>
                            Place documents against a single-coloured background
                          </span>
                          <span className='tooltip-info'>
                            <span className='tooltip-info__icon'>
                              <InfoCircleIcon />
                            </span>
                            Readable, well-lit, coloured imeges
                          </span>
                          <span className='tooltip-info'>
                            <span className='tooltip-info__icon'>
                              <InfoCircleIcon />
                            </span>
                            Place documents against a single-coloured background
                          </span>
                          <span className='tooltip-info'>
                            <span className='tooltip-info__icon'>
                              <CrossInRedCircle />
                            </span>
                            No black and white images
                          </span>
                          <span className='tooltip-info'>
                            <span className='tooltip-info__icon'>
                              <CrossInRedCircle />
                            </span>
                            No edited or expired documents
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <div className='client-doc'>
                    <img src={user.userKyc.doc_one} alt='passport' />
                    {user.userKyc.doc_two && <img src={user.userKyc.doc_two} alt='passport' />}
                  </div>
                  <DocFile name={getImageName(user.userKyc.doc_one)} src={user.userKyc.doc_one} />
                  {user.userKyc.doc_two && (
                    <DocFile name={getImageName(user.userKyc.doc_two)} src={user.userKyc.doc_one} />
                  )}
                  {user.userKyc?.status === 'waiting' && (
                    <div className='client-account-button client-account-button--mt-32'>
                      <button
                        type='button'
                        name='approved'
                        className='button button--full-width button--size2'
                        onClick={handleStatusChange}
                      >
                        Approve
                      </button>
                      <button
                        type='button'
                        name='rejected'
                        className='button button--full-width button--type2 button--size2'
                        onClick={handleStatusChange}
                      >
                        Reject
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileTab;
