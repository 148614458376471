import { useCallback } from 'react';
import { Navigate, NavLink, useLocation, useParams } from 'react-router-dom';
import TabsContent from './TabsContent';
import { ETableRout } from '../types';

const DepositTab = () => {
  return (
    <div className='block block--type2'>
      <TabsContent isCrypto={false} />
    </div>
  );
};

export default DepositTab;
