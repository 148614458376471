import {
  deleteUser,
  getUsers,
  getAllUsers,
  setBlockedUser,
  getUserSettings,
  editUserData,
  getCountries,
  getTradeAnalyticByUser,
  getReferralTransactionsByUser,
  updateKYC,
} from 'store/redux-toolkit/users/users-thunks';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UsersState,
  IUsersResponse,
  IUser,
  IAllUsers,
  IUserSetting,
  ICountryItem,
  ITradeAnalyticResponse,
  IReferralTransactions,
} from './users-types';

const initialState: UsersState = {
  usersList: null,
  user: null,
  allUsers: [],
  countries: [],
  tradeAnalyticByUser: null,
  referralTransactions: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userInitState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, { payload }: PayloadAction<IUsersResponse>) => {
        state.usersList = payload;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }: PayloadAction<IAllUsers[]>) => {
        state.allUsers = payload;
      })
      .addCase(setBlockedUser.fulfilled, (state, { payload }: PayloadAction<IUser>) => {
        if (state.usersList) {
          const idx = state.usersList.users.findIndex((el) => el.id === payload.id);
          state.usersList.users = [
            ...state.usersList.users.slice(0, idx),
            payload,
            ...state.usersList.users.slice(idx + 1),
          ];
        }
      })
      .addCase(updateKYC.fulfilled, (state, { payload }: PayloadAction<IUserSetting>) => {
        if (state.user) {
          state.user.kyc = payload.kyc;
        }
      })
      .addCase(getUserSettings.fulfilled, (state, { payload }: PayloadAction<IUserSetting>) => {
        state.user = payload;
      })
      .addCase(editUserData.fulfilled, (state, { payload }: PayloadAction<IUserSetting>) => {
        // state.user = payload;
      })
      .addCase(getCountries.fulfilled, (state, { payload }: PayloadAction<ICountryItem[]>) => {
        state.countries = payload;
      })
      .addCase(
        getTradeAnalyticByUser.fulfilled,
        (state, { payload }: PayloadAction<ITradeAnalyticResponse>) => {
          state.tradeAnalyticByUser = payload;
        }
      )
      .addCase(
        getReferralTransactionsByUser.fulfilled,
        (state, { payload }: PayloadAction<IReferralTransactions>) => {
          state.referralTransactions = payload;
        }
      );
  },
});

export const { userInitState } = usersSlice.actions;

export default usersSlice.reducer;
