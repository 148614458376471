import React, { FC } from 'react';
import useInput from 'hooks/useInput';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { confirmCancelWithdrawal } from 'store/redux-toolkit/transactions/transactions-thunks';

const AddCommentToCancel: FC<IPopupProps> = ({ openModal, setOpenModal }) => {
  const dispatch = useAppDispatch();
  const { cancelPopUp } = useAppSelector((state) => state.transactions);
  const reason = useInput('');
  const handleConfirm = () => {
    if (cancelPopUp && reason.value) {
      dispatch(
        confirmCancelWithdrawal({
          withdrawal_id: cancelPopUp?.withdrawal_id,
          comment: reason.value,
        })
      );
    }
  };
  return (
    <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} customClass='popup--width-500'>
      <div className='popup-body popup-body--mt-0'>
        <div className='popup-reason'>
          <div className='input-wrapper'>
            <div className='textarea textarea--height-type2'>
              <textarea placeholder='Rejection reason' {...reason}></textarea>
            </div>
          </div>
        </div>
        <div className='popup-submit '>
          <button className='button button--type2 button--full-width' onClick={setOpenModal}>
            Cancel
          </button>
          <button
            className='button button--full-width'
            type='button'
            onClick={handleConfirm}
            disabled={!reason.value}
          >
            Confirm
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddCommentToCancel;
