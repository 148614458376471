import moment from 'moment';
import { IAnalyticsItem } from 'store/redux-toolkit/dashboard/dashboard-types';

export const convertToChartData = (data: IAnalyticsItem[]) => {
  const labels = data.map((item) => moment(item.created_at).format('MMM DD'));

  return {
    labels,
    datasets: [
      {
        maxBarThickness: 150,
        data: data.map((item) => item.amount),
        backgroundColor: data.map((item) => (item.amount > 0 ? '#00d984' : '#fd413d')),
      },
    ],
  };
};
